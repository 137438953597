import React, { useState } from "react";
import { Modal, Radio, Space, Tooltip } from "antd";
import { InfoCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import MagicButton from "../Segments/GenerateAI/MagicButton";
import { useDispatch, useSelector } from "react-redux";
import { message } from "/src/components/UI/AntdAppHelper";
import { actions as experienceUsersActions } from "./redux";
import {
  activeAdjustedExperienceSelector,
  submissionsAIGradingStatusSelector,
} from "../Experiences/selector";
import { actions as experienceActions } from "../Experiences/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { ImMagicWand } from "react-icons/im";
import { axiosInstance } from "/src/api/apiModule";
import { areAllExperienceUsersMarkedSelector, isGradingWithAIInProgressSelector } from "/src/views/ExperienceUsers/selector";
import { isEmpty } from "lodash";
import { usePollingEffect } from "../Segments/InteractiveHelpers";


const GradeAllSubmissions = () => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const areAllExperienceUsersMarked = useSelector(areAllExperienceUsersMarkedSelector());
  const submissionsAIGradingStatus = useSelector(submissionsAIGradingStatusSelector());
  const isGradeAllWithAIInProgress = useSelector(isGradingWithAIInProgressSelector())
  const isGradingWithAIInProgressForEU = useSelector(
    isGradingWithAIInProgressSelector("experience_users")
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ungradedOnly, setUngradedOnly] = useState(false);
  
  let isGradeAllButtonDisabled = false;
  let gradeAllButtonTooltipTitle = mrIntl("GradeAllSubmissions.grade_all_submissions");;
  
  if (isGradeAllWithAIInProgress) {
    gradeAllButtonTooltipTitle = mrIntl("GradeAllSubmissions.stop_grading_all_submissions");
  } else if (activeExperience.status === "closed") {
    isGradeAllButtonDisabled = true; 
    gradeAllButtonTooltipTitle = mrIntl("CommonText.grading_is_disabled_as_the_assessment_is_currently_closed");
  } else if (isGradingWithAIInProgressForEU) {
    // When grading on an EU is in progress but grade all is not in progress
    isGradeAllButtonDisabled = true;
    gradeAllButtonTooltipTitle = mrIntl("GradeAllSubmissions.cannot_grade_all_submissions_while_ai_is_grading_a_submission");
  } else if (areAllExperienceUsersMarked) {
    // Not allwoing grade all when all submissions are published
    isGradeAllButtonDisabled = true;
    gradeAllButtonTooltipTitle = mrIntl("GradeAllSubmissions.grading_of_published_submissions_is_not_allowed");
  }

  const gradeAllModalTitle = (
    <Space>
      {mrIntl("GradeAllSubmissions.grade_all_submissions")}
      <Tooltip
        title={mrIntl("GradeAllSubmissions.only_unpublished_submissions_will_be_graded")}
        placement="top"
      >
        <InfoCircleTwoTone />
      </Tooltip>
    </Space>
  );
  const shouldPoll =
    import.meta.env.VITE_MODE !== "production" &&
    !isEmpty(submissionsAIGradingStatus) &&
    isGradeAllWithAIInProgress;

  const updateGradingStatusInExperience = (newGradeAllSubmissionsWithAI) => {
    dispatch(
      experienceActions.showSuccess({
        data: {
          experience: {
            ...activeExperience,
            custom_fields: {
              ...activeExperience.custom_fields,
              submissions_ai_grading_status: newGradeAllSubmissionsWithAI,
            },
          },
        },
      })
    );
  };

  const fetchUngradedEUsWithResponses = (euIds) => {

    let ungradedEuIds = euIds.filter(
      (id) => !submissionsAIGradingStatus.eu_ids.includes(id)
    );
    
    if (ungradedEuIds.length > 0) {
      dispatch(
        experienceUsersActions.fetch(
          {
            params: {
              by_ids: ungradedEuIds,
              with_all_eus_user_responses: true,
            },
          },
          {
            mergeFetchedData: true,
          }
        )
      );
    }
  };

  const handleGradingStatusUpdate = (data, clearIntervalCallback) => {
    const updatedSubmissionsAIGradingStatus = data.submissions_ai_grading_status;
    const { status, eu_ids } = updatedSubmissionsAIGradingStatus;

    const progressCompleted = status === "completed" || status === "failed" || status === "cancelled";

    updateGradingStatusInExperience(updatedSubmissionsAIGradingStatus);
    fetchUngradedEUsWithResponses(eu_ids);

    if (progressCompleted) {
      clearIntervalCallback();
    }
  };

  const checkGradeAllSubmissionsProgress = (clearIntervalCallback) => {
    dispatch(
      experienceActions.getSubmissionsAiGradingStatus(
        { exp_uuid: activeExperience.uid },
        {
          successCallback: (data) =>
            handleGradingStatusUpdate(data, clearIntervalCallback),
          errorCallback: (error) => {
            console.error(
              "Failed to check the status of grading all submissions",
              error
            );
          },
        }
      )
    );
  };

  usePollingEffect(shouldPoll, checkGradeAllSubmissionsProgress, 10000);

  const handleGradeAll = () => {
    const gradingOptions = {
      exp_uuid: activeExperience.uid,
    };

    if (ungradedOnly) {
      gradingOptions.only_ungraded = true;
    }

    dispatch(
      experienceUsersActions.gradeSubmissionsWithAi(gradingOptions, {
        successCallback: (data) => {
          let updatedSubmissionsAIGradingStatus =
            data.experience.custom_fields.submissions_ai_grading_status;
          updateGradingStatusInExperience(updatedSubmissionsAIGradingStatus);
          // Need to fetch all EUs as their customField is updated in BE on grade all
          dispatch(experienceUsersActions.fetch({
            params: {
              by_experience_id: activeExperience.id,
            }
          }));
        },
        errorCallback: (error) => {
          message.error(
            mrIntl("CommonText.something_went_wrong_please_try_again")
          );
          console.log("gradeAllWithAI errorCallback", error);
        },
      })
    );
  };

  const cancelGradeAll = () => {
    const url = `${import.meta.env.VITE_API_URL}cancel_grade_submission_with_ai`;
    const params = {
      exp_uuid: activeExperience.uid,
    };
    axiosInstance.instance.post(url, params)
      .then(response => {
        let updatedSubmissionsAIGradingStatus = response.data.experience.custom_fields.submissions_ai_grading_status;
        updateGradingStatusInExperience(updatedSubmissionsAIGradingStatus);
        // if(updatedSubmissionsAIGradingStatus.eu_ids?.length > 0) {
        //   // fetching to merge if grading of some EUs is completed on cancellation 
        //   fetchUngradedEUsWithResponses(updatedSubmissionsAIGradingStatus.eu_ids);
        // } else {
          // Fetching all experience users since no specific eu_ids are available, making it unclear which experience users have been updated
          dispatch(experienceUsersActions.fetch({
            params: {
              by_experience_id: activeExperience.id,
              with_all_eus_user_responses: true
            }
          }));
        // }

      })
      .catch(error => {
        console.error('Failed to cancel grade all process:', error);
        message.error(mrIntl("CommonText.something_went_wrong_please_try_again"));
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleGradeAll();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* TODO: show grading progress in real time */}
      <MagicButton
        shape="default"
        text={isGradeAllWithAIInProgress ? mrIntl("CommonText.stop") :  mrIntl("ExperienceAIGradingProgress.grade_all")}
        tooltipTitle={gradeAllButtonTooltipTitle}
        feature_code="grade_with_ai"
        icon={isGradeAllWithAIInProgress ? <LoadingOutlined />: null}
        onClick={isGradeAllWithAIInProgress ? cancelGradeAll : showModal}
        disabled={isGradeAllButtonDisabled}
      />
      <Modal
        title={gradeAllModalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Grade"
        okButtonProps={{
          className: "magic-button-primary",
          icon: <ImMagicWand />,
        }}
      >
        <Radio.Group
          value={ungradedOnly ? "ungraded" : "override"}
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
          onChange={(e) => {
            const value = e.target.value;
            setUngradedOnly(value === "ungraded");
          }}
        >
          <Radio value="override">
            {mrIntl("GradeAllSubmissions.all_student_responses_this_will_override_existing_points_and_comments")}
          </Radio>
          <Radio value="ungraded">{mrIntl("GradeAllSubmissions.only_ungraded_student_responses")}</Radio>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default GradeAllSubmissions;
