import React, { useEffect, useRef, useState, useCallback, Suspense } from "react";
import ReactDOM from "react-dom";

// import "./GroupItemDetail.scss";
import { Route, useRouteMatch } from "react-router-dom";
// import { MrSelect } from "mr_react_framework";
import MrSelect from "/src/components/UI/MrSelect/MrSelect";

import {
  Tabs,
  Row,
  Col,
  Button,
  List,
  Card,
  Alert,
  Tooltip,
  Drawer,
  Tag,
  Space,
  Badge,
} from "antd";
// import { AntCloudOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextAnswerExplanation from "/src/views/Segments/Explanation/TextAnswerExplanation";
import MCQSingle from "/src/views/Segments/MCQ/MCQSingle";
import MCQMultiple from "/src/views/Segments/MCQ/MCQMultiple";
import TrueFalse from "/src/views/Segments/TrueFalse/TrueFalse";
import FabricDrawing from "/src/views/Segments/FabricDrawing/FabricDrawing";
import FabricLabel from "/src/views/Segments/FabricLabel/FabricLabel";
import FabricHotspot from "/src/views/Segments/FabricHotspot/FabricHotspot";
import GGBGraph from "/src/views/Segments/GGBGraph/GGBGraph";
import RBDInlineMatching from "../RBDMatching/RBDInlineMatching";
import RBDInlineSorting from "../RBDSorting/RBDInlineSorting";
import RBDInlineClassify from "../RBDClassify/RBDInlineClassify";
import RBDMatching from "/src/views/Segments/RBDMatching/RBDMatching";
import RBDSorting from "/src/views/Segments/RBDSorting/RBDSorting";
import RBDClassify from "/src/views/Segments/RBDClassify/RBDClassify";
import RDSTable from "/src/views/Segments/RDSTable/RDSTable";
import CKEFillText from "/src/views/Segments/CKEFill/CKEFillText";
import CKEFillDropDown from "/src/views/Segments/CKEFill/CKEFillDropDown";
import MiniQuiz from "/src/views/Segments/MiniQuiz/MiniQuiz";
import FlashCard from "/src/views/Segments/FlashCard/FlashCard";

import Accordion from "/src/views/Segments/Accordion/Accordion";
import AudioView from "/src/views/Segments/RTCAudio/AudioView";
import VideoView from "/src/views/Segments/Video/VideoView";
import ImageView from "/src/views/Segments/Image/ImageView";
import SimulationView from "/src/views/Segments/Simulation/SimulationView";
import PDFView from "/src/views/Segments/PDF/PDFView";
import EmbedView from "../Embed/EmbedView";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
// import withSizes from "react-sizes";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  isMBIframeSelector,
  oscAccountTypeSelector,
  partnerNameSelector,
} from "/src/views/Auth/Login/selector";

import CKESubjective from "../CKESubjective/CKESubjective";

import {
  renderMathInElement,
  getUniqueId,
  checkMob,
  checkIPAD,
  maxCharacters,
  displayPoints,
  getBrowser,
  writeToClipboardPolyfill,
  getLabelFromIndexPath,
  removeHTMLTagsFromText,
} from "/src/lib/utils/helperMethods";
import ApErrorBoundary from "/src/components/ApErrorBoundary/ApErrorBoundary";
import ResponseAttachmentsButton from "../ResponseAttachments/ResponseAttachmentsButton";
import ResponseAttachments from "/src/views/Segments/ResponseAttachments/ResponseAttachments";
import {
  FullScreenButton,
  CustomTextButton,
  UploadFilesButton,
  PinButton,
} from "/src/components/UI/Segment/UIHelper";
import CKETextAnswer from "../CKETextContent/CKETextAnswer";
import {
  CKETextAnswerTools,
  CalcTool,
  SpellCheckTool,
  updateConfigForTool,
  SpeechToTextTool
} from "/src/components/UI/CKEditor/CKEConfig";
import { createSelector } from "reselect";
import { actions as userResponseActions } from "/src/views/UserResponses/redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
// import * as Sentry from "@sentry/react";
import {
  InfoCircleOutlined,
  BookOutlined,
  BookTwoTone,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  CloudSyncOutlined,
  CloudTwoTone,
} from "@ant-design/icons";
// import { GrBookmark } from "react-icons/gr";
// import { GoBookmark } from "react-icons/go";

import {
  activeLogsHistoryForSegmentIdSelector,
  activeSegmentIdSelector,
  activeSegmentSelector,
  pinnedSegmentsSelector,
  segmentLayoutSelector,
  segmentModeSelector,
} from "/src/views/Segments/selector";
import AudioInput from "../AudioInput/AudioInput";
import _, { debounce, filter, find, findIndex, isEmpty } from "lodash";
// import BookmarkButton from "components/UI/BookmarkButton/BookmarkButton"
import BookmarkButton from "/src/views/Segments/Components/BookmarkButton";
import BackupDropdown from "./BackupDropdown";
import SegmentRelations from "./SegmentRelations";
import DetectInsideClick from "/src/lib/DetectInsideClick/DetectInsideClick";
import {
  activeSegmentInfoSelector,
  firebaseUserResponseSelector,
  supportOldUserResponseIdSelector,
  calcEnabledSelector,
  spellCheckEnabledSelector,
  sttAllowedSelector,
  graphingCalcEnabledSelector
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import AddSegmentSelector from "../CustomSegmentSelector/AddSegmentSelector";
// import Segments from "../Segments";
import QuestionGroup from "../QuestionGroup/QuestionGroup";
import {
  experienceConfigSelector,
  experienceConfigTakeExperienceSelector,
  getInternetStatusSelector,
  getOfflineDBStatusSelector,
  printOptionsSelector,
} from "/src/views/Experiences/selector";
import AnswerExplanation from "../Explanation/AnswerExplanation";
import { GrCloudComputer, GrPersonalComputer } from "react-icons/gr";
import dayjs from "dayjs";
import SavingStatusIndicator from "/src/views/Experiences/ExperienceShow/Components/SavingStatusIndicator";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { dbUserResponsesSelector } from "/src/views/UserResponses/selector";
import DesmosGraph from "../DesmosGraph/DesmosGraph";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import Connect from "../Connect/Connect";
import { getMaxEngagementPoints } from "../SegmentHelperMethods";
import EngagementPoints from "./EngagementPoints";
import UserResponseFeedback from "/src/views/UserResponses/UserResponsesItemDetail/UserResponseFeedback";
import { activeTopicSelector } from "../Topics/selector";
import { activeExperienceUserSelector } from "/src/views/ExperienceUsers/selector";
import FabricAnnotator from "../FabricAnnotator/FabricAnnotator";
import EllipsisContent from "../../../components/UI/EllipsisContent/EllipsisContent";
import Markscheme from "../Markscheme/Markscheme";
// import SegmentHistory from "./SegmentHistory";
import VideoInput from "../VideoInput/VideoInput";
import FileUpload from "../FileUpload/FileUpload";
import SegmentList from "../SegmentList/SegmentList";
import DocumentView from "../Document/DocumentView";
import PPTView from "../PPT/PPTView";
import Spinner from "/src/components/UI/Spinner/Spinner";
import PinnedSegmentPlaceholder from "../Components/PinnedSegmentPlaceholder";


const SegmentHistory = React.lazy(() => import('./SegmentHistory'));

const LibrarySegments = React.lazy(() => import('../LibrarySegments/LibrarySegments'));
const Segments = React.lazy(() => import('../Segments'));

const IBFields = React.lazy(() => import('./IBFields'));

// const finalSavingStatusSelector = savingStatusSelector();
const SegmentItemDetail = (props) => {
  console.log("164 SegmentItemDetail props==>", props);
  // const [bookMarked, setBookMarked] = useState(false);
  const {
    item,
    experience = {},
    // experienceUser = {},
    experienceViewMode = "apTakeTest",
    // userResponse,
    gradingConfig = {},
    qNo,
    isLibrary,
    readingModeEnabled,
    mobileUploadMode = false,
    embedded,
    printMode = false,
    isLibraryModal = false,
    activeTopicIdx,
    parentIdxs,
    nestingLevel = 1,
    libraryModalProps = {},
    rearrangeMode = false,
    onlyExplanationMode = false,
    recursiveSegmentConfig = {},
    showAnnotatorToStudent,
    allowIncludeTextAnswer = true,
    allowResponseAttachments = true,
    allowSavingStatusIndicator = true,
    renderQuestionAreaOnly = false,
    notRenderQGChildren = false,
    isPinnedSegment = false,
    isSegmentInAIList = false,
    showExperiencePreview = false,
  } = props;
  console.log("SegmentItemDetail logs ===> props", {item, nestingLevel, props});
  // NOTE: nestingLevel default 1 for when no value passed from above - being used outside experience
  // const mobileUploadMode = true // temp - receive from parent

  console.log("activeTopicIdx SegmentItemDetail props==> ", activeTopicIdx);
  const screenSettings = { isMobile: checkMob() };
  const mrIntl = useTranslate()
  const { TabPane } = Tabs;
  const { ErrorBoundary } = Alert;
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const calcEnabledFromUserInfo = useSelector(calcEnabledSelector());
  const graphingCalcEnabledFromUserInfo = useSelector(graphingCalcEnabledSelector())
  const sttEnabledFromUserInfo = useSelector(sttAllowedSelector);
  const spellCheckEnabledFromUserInfo = useSelector(spellCheckEnabledSelector());
  const experienceConfigFromRedux = useSelector(experienceConfigSelector());
  const experienceConfigTakeExperienceFromRedux = useSelector(
    experienceConfigTakeExperienceSelector()
  );

  const currentUser = useSelector(currentUserSelector());
  const pinnedSegments = useSelector(pinnedSegmentsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeSegmentInfo = useSelector(activeSegmentInfoSelector);
  const internetStatus = useSelector(getInternetStatusSelector());
  const offlineDBStatus = useSelector(getOfflineDBStatusSelector());
  const segmentLayout = useSelector(segmentLayoutSelector());
  const partnerName = useSelector(partnerNameSelector());
  const activeTopic = useSelector(activeTopicSelector());
  const supportOldUserResponseId = useSelector(supportOldUserResponseIdSelector());
  const oscAccountType = useSelector(oscAccountTypeSelector());
  const activeSegmentId = useSelector(activeSegmentIdSelector())
  const activeSegment = useSelector(activeSegmentSelector());
  const activeLogsHistoryForSegmentId = useSelector(activeLogsHistoryForSegmentIdSelector())
  const isMBIframe = useSelector(isMBIframeSelector());
  const printOptions = useSelector(printOptionsSelector());

  const renderUsingWiris = !isMBIframe
  
  let experienceUser = useSelector(activeExperienceUserSelector());
  if (experienceViewMode == "apPreviewTest") {
    experienceUser = {
      experience_id: experience.id,
      user_id: currentUser.id, 
      attempt_no: 1,
    }
  }

  console.log("segmentLayout ==>", segmentLayout, printOptions);
  console.log("currentuser in segmentitem detail==>", currentUser);
  console.log(
    "enabledFeatures experienceConfigTakeExperienceFromRedux, experienceConfigFromRedux ==>",
    enabledFeatures,
    experienceConfigTakeExperienceFromRedux,
    experienceConfigFromRedux
  );

  // let experience_id = 0, allowStudentUploadFiles;
  // if (experience) {
  //   experience_id = experience.id
  //   allowStudentUploadFiles = experience.settings.allow_student_upload_files
  // }
  let isMobile = checkMob();
  let segment = item;
  const segment_type = segment.segment_type;

  const [currentHistoryOfSegment, setCurrentHistoryOfSegment] = useState()
  if (currentHistoryOfSegment) {
    const child_segment_key = `${segment_type}_segment_attributes`
    segment = {
      ...segment,
      ...currentHistoryOfSegment,
      [child_segment_key]: {
        ...segment[child_segment_key],
        ...currentHistoryOfSegment["child_segment_attributes"]
      }
    }
    delete segment.child_segment_attributes // NOTE: Its getting merged because currentHistoryOfSegment has this key
  }

  let experience_id = experience.id || 0;
  // TODO: use experience_id = segment.experience_id for osc ind segment embed with mcq submission
  let experienceSettings = experience.settings || {};
  let gradingEnabled = gradingConfig.gradingEnabled || false;
  let experienceUserMarked = experienceUser.marked;
  let experienceQbCode = segment.experience_data && segment.experience_data.qb_code  
  let showFullPathLabel = nestingLevel === 1 || ((isLibrary) && nestingLevel === 2) || false;
  console.log("qNo===>", qNo)

  const spellcheckEnabled = spellCheckEnabledFromUserInfo && enabledFeatures.spellcheck;
  const sttPluginEnabled = enabledFeatures.speech_to_text_plugin
  const sttEnabled = sttPluginEnabled && (sttEnabledFromUserInfo || experienceViewMode === "apPreviewTest");

  let segmentSettings = {
    ...segment.settings,
    nestingLevel: nestingLevel,
    //********************************************************//
    // IMPORTANT NOTE: @SHIKHAR removed ternary operator having conditional selector issue
    //********************************************************//
    oscSettings: {
      shouldBlurContent: (oscAccountType === "b2c" && experienceQbCode === "ib_dp"),
      shouldShowB2CInfoCard: (oscAccountType === "b2c" && experienceQbCode === "ib_dp"),
      shouldShowPDFMarksScheme: (oscAccountType === "b2b" || (oscAccountType === "b2c" && experienceQbCode !== "ib_dp")),
    }
  }

  const segmentMode =
    useSelector(segmentModeSelector()) || segmentSettings.mode;

  let isPamoja = false;
  if (segment.custom_fields.block) {
    isPamoja = true;
  }
  // console.log("segment json item ==>", item.id, '{"uuid": ', JSON.stringify(item.uuid), ', "segment": ', JSON.stringify(item), '}')

  let segmentToRender,
    contentToRender,
    answerExplantion,
    includeExplanation,
    resourceTitle,
    resourceCaption,
    points,
    showSideGrader,
    showTopGrader,
    graderAttrs = {};
  let extraTextAnswerButton,
    responseAttachmentsButton,
    pinSegmentButton,
    bookMarkButton,
    extraInteractiveSelect,
    annotateAttachmentsButton,
    savingStatusIndicatorIcon;
  let extraTextAnswer, extraInteractive, responseAttachments, extraOptions;
  let fabricAnnotator, annotatorConfig;
  let segmentFullScreenButton;
  let showBackupDropdown = false;
  let viewUnmappedFilesButton = null;
  let includeMarkscheme = false;
  if (segmentSettings.include_markscheme) {
    if (experienceViewMode === "apEditTest" || experienceViewMode === "apGradeTest" || (printMode && printOptions.with_markscheme == "true")) {
      includeMarkscheme = true;
    }
  }

  const segmentItem = useRef();
  const question = segment.question_segment_attributes || {};
  const question_type = question.question_type;
  const game_type = question.game_type || false; //  NOTE: UTTAM DONT FORGET TO HANDLE THIS
  const segment_id = segment.id;
  const question_id = question.id;
  const topic_id = segment.parent_id;

  const avoidBreakInsideInPrintFor = ["resource", "fabric_drawing", "fabric_fill_label", "fabric_hotspot", "fabric_dnd_label",  "rds_table", "ggb_graph"]
  const isAvoidBreakInsideInPrint = printMode && (avoidBreakInsideInPrintFor.includes(question_type) || avoidBreakInsideInPrintFor.includes(segment_type))

  
  // Using activeSegmentQuestionType instead of question_type as in case of nested questions we need to check activeSegment question type to show annotator or thumbnails for attachments specifically for group type
  const activeSegmentQuestionType = 
    activeSegment && 
    activeSegment.question_segment_attributes && 
    activeSegment.question_segment_attributes.question_type;

  // let showGrader = true
  // if(segment_type === "question" && question_type !== "group"){
  //   showGrader = true
  // }

  // Getting from selector now instead of passing from the top - was getting complicated with nested questions
  // const userResponse = useSelector(
  //   dbUserResponsesSelector(
  //     experienceUser.experience_id,
  //     experienceUser.user_id,
  //     segment_id,
  //     experienceUser.attempt_no,
  //   )
  // )[0];

  const userResponse = useSelector(
    dbUserResponsesSelector(
      experienceUser.experience_id,
      experienceUser.user_id,
      segment_id,
      experienceUser.attempt_no,
      true,
      experienceUser.id
    )
  );

  console.log("SID filtered userResponse", userResponse);
  const userResponseId = userResponse && userResponse.id;

  const responseIdentifier = supportOldUserResponseId
    ? `eId${experience_id}_uId${currentUser.id}_qId${question_id}_sId${segment_id}`
    : `eId${experience_id}_uId${currentUser.id}_sId${segment_id}`;

  const firebaseUserResponse = useSelector(
    firebaseUserResponseSelector(responseIdentifier)
  );
  // const firebaseUserResponse = null
  // const firebaseUserResponse = useSelector((state) => {
  //   if (
  //     (experienceViewMode !== "apTakeTest" &&
  //       experienceViewMode !== "apPreviewTest") ||
  //     segment_type !== "question"
  //   ) {
  //     return {};
  //   }
  //   const responseIdentifier = supportOldUserResponseId
  //     ? `eId${experience_id}_uId${currentUser.id}_qId${segment.question_segment_attributes.id}_sId${segment_id}`
  //     : `eId${experience_id}_uId${currentUser.id}_sId${segment_id}`;
  //   return firebaseUserResponseSelector(responseIdentifier);
  // });

  const [renderMath, setRenderMath] = useState(0);
  const [triggerStateUpdate, setTriggerStateUpdate] = useState(false);
  const [triggerSetActiveSegment, setTriggerSetActiveSegment] = useState();
  const [currentBackup, setCurrentBackup] = useState({});

  // #####################################################################
  // NOTE: props.id getting overridden for different pages never use this.
  // #####################################################################

  let dbBackupStatus = {};
  if (
    userResponse &&
    userResponse.custom_fields &&
    userResponse.custom_fields.fb_response_id
  ) {
    dbBackupStatus = userResponse.custom_fields;
  }
  // const dbBackupStatus =
  //   (userResponse &&
  //     userResponse.custom_fields &&
  //     userResponse.custom_fields.fb_response_id &&
  //     userResponse.custom_fields) ||
  //   {};

  console.log(
    "dbBackupStatus ==>",
    dbBackupStatus,
    currentBackup,
    question_id,
    question_type
  );
  console.log("supportOldUserResponseId ==>", supportOldUserResponseId);

  // userResponse for grading view, //firebaseUserResponse for FB restore
  // let initialData = userResponse || {};
  //********************************************************//
  // IMPORTANT NOTE: UTTAM Resolve this need to USE below line
  //********************************************************//
  // let initialData = userResponse || firebaseUserResponse || {};
  // Doing this instead:
  let initialData = {}
  if(firebaseUserResponse){
    initialData = firebaseUserResponse
  }
  if(userResponse){
    initialData = userResponse
  }
  console.log("attactment json =======>",initialData && initialData.attachments_json);

  const [segmentItemStateSetting, setSegmentItemStateSetting] = useState({});

  const modalVisible = segmentItemStateSetting.modalVisible;
  const annotateAttachmentMode = segmentItemStateSetting.annotateAttachmentMode;

  console.log(
    "initial response ==>",
    question_type,
    segment_id,
    initialData,
    // firebaseUserResponse,
    userResponse
  );

  useEffect(() => {
    const defaultSegmentItemStateSetting = {
      includeTextAnswer:
        segmentSettings.include_text_answer || initialData.include_text_answer,
      extraInteractive: initialData.extra_interactive || "",
      allowStudentUploadFiles:
        experienceSettings.allow_student_upload_files ||
        initialData.files_uploaded ||
        false,
      responseAttachmentFormVisible: true,
    };

    setSegmentItemStateSetting(defaultSegmentItemStateSetting);
  }, []);

  useEffect(() => {
    // if (dbBackupStatus.fb_response_id) {
    //   setCurrentBackup(dbBackupStatus);
    // } else {
    //   setCurrentBackup({});
    // }
    // setSegmentItemStateSetting((segmentItemStateSetting) => {
    //   return {
    //     ...segmentItemStateSetting,
    //     includeTextAnswer: dbBackupStatus.text_answer ? true : false
    //   }
    // })
  }, [userResponseId, gradingConfig.gradingMode]);

  useEffect(() => {
    console.log("checking mb selector", renderUsingWiris);
    console.log("render math again in segment item detail ", renderMath);
    if (segmentItem.current) {
      renderMathInElement(segmentItem.current, renderUsingWiris);
    }
    // Answer explanation was in drawer in some cases, Drawer is out of scope from segment div so explicitily checking if any drawer open too
    let drawer = document.getElementsByClassName("ant-drawer");
    if (drawer[0]) {
      renderMathInElement(drawer[0], renderUsingWiris);
    }
  }, [renderMath, currentBackup.fb_response_id, initialData.id]);

  useEffect(() => {
    setTriggerStateUpdate(getUniqueId());
  }, [readingModeEnabled]);

  // function setUserResponse(params) {
  //   // if (currentUser.id && activeSegmentInfo.segment_id == segment_id) {
  //   if (currentUser.id) {
  //     const userResponseData = {
  //       ...params,
  //       questionId: segment.question_segment_attributes.id,
  //       segmentId: segment_id,
  //       userId: currentUser.id,
  //       topicId: topic_id,
  //      // userResponseId: `eId${experience_id}_uId${currentUser.id}_qId${segment.question_segment_attributes.id}_sId${segment_id}`,
  //       userResponseId: `eId${experience_id}_uId${currentUser.id}_sId${segment_id}`,
  //       extra: {
  //         topic_name: topic.title,
  //         activeTopicIdx: activeTopicIdx || 0,
  //         qNo: qNo,
  //       }
  //     }

  //     console.log("sending userResponseData to save ==>", userResponseData)
  //     dispatch(firestoreInteractionActions.setUserResponse(userResponseData));
  //   }
  // }

  const setUserResponseWithDebounce = debounce((params) => {
    console.log(
      "setUserResponseWithDebounce",
      activeTopic,
      activeTopicIdx,
      topic_id,
      qNo
    );
    // if (currentUser.id && activeSegmentInfo.segment_id == segment_id) {
    if (currentUser.id) {
      const userResponseData = {
        ...params,
        experienceId: experience_id,
        questionId: segment.question_segment_attributes.id,
        segmentId: segment_id,
        userId: currentUser.id,
        topicId: topic_id,
        ePoints: getMaxEngagementPoints(
          segment,
          experienceConfigTakeExperienceFromRedux.engagement
        ),
        // userResponseId: `eId${experience_id}_uId${currentUser.id}_qId${segment.question_segment_attributes.id}_sId${segment_id}`,
        userResponseId: supportOldUserResponseId
          ? `eId${experience_id}_uId${currentUser.id}_qId${segment.question_segment_attributes.id}_sId${segment_id}`
          : `eId${experience_id}_uId${currentUser.id}_sId${segment_id}`,
        extra: {
          topic_name: activeTopic.section_segment_attributes
            ? activeTopic.section_segment_attributes.title
            : "",
          topic_idx: activeTopicIdx || 0,
          question_number: qNo,
        },
      };
      setTriggerSetActiveSegment(getUniqueId());

      console.log("sending userResponseData to save ==>", userResponseData);
      dispatch(
        firestoreInteractionActions.setUserResponse(userResponseData, {
          updateOfflineSaveStatus: (offline_status) => {
            console.log("offline saving status ==>", offline_status);
            let status = {
              offline_status: offline_status,
            };
            if (offline_status == "saved") {
              status.offline_saved_at = Date.now();
            }
            dispatch(
              firestoreInteractionActions.savingStatusSuccess({
                segment_id: segment_id,
                status: status,
              })
            );
          },
          updateOnlineSaveStatus: (online_status) => {
            let status = {
              online_status: online_status,
            };
            if (online_status == "saved") {
              status.online_saved_at = Date.now();
            }
            dispatch(
              firestoreInteractionActions.savingStatusSuccess({
                segment_id: segment_id,
                status: status,
              })
            );
          },
        })
      );
    }
  }, 500);

  const setUserResponse = useCallback(setUserResponseWithDebounce, [
    activeTopicIdx,
    segment_id,
  ]);

  console.log("triggerSetActiveSegment ==>", triggerSetActiveSegment);
  useEffect(() => {
    console.log(
      "triggerSetActiveSegment useEffect ==>",
      activeSegmentInfo.segment_id,
      segment_id
    );
    if (
      triggerSetActiveSegment &&
      currentUser.id &&
      activeSegmentInfo.segment_id != segment_id &&
      partnerName !== "osc" &&
      nestingLevel === 1
    ) {
      const userInfo = {
        active_segment_info: {
          segment_id: segment_id,
          topic_id: topic_id,
          topic_idx: activeTopicIdx,
          question_number: qNo,
        },
      };
      console.log(
        "sending active segment info ==>",
        userInfo.active_segment_info
      );
      dispatch(
        firestoreInteractionActions.setUserInfo({
          experienceId: experience_id,
          userInfo,
        })
      );
    }
  }, [triggerSetActiveSegment]);

  // useEffect(() => {
  //   if (question_type == 'fabric_drawing' || question_type == 'fabric_dnd_label' || question_type == 'fabric_fill_label' || question_type == 'fabric_hotspot') {
  //     setTriggerStateUpdate(getUniqueId())
  //   }
  // }, [])

  // Uttam's from SegmentList
  // let finalRender = [];

  console.log("segment attempt status is ==>", segment.attempt_status);
  let ckeConfig = {};

  let textAreaFullScreenButton = (
    <FullScreenButton
      fullscreen={segmentItemStateSetting.textAreaFullScreen}
      clicked={() =>
        setSegmentItemStateSetting({
          ...segmentItemStateSetting,
          textAreaFullScreen: !segmentItemStateSetting.textAreaFullScreen,
          modalVisible: !segmentItemStateSetting.textAreaFullScreen,
        })
      }
    />
  );

  let interactiveFullScreenButton = (
    <FullScreenButton
      fullscreen={segmentItemStateSetting.interactiveFullScreen}
      clicked={() =>
        setSegmentItemStateSetting({
          ...segmentItemStateSetting,
          interactiveFullScreen: !segmentItemStateSetting.interactiveFullScreen,
          modalVisible: !segmentItemStateSetting.interactiveFullScreen,
        })
      }
    />
  );

  let childrenRenderProps = {
    experienceViewMode,
    experience,
    mobileUploadMode,
    embedded,
    rearrangeMode,
    readingModeEnabled,
    printMode,
    segment,
    nestingLevel,
    parentIdxs,
    activeTopicIdx,
    libraryModalProps,
    gradingConfig,
    experienceUser,
    isLibrary,
    isLibraryModal,
    isSegmentInAIList,
    showExperiencePreview
  };

  // console.log("children render props ==>", segment.criterium_associations_attributes, experience)
  let numberOfLinesForAnswerSpace;
  if (experience && experience.rubric) {
    const printLinesCount = parseInt(segment.settings?.print_lines_count)
    if (printLinesCount) {
      numberOfLinesForAnswerSpace = printLinesCount
    } else {
      if (experience.rubric.type_c === "myp_achievement_level") {
        const segmentCas = segment.criterium_associations_attributes || [];
        const segmentCasLength = segmentCas.length || 1;
        numberOfLinesForAnswerSpace = segmentCasLength * 4;
      } else if (experience.rubric.type_c === "comments_only") {
        numberOfLinesForAnswerSpace = 4;
      } else {
        numberOfLinesForAnswerSpace = segment.points * 2; 
      }
    }
  }
  //default segmentConfig for teacher - create = apEditTest
  let segmentConfig = {
    ...segment.question_segment_attributes, // TODO: Fix this its overriding wrong segment_id to all child components
    ...recursiveSegmentConfig,
    experience_id: experience_id,
    customPropSegments: segment.customPropSegments,
    renderQuestionAreaOnly: renderQuestionAreaOnly,
    segment_id: segment_id,
    parentIdxs: parentIdxs,
    numberOfLinesForAnswerSpace: numberOfLinesForAnswerSpace,
    // segmentPoints: segment.points,
    // segmentCALength: segment.criterium_associations_attributes.length,
    // triggerStateUpdate: triggerStateUpdate,
    text_answer: initialData.text_answer || "",
    response_json: initialData.response_json,
    game_status: initialData.game_status,
    attempt_status: initialData.attempt_status || {},
    attachments_json: initialData.attachments_json, // @uttam - question_segment_attributes attachments_json getting overridden by this
    screenSettings: screenSettings,
    segmentSettings: segmentSettings,
    triggerStateUpdate: triggerStateUpdate,
    setTriggerStateUpdate: setTriggerStateUpdate,
    ckeConfig: ckeConfig,
    printMode: printMode,
    segmentMode: segmentMode,
    onlyExplanationMode: onlyExplanationMode,
    unique_segment_identifier: `${segment_id}_${currentUser.id}`,
    setRenderMath: setRenderMath,
    segmentFooterAttributes: {
      experienceViewMode: experienceViewMode,
      segment: segment,
      segmentMode: segmentMode,
      printMode: printMode,
      explanationViewMode: 
        onlyExplanationMode
          ? ""
          : segmentLayout === "single"
            ? "drawer"
            : segmentMode === "quick_check"
              ? ""
              : "accordion",
      answer_explanation:
        segment.question_segment_attributes &&
        segment.question_segment_attributes.answer_explanation,
      attachments_json:
        (segment.question_segment_attributes &&
          segment.question_segment_attributes.attachments_json) ||
        {},
    },
    experienceSettings: experienceSettings,
    experienceViewMode: experienceViewMode,
    attachmentsCount: initialData.attachments_json?.attachments?.length
    // onAutoCheck: onAutoCheck
  };

  if (segment_type == "question") {
    // let question_type = segment.question_segment_attributes.question_type
    contentToRender = (
      <RenderHtml uniqueTextIdentifier={segmentConfig.unique_segment_identifier} text={segment.question_segment_attributes.content} />
    );
    // points = segment.question_segment_attributes.points
    points = segment.points;

    if (segment.question_segment_attributes.attachments_json) {
      segmentConfig = {
        ...segmentConfig,
        answer_explanation_video_url:
          segment.question_segment_attributes.attachments_json
            .answer_explanation_video_url,
      };
    }

    if (
      experienceViewMode === "apTakeTest" ||
      experienceViewMode === "apPreviewTest"
    ) {
      const answeringAllowed =
        internetStatus == "online" || offlineDBStatus == "open";
      console.log(
        "answeringAllowed, internetStatus, offlineDBStatus ==>",
        answeringAllowed,
        internetStatus,
        offlineDBStatus
      );

      segmentConfig = {
        ...segmentConfig,
        gameMode: game_type ? true : false,
        answerMode: !readingModeEnabled && answeringAllowed,
        presentationMode: readingModeEnabled,
        ckeConfig: {
          ...segmentConfig.ckeConfig,
          ...CKETextAnswerTools,
        },
      };

      if (calcEnabledFromUserInfo || graphingCalcEnabledFromUserInfo) {
        segmentConfig = {
          ...segmentConfig,
          ckeConfig: updateConfigForTool(
            segmentConfig.ckeConfig,
            CalcTool.toolbarName
          ),
        };
      }

      // React Hook "useState" is called conditionally. Remove error.
      if (spellcheckEnabled && !printMode) {
        segmentConfig = {
          ...segmentConfig,
          ckeConfig: updateConfigForTool(
            segmentConfig.ckeConfig,
            SpellCheckTool.toolbarName
          ),
        };
      }

      if (sttEnabled) {
        segmentConfig = {
          ...segmentConfig,
          ckeConfig: updateConfigForTool(
            segmentConfig.ckeConfig,
            SpeechToTextTool.toolbarName
          ),
        };
      }
    } else if (experienceViewMode == "apGradeTest") {
      const allowed =
        userResponse &&
        userResponse.text_answer &&
        userResponse.text_answer.length &&
        !currentBackup.show_backup
          ? true
          : false;

      console.log("experience id in ap grade test ===>", experienceUser)
      const userId = experienceUser.user_id;
      const annotationOptions = {
        allowed: allowed,
        uri: `uId_${userId}_urId_${userResponseId}_sId_${segment_id}`,
        user: currentUser.role,
        // user: currentUser.id, // SS thinks should save id - only edit your own
        user_id: userId,
        experience_id: experience_id,
        topic_id: topic_id,
        segment_id: segment_id,
        user_response_id: userResponseId,
        // multiple attempt support not yet
      };
      segmentConfig = {
        ...segmentConfig,
        presentationMode: true,
        autoCheckMode: true,
        // showCorrectAnswerSwitch: true,
        showCorrectAnswerSwitch: !printMode,
        annotationOptions: annotationOptions,
        unique_segment_identifier: `${segment_id}_${userId}`,
      };

      if (
        currentBackup.fb_response_id &&
        segment_id == currentBackup.segment_id &&
        currentBackup.show_backup
      ) {
        console.log("current backup ==>", currentBackup);
        segmentConfig = {
          ...segmentConfig,
          ...currentBackup,
          text_answer: currentBackup.text_answer, // Individually updating as in some cases there is no text_answer variable so its not merges and old data showing
          response_json: currentBackup.response_json,
          game_status: currentBackup.game_status,
          attempt_status: currentBackup.attempt_status,
        };
      }
    } else {
      // create
      segmentConfig = {
        ...segmentConfig,
        presentationMode: true,
        showCorrectAnswer: true,
        autoCheckMode: true,
      };
    }

    // const triggerStateUpdate = `${initialData.user_id}_${initialData.attempt_no}` // add response_json also or just set to initialData?
    // const triggerStateUpdate = initialData
    console.log("initialData userResponse", initialData);
    segmentConfig = {
      ...segmentConfig,
      onChange: (result) => {
        console.log("value sent for saving ==>", result);

        dispatch(
          firestoreInteractionActions.savingStatusSuccess({
            segment_id: segment_id,
            status: {
              offline_status: "saving",
              online_status: "saving",
            },
          })
        );

        if (result.response_json) {
          let responseJSON = result.response_json;
          responseJSON.json_uuid = getUniqueId();
          // To support - in react data sheet datasource is array of arrays and jsonb does not save array of arrays
          if (question_type == "rds_table") {
            if (typeof responseJSON.segment_data.table_data == "object") {
              responseJSON.segment_data.table_data = JSON.stringify(
                responseJSON.segment_data.table_data
              );
            }
          }
          // To support - in canvas path is array of arrays and jsonb does not save array of arrays
          if (
            question_type == "fabric_drawing" ||
            question_type == "fabric_hotspot"
          ) {
            if (typeof responseJSON.segment_data.canvas_data == "object") {
              responseJSON.segment_data.canvas_data = JSON.stringify(
                responseJSON.segment_data.canvas_data
              );
            }
          }
        }

        // result object should also update in mini_quiz
        setUserResponse({
          responseJSON: result.response_json,
          textAnswer: result.text_answer,
          // gameStatus: result.game_status,  // commenting out for now - need to make fields in supabase
          attemptStatus: result.attempt_status,
          attachmentsJSON: result.attachments_json,
          includeTextAnswer: segmentItemStateSetting.includeTextAnswer || false,
          extraInteractive: segmentItemStateSetting.extraInteractive || "",
          filesUploaded:
            segmentItemStateSetting.allowStudentUploadFiles || false,
        });
      },
    };
    if (question_type === "cke_subjective") {
      segmentToRender = <CKESubjective {...segmentConfig}></CKESubjective>;
    } else if (question_type === "mcq_single") {
      segmentToRender = <MCQSingle {...segmentConfig}></MCQSingle>;
    } else if (question_type === "mcq_multiple") {
      segmentToRender = <MCQMultiple {...segmentConfig}></MCQMultiple>;
    } else if (question_type === "true_false") {
      segmentToRender = <TrueFalse {...segmentConfig}></TrueFalse>;
    } else if (question_type === "fabric_drawing") {
      segmentToRender = <FabricDrawing {...segmentConfig}></FabricDrawing>;
    } else if (
      question_type === "fabric_dnd_label" ||
      question_type === "fabric_fill_label"
    ) {
      segmentToRender = <FabricLabel {...segmentConfig}></FabricLabel>;
    } else if (question_type === "fabric_hotspot") {
      segmentToRender = <FabricHotspot {...segmentConfig}></FabricHotspot>;
    } else if (question_type === "ggb_graph") {
      segmentToRender = <GGBGraph {...segmentConfig}></GGBGraph>;
    } else if (question_type === "desmos_graph") {
      segmentToRender = <DesmosGraph {...segmentConfig}></DesmosGraph>;
    } else if (question_type === "rbd_inline_matching") {
      segmentToRender = (
        <RBDInlineMatching {...segmentConfig}></RBDInlineMatching>
      );
    } else if (question_type === "rbd_inline_sorting") {
      segmentToRender = (
        <RBDInlineSorting {...segmentConfig}></RBDInlineSorting>
      );
    } else if (question_type === "rbd_inline_classify") {
      segmentToRender = (
        <RBDInlineClassify {...segmentConfig}></RBDInlineClassify>
      );
    } else if (question_type === "rbd_matching") {
      segmentToRender = <RBDMatching {...segmentConfig}></RBDMatching>;
    } else if (question_type === "rbd_sorting") {
      segmentToRender = <RBDSorting {...segmentConfig}></RBDSorting>;
    } else if (question_type === "rbd_classify") {
      segmentToRender = <RBDClassify {...segmentConfig}></RBDClassify>;
    } else if (question_type === "rds_table") {
      segmentToRender = <RDSTable {...segmentConfig}></RDSTable>;
    } else if (question_type === "cke_fill_text") {
      segmentToRender = <CKEFillText {...segmentConfig}></CKEFillText>;
    } else if (question_type === "cke_fill_dropdown") {
      segmentToRender = <CKEFillDropDown {...segmentConfig}></CKEFillDropDown>;
    } else if (question_type === "audio_input") {
      segmentToRender = <AudioInput {...segmentConfig}></AudioInput>;
    } else if (question_type === "video_input") {
      segmentToRender = <VideoInput {...segmentConfig}></VideoInput>;
    } else if (question_type === "mini_quiz") {
      segmentToRender = <MiniQuiz {...segmentConfig}></MiniQuiz>;
    } else if (question_type === "flash_card") {
      segmentToRender = <FlashCard {...segmentConfig}></FlashCard>;
    } else if (question_type === "connect") {
      segmentToRender = <Connect {...segmentConfig}></Connect>;
    } else if (question_type === "file_upload") {
      segmentToRender = <FileUpload {...segmentConfig}></FileUpload>;
    } else if (question_type === "group") {
      // segmentToRender = <QuestionGroup {...segmentConfig} childrenRenderProps={childrenRenderProps}></QuestionGroup>;
      segmentToRender = "";
    }

    // if (segment.settings && segment.settings.include_explanation) {
    //   answerExplantion = <TextAnswerExplanation {...segmentConfig}></TextAnswerExplanation>
    // }

    if (
      !embedded &&
      !printMode &&
      !isPamoja &&
      gradingEnabled &&
      question_type !== "group" &&
      experienceViewMode === "apGradeTest" &&
      gradingConfig.gradingMode === "quick"
    ) {
      showBackupDropdown = true;
    }
  } else if (segment_type === "resource" && segment.resource_segment_attributes) {
    let resource_type = segment.resource_segment_attributes.resource_type;
    resourceTitle = (
      <div
        dangerouslySetInnerHTML={{
          __html: segment.resource_segment_attributes.title,
        }}
      ></div>
    );
    resourceCaption = (
      <div
        className={`resource-caption`}
        dangerouslySetInnerHTML={{
          __html: segment.resource_segment_attributes.caption,
        }}
      ></div>
    );
    segmentConfig = {
      ...segmentConfig,
      ...segment.resource_segment_attributes,
      topic_id: segment.parent_id,
      presentationMode: true,
    };
    if (resource_type === "embed") {
      segmentToRender = <EmbedView {...segmentConfig}></EmbedView>;
    }
    if (resource_type === "accordion") {
      segmentToRender = <Accordion {...segmentConfig}></Accordion>;
    } else if (resource_type === "audio") {
      segmentToRender = <AudioView {...segmentConfig}></AudioView>;
    } else if (resource_type === "video") {
      segmentToRender = (
        <VideoView
          {...segmentConfig}
          mediaShowSetting={{
            custom_player: true,
            segment_id: segment_id,
            segment: segment,
            showProcessingError: experienceSettings.mode === "offline"
            // hideFullScreenButton: getBrowser().includes("Safari") && experienceSettings.mode == "online_secure",
          }}
        ></VideoView>
      );
    } else if (resource_type === "image") {
      segmentToRender = <ImageView {...segmentConfig}></ImageView>;
    } else if (resource_type === "pdf") {
      segmentToRender = <PDFView {...segmentConfig}></PDFView>;
    } else if (resource_type === "simulation") {
      segmentToRender = <SimulationView {...segmentConfig}></SimulationView>;
    } else if (resource_type === "doc") {
      segmentToRender = <DocumentView {...segmentConfig}></DocumentView>;
    } else if (resource_type === "ppt") {
      segmentToRender = <PPTView {...segmentConfig}></PPTView>;
    }
  } else if (segment_type === "text") {
    contentToRender = (
      <RenderHtml uniqueTextIdentifier={segmentConfig.unique_segment_identifier} text={segment.text_segment_attributes.content} />
    );
  } else if (segment_type === "section") {
    console.log("segment_type =====>", segment_type, segment.id, nestingLevel, path);
    /* TODO - this is temp */
    let tempInner = [];
    // tempInner.push(<div key={"section-title"} dangerouslySetInnerHTML={{ __html: segment.section_segment_attributes.title }}></div>)
    // tempInner.push(<span key={"section-info"}>{segment.id} - {JSON.stringify(segment.children_order)} - {segment.points} pts</span>)
    points = segment.points;
    let childrenRender
    // Doing this because show full topic is in library this show only in case of library
    if (isLibrary) {
      childrenRender = (
        <Route
          path={`${path}`}
          render={(routeProps) => (
            <Suspense fallback={<Spinner />}>
              <LibrarySegments
                experienceViewMode={experienceViewMode}
                // experienceProps={{ item: experience }}
                // mobileUploadMode={mobileUploadMode}
                // embedded={embedded}
                // rearrangeMode={rearrangeMode}
                // readingModeEnabled={readingModeEnabled}
                // topicProps={{ item: segment }}
                // children={segment.descendants} // would have had to find children from descendants
                isLibrary={true}
                isLibraryModal={isLibraryModal}
                parentId={segment.id}
                activeTopicIdx={activeTopicIdx}
                parentIdxs={parentIdxs}
                nestingLevel={nestingLevel + 1}
                // gradingConfig={gradingConfig}
                // experienceUser={experienceUser}
                params={{
                  is_library: true,
                  by_original: true,
                  is_library_modal: isLibraryModal,
                }}
                // createUpdateParams={{
                //   experience_id: segment.experience_id,
                //   parent_id: segment.id,
                // }}
                libraryModalProps={libraryModalProps}
                isRoute={false}
                {...routeProps}
                config={{
                  filter: {
                    show: false,
                    config: {
                      create: { show: false },
                      search: { show: false },
                    },
                  },
                  pagination: { show: false },
                  list: {
                    widget: SegmentList, // TODO: not working here wihtout explicitly mentioning widget - something strange in framework - overriding default list widget with undefined even just if LibrarySegments is imported
                    shouldExecuteRequest: false, // No fetch, getting from redux
                  },
                }}
              />
            </Suspense>
          )}
        />
      );
    } else {
      childrenRender = (
          <Route
            path={`${path}`}
            render={(routeProps) => (
              <Suspense fallback={<Spinner />}>
                <Segments
                  experienceViewMode={experienceViewMode}
                  experienceProps={{ item: experience }}
                  mobileUploadMode={mobileUploadMode}
                  printMode={printMode}
                  embedded={embedded}
                  rearrangeMode={rearrangeMode}
                  readingModeEnabled={readingModeEnabled}
                  topicProps={{ item: segment }}
                  parentId={segment.id}
                  parentSegment={segment}
                  activeTopicIdx={activeTopicIdx}
                  parentIdxs={parentIdxs}
                  nestingLevel={nestingLevel + 1}
                  gradingConfig={gradingConfig}
                  experienceUser={experienceUser}
                  params={{
                    by_experience_id: segment.experience_id,
                    by_parent_id: segment.id,
                  }}
                  createUpdateParams={{
                    experience_id: segment.experience_id,
                    parent_id: segment.id,
                  }}
                  libraryModalProps={libraryModalProps}
                  isRoute={false}
                  {...routeProps}
                  config={{
                    list: {
                      shouldExecuteRequest: false,
                    },
                    //   routes: { fetch: false }, // don't fetch even if empty - in this case, always will be present in redux and getting from selector
                  }}
                />
              </Suspense>
              
            )}
          />
        );
    }

    // segment.id === 1192857 && tempInner.push(childrenRender)
    tempInner.push(childrenRender);

    segmentToRender = tempInner;
    contentToRender = (
      <RenderHtml uniqueTextIdentifier={segmentConfig.unique_segment_identifier} text={segment.section_segment_attributes.title} />
    );
  }

  if (
    experienceViewMode === "apTakeTest" ||
    experienceViewMode === "apPreviewTest"
  ) {
    segmentFullScreenButton = (
      <FullScreenButton
        fullscreen={segmentItemStateSetting.segmentFullScreen}
        clicked={() =>
          setSegmentItemStateSetting({
            ...segmentItemStateSetting,
            segmentFullScreen: !segmentItemStateSetting.segmentFullScreen,
            modalVisible: !segmentItemStateSetting.segmentFullScreen,
          })
        }
      />
    );

    if (segment_type === "question") {
      if (
        question_type !== "cke_subjective" &&
        question_type !== "group" &&
        !printMode
      ) {
        if (allowIncludeTextAnswer) {
          if (!segmentItemStateSetting.includeTextAnswer) {
            extraTextAnswerButton = (
              <CustomTextButton
                buttonText={mrIntl("SegmentItemDetail.add_text_answer")}
                clicked={() => {
                  setSegmentItemStateSetting({
                    ...segmentItemStateSetting,
                    includeTextAnswer: true,
                  });
                }}
              />
            );
          }

          if (segmentItemStateSetting.includeTextAnswer) {
            extraTextAnswer = (
              <CKETextAnswer {...segmentConfig} answerMode={true} />
            );
          }
        }
      } else {
        const extraInteractiveType = segmentItemStateSetting.extraInteractive;
        // const optionPrefix = extraInteractiveType ? '' : 'Add'
        const options = [
          { label: mrIntl("CommonText.drawing"), value: "fabric_drawing" },
          { label: mrIntl("SegmentItemDetail.geogebra_label"), value: "ggb_graph" },
          { label: mrIntl("SegmentItemDetail.add_desmos_label"), value: "desmos" },
        ];
        extraInteractiveSelect = (
          <MrSelect
            config={{
              // style: { width: '200px' },
              placeholder: mrIntl("SegmentItemDetail.select_interactive_placeholder"),
              value: extraInteractiveType || null,
              allowClear: true,
              onChange: (value) => {
                console.log("On change happening in extra interactive ", value);
                setSegmentItemStateSetting({
                  ...segmentItemStateSetting,
                  extraInteractive: value,
                });
              },
            }}
            options={options}
          />
        );

        if (extraInteractiveType) {
          if (extraInteractiveType == "fabric_drawing") {
            extraInteractive = (
              <FabricDrawing {...segmentConfig}></FabricDrawing>
            );
          } else if (extraInteractiveType == "ggb_graph") {
            extraInteractive = <GGBGraph {...segmentConfig}></GGBGraph>;
          } else if (extraInteractiveType == "desmos_graph") {
            extraInteractive = <DesmosGraph {...segmentConfig}></DesmosGraph>;
          }
        }
      }

      // if (
      //   segmentItemStateSetting.allowStudentUploadFiles &&
      //   (experienceSettings.mode == "online" ||
      //     (experienceSettings.mode == "online_secure" &&
      //       (isMobile || checkIPAD())) ||
      //     (experienceSettings.mode == "offline" && isMobile))
      // ) {
      //   responseAttachmentsButton = (
      //     <div>
      //       <UploadFilesButton
      //         buttonText={"Upload OR Modify"}
      //         clicked={() => {
      //           setSegmentItemStateSetting({
      //             ...segmentItemStateSetting,
      //             allowStudentUploadFiles: true,
      //             responseAttachmentFormVisible: true,
      //           });
      //         }}
      //       />
      //     </div>
      //   );
      // }

      if (
        allowResponseAttachments &&
        (segmentItemStateSetting.allowStudentUploadFiles ||
          initialData.attachments_json) &&
        !printMode
      ) {
        responseAttachments = (
          <ResponseAttachments
            {...segmentConfig}
            qNo={qNo}
            topic_id={topic_id}
            segment_id={segment_id}
            activeTopicIdx={activeTopicIdx}
            previewType={"modal"}
            recordId={`${experience_id}`}
            uploadType="QuestionWise"
            // modalVisible={
            //   segmentItemStateSetting.responseAttachmentFormVisible
            // }
            modalVisible={false}
            closeModal={() => {
              setSegmentItemStateSetting({
                ...segmentItemStateSetting,
                allowStudentUploadFiles: true,
                responseAttachmentFormVisible: false,
              });
            }}
            answerMode={
              segmentConfig.answerMode &&
              segmentItemStateSetting.responseAttachmentFormVisible &&
              segmentItemStateSetting.allowStudentUploadFiles
              // && (experienceSettings.mode == "online" ||
              // (experienceSettings.mode != "online" &&
              // (isMobile || checkIPAD())))
            }
          />
        );
      }

      if (!isMobile && !printMode && !embedded) {
        if (nestingLevel === 1) {
          bookMarkButton = (
            <BookmarkButton
              experienceViewMode={experienceViewMode}
              topic_idx={activeTopicIdx}
              question_number={qNo}
              segment_id={segment_id}
              question_id={question_id}
              topic_id={topic_id}
              experience_id={experience_id}
            />
          );
        }
      }

      if (
        !isMobile &&
        !printMode &&
        !embedded &&
        question_type != "group" &&
        allowSavingStatusIndicator
      ) {
        savingStatusIndicatorIcon = (
          <SavingStatusIndicator
            key={`saving-status-${segment_id}`}
            segment_id={segment_id}
            initialData={initialData}
          />
        );
      }
    }

    console.log("checking what is here segment type ==>", segment_type)
    if (!isMobile && 
      !embedded && 
      !printMode && 
      !isPinnedSegment && // This will not hide pinned segment button for pinned segments (As not passing from pinnedSegments)
      (segment_type === "resource" ||
        segment_type === "text" ||
        (segment_type === "question" && question_type === "group" && removeHTMLTagsFromText(segment.question_segment_attributes.content).length > 0)
      )
    ) {
      const sIndex = pinnedSegments.findIndex((s) => s.id === segment_id);
      const isSegmentPinned = sIndex >= 0;
      pinSegmentButton = (
        <PinButton
          tooltipTitle={
            isSegmentPinned 
              ? <MrTranslate id={"SegmentItemDetail.unpin"}/> 
              : <MrTranslate id={"SegmentItemDetail.pin"}/>
          }
          ghost={!isSegmentPinned}
          className="pin-button"
          shape="circle"
          type="primary"
          clicked={() => {
            // Comment out below to support multiple pinning
            // const newPinnedSegments = [...pinnedSegments]
            const newPinnedSegments = [];
            if (isSegmentPinned) {
              newPinnedSegments.splice(sIndex, 1);
            } else {
              newPinnedSegments.push(segment);
            }
            console.log("newPinnedSegments available ==>", newPinnedSegments);
            dispatch(
              segmentActions.pinnedSuccess({
                pinnedSegments: newPinnedSegments,
                isPinned: true,
              })
            );
          }}
        />
      );
    }

    if (isPinnedSegment) {
      contentToRender =
        <PinnedSegmentPlaceholder key={`pinned-segment-placeholder-${segment_id}`} segment_id={segment_id} />
    }
  } else if (experienceViewMode == "apGradeTest") {
    showSideGrader = true
    // showSideGrader =
    //   gradingConfig.gradingMode == "quick" || printMode ? true : false;
    showTopGrader = gradingConfig.gradingMode == "annotate" ? true : false;
    graderAttrs = {
      showSideGrader: showSideGrader || showTopGrader, // showing for all if apGradeTest
      gradingEnabled: gradingEnabled,
      // gradingEnabled: false, // student
      userResponse: userResponse,
      experienceUser: experienceUser,
      segment: segment,
      printMode: printMode,
      showAnnotatorToStudent,
    };

    let showFabricAnnotator = false
    if (
      segmentConfig.attachments_json &&
      segmentConfig.attachments_json.attachments && 
      segmentConfig.attachments_json.attachments.length > 0
    ) {
      showFabricAnnotator = true
      const onPreview = () => {
        dispatch(
          topicActions.setActiveIdSuccess({
            id: segment.parent_id,
          })
        );
        dispatch(
          segmentActions.setActiveIdSuccess({ id: segment.id })
        )
      }

      responseAttachments = (
        <>
          <ResponseAttachments
            {...segmentConfig}
            previewType={"modal"}
            modalVisible={false}
            answerMode={false}
            onPreview ={!embedded && onPreview}
          />
          {/* {gradingConfig.gradingMode == "quick" &&
            gradingEnabled &&
            !printMode &&
            !embedded && (
              <i style={{ color: "#999999" }}>
                {" "}
                To annotate, click on the thumbnail{" "}
              </i>
            )} */}
        </>
      );
    } else if (!printMode && experienceUser && 
      experienceUser.attachments_json && 
      experienceUser.attachments_json.attachments && 
      experienceUser.attachments_json.attachments.filter((atc) => !atc.segment_id).length
    ) {
      viewUnmappedFilesButton = 
        <>
          <Button onClick={() => {
            dispatch(
              topicActions.setActiveIdSuccess({
                id: segment.parent_id,
              })
            );
            dispatch(
              segmentActions.setActiveIdSuccess({ id: segment.id })
            )
          }}> <MrTranslate id={"CommonText.view_files"}/> </Button>
        </>
      showFabricAnnotator = true
    }
    if (
      showFabricAnnotator &&
      experienceUser.attachments_json && 
      experienceUser.attachments_json.attachments && 
      experienceUser.attachments_json.attachments.length
    ) {
      const annotatorConfig = {
        ...experienceUser,
        ...gradingConfig,
        eu_id: experienceUser.id,
        updateAttachmentsJSONWithAnnoation: (attachments_json) => {
          const newExperienceUser = {
            ...experienceUser,
            attachments_json: attachments_json,
          };
    
          console.log("newExperienceUser ==>", newExperienceUser);
          dispatch(
            experienceUserActions.showSuccess(
              { data: { experience_user: newExperienceUser } },
              { success: { showMessage: false } }
            )
          );
        },
      };
      fabricAnnotator = <FabricAnnotator
        {...annotatorConfig}
        gradingEnabled={gradingEnabled && !experienceUser.marked}
        attachments_json={experienceUser.attachments_json}
        showOnlyMapped={printMode}
        segmentId={segment.id}
      // updateAttachmentsJSON={updateAttachmentsJSON}
      />
    }
    console.log("showFabricAnnotator ==>", segment.id)

    if (segment_type == "question" && question_type != "cke_subjective") {
      if (initialData.text_answer || currentBackup.text_answer) {
        extraTextAnswer = <CKETextAnswer {...segmentConfig} />;
      }
    }

    console.log("SegmentItemDetail graderAttrs ==>", graderAttrs);

    contentToRender = <EllipsisContent>{contentToRender}</EllipsisContent>
  }

  // let answersSelectable = experienceViewMode == "apTakeTest" || experienceViewMode == "apPreviewTest" ? true : false
  let answersSelectable = segmentConfig.answerMode;

  let segmentIdentifier =
    segment.custom_fields.block && segment.custom_fields.block.id
      ? segment.uuid
      : segment_id;

  let questionNoSpan =
    (!isLibrary || (isLibrary && nestingLevel > 1)) &&
    qNo != undefined &&
    segment_type == "question"
      ? isMobile
        ? 2
        : qNo > 9 ? 2 : 1
      : 0;
  

  // defaulting segmentContentSpan to 20 now - instead of 21 - raised qNo psan min-width to 35 px for ui
  let segmentContentSpan =
    segment_type == "question"
    ? showExperiencePreview == true
    ? 19
    : isLibrary == true && nestingLevel === 1
        ? 20
        : qNo == undefined
        ? 21
        : isMobile
        ? 19
        : 20 // case where embedded and group - has no qno coming from segmentList above
      : currentUser.role === "qb_author"
      ? 20
      : 24;

  
  let pointsSpan =
    (
      (experienceSettings.grading_setting == "per_question" ||
      (experience.rubric &&
        experience.rubric.type_c == "myp_achievement_level")) &&
      segment_type == "question" &&
      (!showSideGrader)
    ) ||
    isLibraryModal || isSegmentInAIList
      ? (isLibrary == true || isSegmentInAIList) && nestingLevel === 1
        ? 3
        : 2
      : currentUser.role === "qb_author"
      ? 3
      : partnerName === "osc"
      ? 2
      : 0;

  if(showSideGrader){
    segmentContentSpan = 24 - (questionNoSpan + pointsSpan)
  }
  if (printMode) {
    segmentContentSpan = segmentContentSpan - 1
  }
  console.log("spanValue==>", questionNoSpan, segmentContentSpan, pointsSpan, showSideGrader);

  let segmentItemClassName = "segment-item";
  // segmentItemClassName = checkMob() ? segmentItemClassName.concat(" ", "mobile") : segmentItemClassName
  segmentItemClassName = showSideGrader
    ? segmentItemClassName.concat(" ", "show-side-grader")
    : segmentItemClassName;
  // segmentItemClassName = answersSelectable
  //   ? segmentItemClassName.concat(" ", "answers-selectable")
  //   : segmentItemClassName;
  segmentItemClassName = isPamoja
    ? segmentItemClassName.concat(" ", "pamoja-block")
    : segmentItemClassName;
  segmentItemClassName = annotateAttachmentMode
    ? segmentItemClassName.concat(" segment-annotator")
    : segmentItemClassName;
  segmentItemClassName = printMode
    ? segmentItemClassName.concat(" ", "print-mode")
    : segmentItemClassName;
  // segmentItemClassName = printMode && segment_type === "text"
  //   ? segmentItemClassName.concat(" ", "bordered-content")
  //   : segmentItemClassName;
  // segmentItemClassName = gradingConfig.gradingMode == "annotate" ? segmentItemClassName.concat(" ", "annotate-mode") : segmentItemClassName
  segmentItemClassName = segmentSettings.oscSettings.shouldBlurContent
    ? segmentItemClassName.concat(" ", "osc-blur-content")
    : segmentItemClassName;
  segmentItemClassName = segmentSettings.oscSettings.shouldBlurContent
    ? segmentItemClassName.concat(" ", "image-zoom-disabled")
    : segmentItemClassName.concat(" ", "image-zoom-enabled");

  let showQuestionsContent = true;
  if (printMode) {
    if (printOptions && printOptions.with_questions === "false") {
      if (segment.segment_type === "question") {
        showQuestionsContent = false;
      }
    }
  }

  // Setting response attachments to null for file upload type as it has its own attachments comoonent
  if (question_type === "file_upload") {
    responseAttachments = null
  }

  let criteriaPointsView = [];
  if (segment.criterium_associations_attributes) {
    segment.criterium_associations_attributes.map((ca, i) => {
      criteriaPointsView.push(
        <Tooltip
          key={`criteria-${ca.id}`}
          title={ca.custom_fields.title + ": " + ca.custom_fields.label}
        >
          <Tag>
            {ca.custom_fields.title}{" "}
            {experience.rubric &&
              experience.rubric.type_c == "myp_achievement_level"
              ? ""
              : displayPoints(ca.points)}
          </Tag>
        </Tooltip>
      );
    });
  }

  console.log("criteriaPointsView", showFullPathLabel, segment.customPropSegments);

  let questionIndexToRender = (
    <span
      className={`question-number ${
        partnerName === "osc" ? "osc-question-number" : ""
      }`}
    >
      {(nestingLevel === 1) && "Q"}{" "}
      {/* using !segment.doNotUseLabelPath because we don't have parentIdxs */}
      {segment_type == "question" && (showFullPathLabel && !segment.doNotUseLabelPath ? getLabelFromIndexPath(parentIdxs) : qNo)}
    </span>
  );

  console.log("SegmentItemDetail logs ===> questionIndex", {questionIndexToRender, questionNoSpan, parentIdxs, nestingLevel, segment, qNo});
  

  let segmentCardBodyUploadMode = (
    <Row>
      <Col
        key={`segment-item-detail-${isLibrary ? "library-" : ""}${segment_id}`}
        span={24}
        className={segmentItemClassName}
        ref={segmentItem}
      >
        <Row>
          <Col span={24}>{questionIndexToRender}</Col>
          <Col span={24}>
            {question_type === "group" ? (
              <QuestionGroup
                {...segmentConfig}
                childrenRenderProps={childrenRenderProps}
              ></QuestionGroup>
            ) : (
              responseAttachments
            )}
            {/* {responseAttachments} */}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  let segmentCardBodyExplanationMode = (
    <Row>
      <Col
        key={`segment-item-detail-${isLibrary && "library-"}${segment_id}`}
        span={24}
        className={segmentItemClassName}
        ref={segmentItem}
      >
        <Row>
          {!printMode && (
            <Col span={24}>
              <Row className={"explanation-header"}>
                <Col span={12} className={"left-side"}>
                  {mrIntl("SegmentItemDetail.solution_to")}{" "}
                  {questionIndexToRender}
                </Col>
                <Col span={12} className={"right-side"}>
                  [{displayPoints(segment.points)}]
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <AnswerExplanation
              {...segmentConfig.segmentFooterAttributes}
              childrenRenderProps={childrenRenderProps}
              segmentSettings={segmentSettings}
              setRenderMath={setRenderMath}
              showChildrenExplanations={question_type == "group"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  let segmentCardBody = (
    <Row key={`segment-item-detail-${isLibrary && "library-"}${segment.id}`} className={`${activeLogsHistoryForSegmentId === segment.id ? "segment-log-view" : ""} ${isAvoidBreakInsideInPrint ? "break-inside-avoid" : ""} ${printMode ? "m-t-10" : ""}`}>
      <Col span={activeLogsHistoryForSegmentId ? 10 : 24} className={segmentItemClassName} ref={segmentItem} data-id={`segment-id-${segment.id}`}>
        {/* {userResponse && userResponse.id && <Feedback graderAttrs={graderAttrs}/>}  */}
        {/* Make conditional for when submitted test - teacher and student have to see */}
        {/* Add TaggerComponent or maybe outside */}
        {/* Add ActionsComponent - Reset, try again, bookmark */}

        {/* TODO: top or bottom fix */}
        {/* {showTopGrader && <Row>
          <Col span={24} className="side-grader">
            <Feedback graderAttrs={graderAttrs} />
          </Col>
        </Row>} */}
        <Row className={"segment-item-inner"}>
          <Col
            span={
              showSideGrader ? (printMode ? 24 : 17) : modalVisible ? 20 : 24
            }
            className={`segment-content ${printMode && segment.segment_type !== "question" && "bordered-content"} ${showSideGrader && !printMode && "p-30"} ${!_.isEmpty(activeSegment) && "with-max-height"}`}
          // className={
          //   showSideGrader
          //     ? printMode
          //       ? ""
          //       : "p-30 segment-content"
          //     : "segment-content"
          // }
          >
            <Row className="question-render">
              {pinSegmentButton}
              {bookMarkButton}
              <Col span={questionNoSpan} style={{ minWidth: isSegmentInAIList ? "20px" : "50px" }}>
                {/* {questionIndexToRender} */}
                <Tooltip
                  title={
                    import.meta.env.VITE_MODE !== "production" &&
                      parentIdxs ? (
                      <>
                        {parentIdxs.join(".")}{" "}
                        {getLabelFromIndexPath(parentIdxs)}
                      </>
                    ) : (
                      ""
                    )
                  }
                >
                  {questionIndexToRender}
                </Tooltip>
              </Col>

              {showQuestionsContent && 
              <Col span={segmentContentSpan}>
                <span 
                  className={`question-text ${partnerName === "osc" ? "osc-question-text" : ""} `}>
                    {contentToRender}
                </span>
              </Col>}
              {/* TODO: NEED to separate segmentConfigFromRedux - embed case */}
              <Col
                span={
                  (experienceConfigTakeExperienceFromRedux.start.segment.points
                    .show || !experience) && (!segment.doNotUseLabelPath)
                    ? pointsSpan
                    : 0
                }
                style={{ textAlign: "right", minWidth: isSegmentInAIList ? "25px" : "33px" }}
              >
                {segment.criterium_associations_attributes &&
                  segment.criterium_associations_attributes.length > 0 ? (
                  criteriaPointsView
                ) : (
                  <Tooltip
                    title={
                      partnerName === "osc"
                        ? ""
                        : `${displayPoints(points)} pt${parseFloat(points).toFixed(1) != 1 ? "s" : ""
                        }`
                    }
                  >
                    <span>
                      {points && experienceViewMode != "apEditTest" &&
                        segment_type == "question" &&
                        segmentMode != "quick_check" &&
                        userResponse &&
                        userResponse.points ? (
                        <span>{displayPoints(userResponse.points)}/</span>
                      ) : (
                        ""
                      )}

                      {points &&
                        // ["question", "section"].indexOf(segment_type) > -1 &&
                        segment_type == "question" &&
                        (segmentMode != "quick_check" || partnerName === "osc") //basically only NOT for pamoja segment embeds - in osc quick_check from url but we want to show points
                        ? "[" + displayPoints(points) + "]"
                        : ""}
                    </span>
                  </Tooltip>
                )}
              </Col>
              {experienceConfigTakeExperienceFromRedux.start.segment.ePoints
                .show && (
                  <EngagementPoints
                    segment={segment}
                    userResponse={firebaseUserResponse}
                  />
                )}
              {embedded &&
                partnerName !== "osc" &&
                segmentIdentifier &&
                currentUser.role !== "student" ? (
                <div
                  style={{
                    // width: "20px",
                    position: "absolute",
                    right: "0px",
                    // right: "-30px",
                    top: "0px",
                    color: "#d0d5dd",
                  }}
                >
                  <Tooltip title={segmentIdentifier} trigger={'click'} placement="leftBottom">
                    <InfoCircleOutlined
                      onClick={() => {
                        writeToClipboardPolyfill(segmentIdentifier);
                      }}
                    />
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
              {savingStatusIndicatorIcon}
            </Row>
            {resourceTitle && !printMode && <h4 className={`resource-title`}>{resourceTitle}</h4>}

            {!printMode && resourceCaption}

            {showBackupDropdown && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <BackupDropdown
                    experienceId={experience_id}
                    userId={experienceUser.user_id}
                    currentUser={currentUser}
                    experience={experience}
                    segmentId={segment.id}
                    currentBackup={currentBackup}
                    dbBackupStatus={dbBackupStatus}
                    setCurrentBackup={setCurrentBackup}
                    userResponse={userResponse}
                    showStudentsList={gradingConfig.showStudentsList}
                    setTriggerStateUpdate={setTriggerStateUpdate}
                    setRenderMath={setRenderMath}
                    experienceUser={experienceUser}
                  />
                </Col>
              </Row>
            )}

            {/* <Row style={{ marginTop: "10px" }}> */}
            <Row>
              <Col span={24}>{segmentToRender}</Col>
            </Row>
            {/* {printMode && printOptions.print_type === "student_submission" && printOptions.with_answer_explanation === "true" && segmentConfig.segmentFooterAttributes.answer_explanation && <Col span={24}>
                <Card
                className="print-answer-explanation"
                style={{marginTop: "20px"}}
                title="Teacher's Explanation"
              >
              <RenderHtml text={segment.question_segment_attributes.answer_explanation} />
              </Card>
            </Col>} */}
            {console.log("showExplanationBody==>", includeMarkscheme)}
            {includeMarkscheme && <Row style={{marginTop: printMode && "20px"}}>
              <Col span={24}><Markscheme {...segmentConfig}></Markscheme></Col>
            </Row>}

            {!embedded && !printMode && experienceViewMode === "apEditTest" && (
              <SegmentRelations segmentRelations={segment.relation_items} segmentCAs={segment.criterium_associations_attributes} className={`segment-relations ${nestingLevel > 1 ? "p-l-40" : ""}`} experienceData={segment.experience_data} />
            )}

            {!embedded && !printMode && experienceViewMode === "apEditTest" && (
              <Suspense fallback={<Spinner />}>         
                <IBFields segment={segment} />
              </Suspense>
            )}

            {!embedded && segment_type === "question" && (extraTextAnswer || extraInteractive || extraTextAnswerButton) && (
              <Row style={{ marginTop: "20px" }}>
                <Col span={24}>{extraTextAnswer || extraInteractive}</Col>

                <Col span={24} style={{ textAlign: "right" }}>
                  {/* {extraTextAnswerButton || extraInteractiveSelect} */}
                  {extraTextAnswerButton}
                </Col>
              </Row>
            )}
            {segment_type === "question" && question_type !== "group" && (
              <Row>
                <Col span={24}>
                  {!embedded && 
                  experienceViewMode === "apGradeTest" && 
                  ((!isEmpty(activeSegment) && 
                  activeSegmentQuestionType !== "group") || printMode) 
                    ? fabricAnnotator 
                    : responseAttachments}

                  {!embedded && 
                  experienceViewMode === "apGradeTest" && 
                  activeSegmentId === "all" && 
                  viewUnmappedFilesButton}
                </Col>
              </Row>
            )}
          </Col>
          {
             !printMode && <Col
            span={showSideGrader ? (printMode ? 4 : 7) : 0}
            offset={0}
            className="side-grader"
          >
            {<UserResponseFeedback graderAttrs={graderAttrs} />}
          </Col>
          }
          {
             printMode && <Col
            span={showSideGrader ? 24 : 0}
            offset={0}
            className="side-grader"
          >
            {<UserResponseFeedback graderAttrs={graderAttrs} />}
          </Col>
          }
        </Row>

        {/* NOTE: @SHIKHAR why we needed showTopGrader */}
        {/* {showTopGrader && (
          <Row className={"bottom-grader"}>
            <Col span={24} className="side-grader">
              <UserResponseFeedback graderAttrs={graderAttrs} />
            </Col>
          </Row>
        )} */}
        {/* NOTE: checking active activeLogsHistoryForSegmentId condition to avoid nested questions render in log modal */}
        {/* NOTE: notRenderQGChildren - This value is set to true when the question group is pinned, indicating that we don't want to render its children in the pinned area */}
        {question_type === "group" && (activeLogsHistoryForSegmentId !== segment_id && 
        !notRenderQGChildren) && (
          <QuestionGroup
            {...segmentConfig}
            childrenRenderProps={childrenRenderProps}
          ></QuestionGroup>
        )}
      </Col>
      <Col span={activeLogsHistoryForSegmentId ? 6 : 0} className="history-items-wraper">
      <Suspense fallback={<Spinner />}>
        <SegmentHistory experience={experience} segmentId={segment.id} key={`logs-history-${segment.id}`} onChange={setCurrentHistoryOfSegment} />
        </Suspense>
      </Col>
    </Row>
  );

  if (segment_type == "question" && experienceViewMode == "apTakeTest") {
  }

  const handleOk = () => {
    setSegmentItemStateSetting({
      ...segmentItemStateSetting,
      modalVisible: false,
      segmentFullScreen: false,
      annotateAttachmentMode: false,
    });
  };

  const handleCancel = () => {
    setSegmentItemStateSetting({
      ...segmentItemStateSetting,
      modalVisible: false,
      segmentFullScreen: false,
      pinSegment: false,
      annotateAttachmentMode: false,
    });
  };

  let finalRender = [];
  // let finalRender = null;
  // if (modalVisible) {
  //   let modalBodyItem = null;
  //   if (segmentItemStateSetting.segmentFullScreen) {
  //     modalBodyItem = segmentCardBody;
  //   }
  //   finalRender.push(
  //     <Modal
  //       visible={modalVisible}
  //       width="95%"
  //       className={"full-screen-modal"}
  //       closable={true}
  //       maskClosable={false}
  //       keyboard={false}
  //       centered={true}
  //       onOk={() => handleOk()}
  //       onCancel={() => handleCancel()}
  //       footer={null}
  //     >
  //       {modalBodyItem}
  //     </Modal>
  //   );
  // }

  // if (segmentItemStateSetting.pinSegment) {
  //   finalRender.push(
  //     <Drawer
  //       key="segment-drawer"
  //       visible={segmentItemStateSetting.pinSegment}
  //       width={"30%"}
  //       placement="left"
  //       mask={false}
  //       maskClosable={false}
  //       onClose={() => handleCancel()}
  //     >
  //       {segmentCardBody}
  //     </Drawer>
  //   );
  // }
  console.log("showExplanationBody==>", onlyExplanationMode)

  if (
    (!segmentItemStateSetting.segmentFullScreen &&
    !segmentItemStateSetting.pinSegment )
  ) {
    console.log("showExplanationBody nestingLevel", nestingLevel)

    if (mobileUploadMode) {
      finalRender.push(segmentCardBodyUploadMode);
    } else if (onlyExplanationMode) {
      let showExplanationBody =
        segment.segment_type === "question" &&
        (nestingLevel !== 3 ||
          (nestingLevel === 3 &&
            (segment.answer_explanation ||
              (segment.question_segment_attributes &&
                segment.question_segment_attributes.attachments_json &&
                (segment.question_segment_attributes.attachments_json
                  .pdf_answer_explanation ||
                  segment.question_segment_attributes.attachments_json
                    .videoAnswerExplanation)))));
      if (showExplanationBody) {
        finalRender.push(segmentCardBodyExplanationMode);
      }
    } else {
      finalRender.push(segmentCardBody);
    }
  }

  console.log(
    "SegmentItemDetail state before render ==>",
    segmentItemStateSetting,
    segment_type,
    question_type,
    segmentConfig
  );
  return (
    <React.Fragment key={`segment-${segment_id}`}>
      {/* <ErrorBoundary message="Some error occured" description={`Error inside SegmentItemDetail with id: ${segment.id}`}> */}
      <ApErrorBoundary
        fallback={`Error inside SegmentItemDetail with id: ${segment.id}`}
      >
        <DetectInsideClick
          detectInside={
            segment_type == "question" && segmentConfig.answerMode && !printMode
          }
          callback={(event) => {
            console.log("segment_id inside detect", segment_id);
            setTriggerSetActiveSegment(getUniqueId());
          }}
        >
          {finalRender}
        </DetectInsideClick>
      </ApErrorBoundary>
      {/* </ErrorBoundary> */}
    </React.Fragment>
  );
};

SegmentItemDetail.defaultProps = {};

SegmentItemDetail.propTypes = {};

export default SegmentItemDetail;

// const mapSizesToProps = (props) => {
//   const { width } = props;
//   return {
//     screenSettings: {
//       isMobile: width < 600,
//     },
//   };
// };

// export default withSizes(mapSizesToProps)(SegmentItemDetail);
