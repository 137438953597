import React from "react";
// import "./GroupItemDetail.scss";
import {Route, useRouteMatch} from "react-router-dom";
import {Tabs, Card, List, Checkbox, Space, Col, Row, Button, ColorPicker, Input, Tooltip, Modal, Popconfirm } from "antd";
import { getActiveKeyFromPathname, getFromLS, getUniqueId } from "/src/lib/utils/helperMethods";
import { useState } from "react";
import { useEffect } from "react";
import OrgProgrammes from "/src/views/OrgProgrammes/OrgProgrammes";
import OrgProgrammeList from "/src/views/OrgProgrammes/OrgProgrammeList/OrgProgrammeList";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { useDispatch, useSelector } from "react-redux";
import AIUsage from "./AIUsage/AIUsage";
import OneRoster from "/src/views/Orgs/OrgItemDetail/OneRoster";
import OrgAuditLogs from "/src/views/OrgAuditLogs/OrgAuditLogs";
import OrgPermissions, { OldPermissionsUI } from "/src/views/OrgProgrammes/OrgPermissions/OrgPermissions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import BulkImportUsers from "/src/views/Users/BulkImportUsers/BulkImportUsers";
import OrgIntegration from "../../OrgIntegration/OrgIntegration";
import BulkImportGroups from "../../Groups/BulkImportGroups/BulkImportGroups";
import { isArray } from "lodash";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  actions as loginActions
} from "/src/views/Auth/Login/redux";
import "./OrgItemDetail.scss";
import { actions as tagActions } from '/src/views/Tags/redux.js';

const ModalContent = ({ currentGradingScale, setCurrentGradingScale }) => {
  
  const mrIntl = useTranslate();
  const currentGradingScaleLevels = currentGradingScale?.levels_json || [];
  const currentGradingScaleLevelsCount = currentGradingScaleLevels.length;

  if (currentGradingScale?.archived) {
    return null;
  }

  const deleteLevel = (j) => {
    let newScale = {...currentGradingScale};
    newScale.levels_json = newScale.levels_json.filter((item, index) => index !== j);
    setCurrentGradingScale(newScale);
  };

  const addLevel = () => {
    const newGradingScale = {...currentGradingScale}
    let newLevel = {
      uuid: `level_${getUniqueId(10)}`,
      color: "",
      label: "",
      archived: false,
      percentage: 0
    };

    newGradingScale.levels_json.push(newLevel);
    console.log("newGradingScale ===>", newGradingScale);
    setCurrentGradingScale(newGradingScale);
  };

  const updateGradingScales = (uuid, key, value, levelUuid = null) => {
    if (levelUuid) {
      const levelIndex = currentGradingScale.levels_json.findIndex((level) => level.uuid === levelUuid);
      if (levelIndex === -1) {
        message.error('Level not found');
        return;
      }
      if (key === "percentage") {
        value = parseInt(value);
      }
      currentGradingScale.levels_json[levelIndex][key] = value;
    } else {
      if (key === "percentage") {
        value = parseInt(value);
      }
      currentGradingScale[key] = value;
    }

    setCurrentGradingScale({ ...currentGradingScale });
  };

  const scaleRow = currentGradingScaleLevelsCount > 0 ? currentGradingScaleLevels.map((level, j) => (
    <Row gutter={[16, 16]} align={'middle'} className="m-b-10" key={level.uuid}>
      <Col span={4}>
        <ColorPicker
          defaultValue="black"
          value={level.color}
          onChange={(_, hex) => updateGradingScales(level.uuid, "color", hex, level.uuid)}
        />
      </Col>
      <Col span={6} className="p-l-5">
        <Input
          placeholder={mrIntl("OrgItemDetail.enter_label")}
          value={level.label}
          onChange={(e) => updateGradingScales(level.uuid, "label", e.target.value, level.uuid)}
        />
      </Col>
      <Col span={6} offset={1}>
        <Input
          type="number"
          min={0}
          max={100}
          placeholder={mrIntl("OrgItemDetail.enter_percentage")}
          value={level.percentage}
          onChange={(e) => {
            const value = e?.target?.value;
            if (value?.length <= 3) {
              updateGradingScales(level.uuid, "percentage", value, level.uuid);
            }
          }}
        />
      </Col>
      <Col span={6} offset={1}>
        <Tooltip title={currentGradingScaleLevelsCount === 1 ? mrIntl("OrgItemDetail.minimum_required_limit") : null}>
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => deleteLevel(j)}
            disabled={currentGradingScaleLevelsCount === 1}
          />
        </Tooltip>
      </Col>
      {j === currentGradingScaleLevelsCount - 1 && (
        <Button
          type="dashed"
          key="add-level-button"
          onClick={() => addLevel()}
          className="m-t-10 m-l-10"
        >
          {mrIntl("OrgItemDetail.add_row")}
        </Button>
      )}
    </Row>
  )) : [];

  return (
    <div className="grading-scale-edit-mode-wrapper">
      <Row className="grading-scale-row">
        <Col span={24} className="m-b-10">
          <Input
            placeholder={mrIntl("OrgItemDetail.enter_grading_scale_title")}
            value={currentGradingScale?.title}
            onChange={(e) => updateGradingScales(currentGradingScale?.uuid, "title", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Row span={24} key={"header-row"}>
            <Col span={4}> {mrIntl("OrgItemDetail.color")} </Col>
            <Col span={6}> {mrIntl("OrgItemDetail.proficiency_level")} </Col>
            <Col span={6} offset={1}> {mrIntl("OrgItemDetail.performance_threshold")} (%) </Col>
            <Col span={6} offset={1}> {mrIntl("OrgItemDetail.remove")} </Col>
          </Row>
          {scaleRow}
        </Col>
      </Row>
    </div>
  );
};

const StandardsBasedGradingScales = (props) => {
  const { org, updateResource } = props;
  let gradingScalesAttributes = org.grading_scales_attributes || [];

  const [gradingScales, setGradingScales] = useState(gradingScalesAttributes);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentGradingScale, setCurrentGradingScale] = useState({});

  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector())
  
  const createDefaultGradingScale = () => {
    return {
      uuid: getUniqueId(10),
      title: "",
      archived: false,
      org_id: org.id,
      levels_json: [{
        uuid: `level_${getUniqueId(10)}`,
        color: "",
        label: "",
        percentage: 0
      }],
    };
  };

  const addGradingScale = () => {
    setIsModalVisible(true);
    setCurrentGradingScale(createDefaultGradingScale());
  };
  
  const saveGradingScaleToResource = (scaleToSave) => {
    updateResource({ id: org.id, grading_scales_attributes: [scaleToSave] }, {
      successCallback: (response) => {
        const org = response?.data?.org?.data?.attributes;
        const gradingScalesAttributes = org?.grading_scales_attributes;
        dispatch(loginActions.updateCurrentUserSuccess({
          currentUser: {
            ...currentUser,
            org: {
              ...currentUser.org,
              grading_scales_attributes: gradingScalesAttributes
            }
          }
        }))
        setGradingScales(gradingScalesAttributes);
      }
    });
  };

  const deleteGradingScale = (index) => {
    const updatedGradingScales = [...gradingScales];
    updatedGradingScales[index].archived = true;
    setGradingScales(updatedGradingScales);
    saveGradingScaleToResource(updatedGradingScales[index]);
  };

  const handleEditMode = (index) => {
    setCurrentGradingScale(gradingScales[index]);
    setIsModalVisible(true);
  };

  const saveSingleGradingScale = () => {
    const scaleToSave = {...currentGradingScale};

    if (!scaleToSave.title || scaleToSave.title.trim().length === 0) {
      message.error('Scale title is required');
      return;
    }
    const invalidLevel = scaleToSave.levels_json.find(level => !level?.label?.trim() || isNaN(level.percentage) || level.percentage === null);
    if (invalidLevel) {
      if (!invalidLevel.label || invalidLevel.label.trim().length === 0) {
        message.error('Level label is required');
      } else if (isNaN(invalidLevel.percentage) || invalidLevel.percentage === null) {
        message.error('Level percentage is required');
      }
      return;
    }
    saveGradingScaleToResource(scaleToSave);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="grading-scale-wrapper">
      <Space direction="vertical" className="grading-scale-list-wrapper">
        <Button key="add-grading-scale-button" onClick={() => addGradingScale()}>{mrIntl("OrgItemDetail.add_grading_scale")}</Button>
        <List
          dataSource={gradingScales}
          renderItem={(scale, i) => (
            <List.Item
              key={scale.uuid}
              actions={[
                <Button
                  icon={<EditOutlined />}
                  // style={{ marginRight: '10px' }}
                  onClick={() => handleEditMode(i, true)}
                  // disabled={editingState?.index === i}
                />,
                  <Popconfirm
                    title={mrIntl("UsersList.confirm")}
                    onConfirm={() => deleteGradingScale(i)}
                    okText={"Yes"}
                    cancelText={"No"}
                    disabled={gradingScales?.length === 1}
                  >
                    <Tooltip title={gradingScales?.length === 1 ? mrIntl("OrgItemDetail.minimum_required_limit") : null}>
                      <Button
                        icon={<DeleteOutlined />}
                        className="m-b-10"
                        disabled={gradingScales?.length === 1}
                      />
                    </Tooltip>
                  </Popconfirm>
              ]}
            >
              <List.Item.Meta
                title={scale.title}
              />
            </List.Item>
          )}
        />
      </Space>
      <Modal
        title={currentGradingScale !== null ? mrIntl("OrgItemDetail.edit_grading_scale") : mrIntl("OrgItemDetail.add_grading_scale")}
        width={800}
        visible={isModalVisible}
        onOk={() => saveSingleGradingScale()}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {mrIntl("CommonText.cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={() => saveSingleGradingScale()}>
            {mrIntl("OrgItemDetail.save")}
          </Button>,
        ]}
      >
        <ModalContent currentGradingScale={currentGradingScale} setCurrentGradingScale={setCurrentGradingScale} />
      </Modal>
    </div>
  );
};

const StandardSets = (props) => {
  const { updateResource } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const currentUser = useSelector(currentUserSelector());
  const org = currentUser?.org;
  const initialStandardCollectionIds = org?.custom_fields?.enabled_standard_collection_ids || [];
  const [standardCollectionsItems, setStandardCollectionsItems] = useState();
  const [standardCollectionsSelectedItems, setStandardCollectionsSelectedItems] = useState(initialStandardCollectionIds);
  
  const { custom_fields: customFields, org_programmes: orgProgrammes } = org;

  useEffect(() => {
    const fetchParams = {
      by_type_c: "standard_collection",
      should_serialize: false
    };

    const handleFetchSuccess = (response) => {
      const tags = response.data?.tags;
      const filteredStandardCollections = tags.filter(tag => tag.name !== "CAIE: Cambridge International Education -  IGCSE");
      setStandardCollectionsItems(filteredStandardCollections);
    };

    dispatch(tagActions.fetch({
      params: fetchParams
    }, {
      successCallback: handleFetchSuccess,
    }));
  }, [dispatch]);

  const handleCheckboxChange = (itemId, checked) => {
    setStandardCollectionsSelectedItems(prevSelectedItems => {
      if (checked) {
        return [...prevSelectedItems, itemId];
      }
      return prevSelectedItems.filter(id => id !== itemId);
    });
  };

  const handleSubmit = () => {
    const updatedCustomFields = {
      ...customFields,
      enabled_standard_collection_ids: standardCollectionsSelectedItems
    };
    
    updateResource({
      id: org.id,
      custom_fields: updatedCustomFields
    }, {
      successCallback: (response) => {
        const updatedOrg = response?.data?.org?.data?.attributes;
        const updatedStandardCollections = updatedOrg?.enabled_standard_collections;
        const newCustomFields = updatedOrg?.custom_fields;
        
        dispatch(loginActions.updateCurrentUserSuccess({
          currentUser: {
            ...currentUser,
            org: {
              ...currentUser.org,
              enabled_standard_collections: updatedStandardCollections,
              custom_fields: newCustomFields
            }
          }
        }));
      },
      success: {
        showMessage: true,
        message: mrIntl("OrgItemDetail.standard_sets_updated_successfully")
      }
    });
  };

  const renderStandardCollections = () => {
    const hasProgrammeId = (item) => {
      return orgProgrammes?.some(programme => 
        programme.programme_id === item.programme_id && programme.archived === false
      );
    };

    const isSelected = (itemId) => {
      return standardCollectionsSelectedItems?.includes(itemId);
    };

    const isItemChecked = (item) => {
      return isSelected(item.id) || hasProgrammeId(item);
    };

    const isItemDisabled = (item) => {
      return hasProgrammeId(item);
    };

    const renderCheckbox = (item) => (
      <Checkbox
        defaultChecked={isSelected(item.id)}
        checked={isItemChecked(item)}
        disabled={isItemDisabled(item)}
        onChange={e => handleCheckboxChange(item.id, e.target.checked)}
      >
        {item.name}
      </Checkbox>
    );

    return (
      <List
        dataSource={standardCollectionsItems}
        renderItem={item => (
          <List.Item>
            {renderCheckbox(item)}
          </List.Item>
        )}
      />
    );
  };

  return (
    <div>
      {renderStandardCollections()}
      <Button type="primary" onClick={() => handleSubmit()}>
        {mrIntl("CommonText.submit")}
      </Button>
    </div>
  );
};

const OrgItemDetail = (props) => {
  console.log("itemDetail props", props);
  const {item, history, currentUser, org, ...nestedProps} = props;
  const {path, url} = useRouteMatch();
  const mrIntl = useTranslate()
  const { TabPane } = Tabs;
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const appRegion = useSelector(appRegionSelector());
  const originalUserRole = getFromLS("originalUserRole")
  const orgProgrammeSwitcher =
    (enabledFeatures.org_programme_switch ||
      import.meta.env.VITE_MODE !== "production") &&
    !currentUser.org.is_mb && !currentUser.org?.is_edlink;
  const [activeKey, setActiveKey] = useState(null)
  const isStandardSetsEnabled = enabledFeatures.enable_standards_content_area_tag_multiple;
  useEffect(() => {
    let pathNameArray = window.location.pathname.split("/")
    let programsIndex = pathNameArray.indexOf("programs") 
    let key = getActiveKeyFromPathname(window.location.pathname)
    if(key == "org"){
      // activeKey = "general"
      if(item.org_type === "individual"){
        key = "enable-py"
      }else{
        key = "roles"
      }
    } else if (key === "roles"){
      key = "roles"
    } else if (key === "enable-py"){
      key = "enable-py"
    } else if (key === "audit-logs"){
      key = "audit-logs"
    } else if (key === "ai-usage") {
      key = "ai-usage"
    } else if (key === "standards-based-grading-scales") {
      key = "standards-based-grading-scales"
    } else if (key === "standard-sets") {
      key = "standard-sets"
    } else {
      key = "general"
    }
  
    if (programsIndex > -1) {
      key = pathNameArray[programsIndex]
    }

    setActiveKey(key)
  }, [])

  const handleTabClick = (key, event) => {
    console.log('inside orgitemdetail==>', key, url);
    history.push(`${url}/${key}`)
    setActiveKey(key)
  }


  return (
    <React.Fragment>
      {/* <span>{item.section_segment_attributes.title} - {item.segment_type}</span> */}
      {/* <span>Org show - {JSON.stringify(item)}</span> */}
      <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
        {currentUser.role == "superadmin" && (
          <TabPane tab= {mrIntl("OrgItemDetail.general_tab")} key="general">
            {/* <h2>General</h2> */}
            <Card>
              <img
                src={item.attachments_json.logo}
                style={{ "max-height": "70px", marginBottom: "20px" }}
                alt="School logo"
              />
              <div>
                {props.renderForm({
                  name: "orgs-general",
                  config: { isModal: false, visible: true },
                  resource: item, //use this for edit
                })}
              </div>
            </Card>
          </TabPane>
        )}
        {currentUser.role == "superadmin" && (
          <TabPane tab={mrIntl("OrgItemDetail.bulk_import_tab")} key="bulk-import">
            {/* <h2>General</h2> */}
            <Card>
              <div>
                {props.renderForm({
                  name: "orgs-bulk-import",
                  config: { isModal: false, visible: true },
                  resource: item, //use this for edit
                })}
                <Row>
                  <Col span={4}>
                    <BulkImportUsers singleResourceName="users" org={org} isMenuItem={false} />
                  </Col>
                  <Col span={20}>
                    <BulkImportGroups singleResourceName="groups" org={org} isMenuItem={false} />
                  </Col>
                </Row>
              </div>
            </Card>
          </TabPane>
        )}
        {currentUser.role == "superadmin" && item.is_one_roster && (
          <TabPane tab={"Oneroster Import"} key="oneroster-import">
            <Card>
              <OneRoster orgId={org.id} orgUUID={org.uuid}/>
            </Card>
          </TabPane>
        )}
        {orgProgrammeSwitcher && (
          <TabPane
          //  tab="Enable programs"
          tab= {mrIntl("OrgItemDetail.enable_programs")}
           key="enable-py">
            <Card>
              <div>
                {props.renderForm({
                  name: "orgs-programs-years",
                  config: { isModal: false, visible: true },
                  resource: item, //use this for edit
                })}
              </div>
            </Card>
          </TabPane>
        )}
        {/* { currentUser.permission.manage_permissions.list && */}
        <TabPane tab={mrIntl("OrgItemDetail.permissions")} key="roles">
          <Card>
            {/* TEMP  until org-roles form properly done */}
            {/* IMP: hardcoded for teacher permissions right now */}
            <b>General</b>
            <OldPermissionsUI org={item} updateResource={props.updateResource} />
            {((currentUser.role === "superadmin") ||
              (currentUser.role === "admin" && currentUser.permission &&
                currentUser.permission.manage_permissions &&
                currentUser.permission.manage_permissions.list)) &&
              <OrgPermissions org={item} updateResource={props.updateResource} />
            }
            {/* <div>
                {props.renderForm({
                  name: "org-roles",
                  config: { isModal: false, visible: true },
                  resource: item //use this for edit
                })}
              </div> */}
          </Card>
        </TabPane>
        {((currentUser.role === "superadmin" || 
          originalUserRole === "superadmin" || (import.meta.env.VITE_MODE != "production" && import.meta.env.VITE_MODE != "china_production"))) && (
          <TabPane
          tab= {mrIntl("OrgItemDetail.audit_logs")}
           key="audit-logs">
            <OrgAuditLogs org={org}/>
          </TabPane>
        )}
        {(currentUser.role === "superadmin" || (currentUser.role === "admin" && (enabledFeatures.ai_generate_questions_with_prompt || import.meta.env.VITE_MODE === "development")) && (appRegion !== "china")) && <TabPane tab={mrIntl("OrgItemDetail.ai_assistant_usage")} key="ai-usage">
          <Card>
           <AIUsage orgId={item.id} aiCreditsUSD={item.custom_fields.ai_credits_usd} />
          </Card>
        </TabPane>}
        {currentUser.role == "superadmin" && (
          <TabPane tab="Org Programmes" key="programs">
            <Route
              path={`${path}/programs`}
              render={(routeProps) => (
                <OrgProgrammes
                  params={{ by_org_id: org.id }}
                  createUpdateParams={{ org_id: org.id }}
                  isRoute={false}
                  {...routeProps}
                  config={{
                    routes: { fetch: true },
                    itemDetail: { widget: OrgProgrammeList },
                  }}
                />
              )}
            />
          </TabPane>
        )}
        {(currentUser.role === "superadmin" || (currentUser.role === "admin" && enabledFeatures.integration_with_edlink)) && (
          <TabPane tab={"Integrations"} key="integrations">
            <Card>
              <OrgIntegration orgId={org.id} org={org} />
            </Card>
          </TabPane>
        )}
        {/* <TabPane tab="Features" key="features">
          <h2>Features</h2>
        </TabPane>
        <TabPane tab="Integrations" key="integrations">
          <h2>Integrations</h2>
        </TabPane> */}
        {(enabledFeatures.set_grading_scales_for_sbg || import.meta.env.VITE_MODE == "development") && currentUser.role === "admin" && (
          <TabPane tab={"Standards based grading scales"} key="standards-based-grading-scales">
            <Card>
              <StandardsBasedGradingScales org={org} updateResource={props.updateResource} />
            </Card>
          </TabPane>
        )}
        {isStandardSetsEnabled && (
          <TabPane
            tab={mrIntl("OrgItemDetail.standard_sets")}
            key="standard-sets">
            <Card>
              <StandardSets org={org} updateResource={props.updateResource} />
            </Card>
          </TabPane>
        )}
      </Tabs>
    </React.Fragment>
  );
}


OrgItemDetail.defaultProps = {};

OrgItemDetail.propTypes = {};

export default OrgItemDetail;
