import React, { useEffect, Suspense, lazy, useMemo, useRef } from "react";
import {
  List,
  Card,
  Col,
  Space,
  Button,
  Tooltip,
  Skeleton,
  Select,
  Typography,
  Row,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { Route, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, experienceLoadingSelector } from "../selector";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { isEmpty } from "lodash";
import { topicsSelector } from "../../Segments/Topics/selector";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import DuplicateExperienceButton from "../ExperienceShow/Components/DuplicateExperienceButton";
import "./ExperienceItemPreview.scss";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import useIntersectionObserver from "./useIntersectionObserver";
const LazySegments = lazy(() => import("../../Segments/Segments"));

const ExperienceItemPreview = (props) => {
  const { isLibrary } = props;
  const { path } = useRouteMatch();
  const experience = useSelector(
    activeAdjustedExperienceSelector(isLibrary)
  );
  const topics = useSelector(topicsSelector());
  const experienceLoading = useSelector(experienceLoadingSelector());
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const { Title } = Typography;
  const defaultTopicId = topics[0]?.id;
  const scrollTimeoutRef = useRef(null);
  const experienceName = experience.name;

  const topicsAndSegments = useMemo(() => {
    const renderTopicsAndSegments = [];
    // Pushing data into render items which is our topics and segments, which later will be pushed to topicsAndSegments
    topics.forEach((topic, i) => {
      const topicId = topic.id;

      // Add topic title
      renderTopicsAndSegments.push(
        <List.Item
          className={
            i === 0
              ? "segment-list-item unstyled-list-item first-topic-title"
              : "segment-list-item unstyled-list-item topic-title"
          }
          key={`topic-title-${topicId}`}
        >
          <h3>
            <b>
              <RenderHtml
                text={topic.section_segment_attributes.title}
                truncate
              />
            </b>
          </h3>
        </List.Item>
      );

      // Add segments component
      const segmentsComponent = (
        <Route
          path={`${path}`}
          render={(routeProps) => (
            <LazySegments
              doNotReloadOnUpdate={true}
              experienceViewMode="apEditTest"
              showExperiencePreview={true}
              experienceProps={{ item: experience }}
              embedded={false}
              mobileUploadMode={false}
              rearrangeMode={false}
              readingModeEnabled={false}
              printMode={false}
              topicProps={null}
              parentId={topicId}
              parentIdxs={[i]}
              nestingLevel={1}
              gradingConfig={{}}
              params={{
                by_experience_id: experience.id,
                by_parent_id: topicId,
              }}
              createUpdateParams={{
                experience_id: experience.id,
                parent_id: topicId,
              }}
              libraryModalProps={{}}
              isRoute={false}
              {...routeProps}
              config={{
                list: {
                  shouldExecuteRequest: false,
                },
              }}
            />
          )}
        />
      );

      renderTopicsAndSegments.push(
        <div className="segments-list" key={`segments-${topicId}`}>
          {segmentsComponent}
        </div>
      );
    });

    return renderTopicsAndSegments;
  }, [topics, path, experience]);

  // Using custom hook for Intersection Observer API
  const { visibleCount, setVisibleCount, loadingDataThroughObserver } =
    useIntersectionObserver(topicsAndSegments);

  // Close preview when experience object is empty (occurs when changing pages, as the current experience is removed from Redux)
  useEffect(() => {
    if (isEmpty(experience)) {
      closePreview();
    }
  }, [experience]);

  // Closing preview on unmount
  useEffect(() => {
    return () => {
      dispatch(experienceReduxActions.setShowPreviewSuccess({ value: false }));
      clearTimeout(scrollTimeoutRef.current);
    };
  }, [dispatch]);

  const duplicateBtn = (
    <DuplicateExperienceButton
      item={experience}
      sourceId={experience.uid}
      isLibrary={true}
      withText={true}
      buttonShape="square"
    />
  );

  const closePreview = () => {
    dispatch(experienceReduxActions.setShowPreviewSuccess({ value: false }));
    dispatch(experienceReduxActions.setActiveSuccess({ id: null }));
  };

  const scrollToSection = (topicId) => {
    const sectionElement = document.getElementById(`topic-${topicId}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleSelectChange = (value) => {
    const selectedTopicIndex = topics.findIndex(
      (topic) => topic.id === parseInt(value, 10)
    );
    if (selectedTopicIndex !== -1) {
      // Calculate the number of elements in topicsAndSegments for the selected topic
      const updatedVisibleCount = (selectedTopicIndex + 1) * 2;
      const isScrollable = visibleCount >= topicsAndSegments.length;

      // Only update visibleCount if the new count is greater
      if (updatedVisibleCount > visibleCount) {
        setVisibleCount(updatedVisibleCount); // Ensure the section is visible
      }

      // Scroll immediately if data is loaded, otherwise wait for loading to complete
      if (isScrollable) {
        scrollToSection(value);
      } else {
        scrollTimeoutRef.current = setTimeout(
          () => scrollToSection(value),
          1500
        );
      }
    }
  };

  return (
    <Card
      className="experience-item-preview-card"
      title={
        <Row
          className="experience-item-preview-card-header"
          gutter={16}
        >
          <Col span={8}>
            <Title level={4} className="card-header-title">
              {experienceName}
            </Title>
          </Col>
          <Col span={8} className="card-header-topic-select-container">
            {/* Ensure there is at least one topic available before rendering the Select component */}
            {defaultTopicId && (
              <Select
                onChange={handleSelectChange}
                className="card-header-topic-select"
                defaultValue={defaultTopicId}
              >
                {topics.map((topic) => {
                  const topicTitle = topic.section_segment_attributes?.title;
                  return (
                    <Select.Option
                      key={`${topicTitle}-${topic.id}`}
                      value={topic.id}
                    >
                      <RenderHtml text={topicTitle} />
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Col>
          <Col span={8} className="card-header-action-buttons">
            <Space>
              {duplicateBtn}
              <Tooltip
                title={mrIntl("ExperienceItemPreview.close_preview")}
                placement="top"
              >
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => closePreview()}
                  shape="circle"
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
      }
    >
      <Col span={24}>
        {experienceLoading ? (
          <Skeleton paragraph={{ rows: 20 }} />
        ) : (
          <>
            {topicsAndSegments.slice(0, visibleCount).map((item, index) => {
              const isLastVisible = index === visibleCount - 1;
              const keyParts = item.key ? item.key.split("-") : [];
              const topicId = keyParts.length > 2 ? keyParts[2] : null;

              return (
                <Suspense fallback={<Skeleton active />}>
                  <div
                    className={isLastVisible ? "last-visible" : ""}
                    key={`topic-${topicId}`}
                    id={topicId ? `topic-${topicId}` : undefined}
                  >
                    {item}
                  </div>
                </Suspense>
              );
            })}
            {loadingDataThroughObserver && <Skeleton />}
          </>
        )}
      </Col>
    </Card>
  );
};

export default ExperienceItemPreview;
