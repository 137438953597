import React from "react";
import MagicButton from "../../Segments/GenerateAI/MagicButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { message } from "/src/components/UI/AntdAppHelper";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { LoadingOutlined } from "@ant-design/icons";
import { isGradingWithAIInProgress } from "/src/lib/utils/helperMethods";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { isGradingWithAIInProgressSelector } from "/src/views/ExperienceUsers/selector";

const GradeSubmission = ({ item }) => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  
  const activeExperience = useSelector(activeAdjustedExperienceSelector())  

  const isGradeAllWithAIInProgress = useSelector(isGradingWithAIInProgressSelector());
  const isGradingWithAIInProgressForEU = isGradingWithAIInProgress(item);
  
  let isGradingButtonDisabled = false;
  let gradingButtonTooltipTitle = "";

  if (!isGradingWithAIInProgressForEU) {
    // Keep the stop button active when an assessment is open in two tabs:
    // If one tab publishes a submission and the other starts grading and refreshes,
    // the '!isGradingWithAIInProgressForEU' condition ensures the stop button remains enabled during grading.
    if (activeExperience.status === "closed") {
      isGradingButtonDisabled = true;
      gradingButtonTooltipTitle = mrIntl(
        "CommonText.grading_is_disabled_as_the_assessment_is_currently_closed"
      );
    } else if (item.marked) {
      isGradingButtonDisabled = true;
      gradingButtonTooltipTitle = mrIntl(
        "GradeSubmission.grading_is_disabled_for_published_submissions"
      );
    } else if (isGradeAllWithAIInProgress) {
      isGradingButtonDisabled = true;
      gradingButtonTooltipTitle = mrIntl("GradeSubmission.not_allowed_while_grading_all_submissions")
    }
  }  
  
  const handleGradeSubmission = () => {
    const gradingOptions = {
      eu_id: item.id,
    };

    dispatch(
      experienceUserActions.gradeSubmissionsWithAi(gradingOptions, {
        successCallback: (data) => {
          console.log("gradeSubmisison data ===>", data);
          const finalExperienceUser = formatJsonApiData(
            data.experience_user
          );
          const updatedExperienceUser = {
            ...item,
            ...finalExperienceUser,
          };
          dispatch(
            experienceUserActions.showSuccess(
              { data: { experience_user: updatedExperienceUser } },
              { success: { showMessage: false } }
            )
          );
        },
        errorCallback: (error) => {
          message.error(
            mrIntl("CommonText.something_went_wrong_please_try_again")
          );
          console.log("gradeAllWithAI errorCallback", error);
        },
      })
    );
  };

  const cancelGradeSubmission = () => {
    const url = `${import.meta.env.VITE_API_URL}cancel_grade_submission_with_ai`;
    const params = {
      eu_id: item.id,
    };
    axiosInstance.instance.post(url, params)
      .then(response => {
        dispatch(
          experienceUserActions.fetch(
            {
              params: {
                by_ids: [item.id],
                with_all_eus_user_responses: true,
              },
            },
            {
              mergeFetchedData: true,
            }
          )
        );
      })
      .catch(error => {
        console.error('Failed to cancel grading submission:', error);
        message.error(mrIntl("CommonText.something_went_wrong_please_try_again"));
      });
  };

  return (
    <MagicButton
      disabled={isGradingButtonDisabled}
      onClick={isGradingWithAIInProgressForEU ? cancelGradeSubmission : handleGradeSubmission}
      shape="default"
      type="text"
      text={isGradingWithAIInProgressForEU ? "Stop grading" : "Grade Submission"}
      mode="subtle"
      size="small"
      icon={isGradingWithAIInProgressForEU ? <LoadingOutlined /> : null}
      feature_code="grade_submission_with_ai"
      ghost={true}
      tooltipTitle={gradingButtonTooltipTitle}
    />
  );
};

export default GradeSubmission;
