import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getFromLS,
} from "/src/lib/utils/helperMethods";
import {
  appTypeSelector,
} from "./offlineAppSelectors";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { message } from "/src/components/UI/AntdAppHelper";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import QuitBtn from "/src/components/UI/QuitBtn";
import { checkNativeMethodSupported, macAppsToClose, supportsMacAACSecurity } from "./offlineAppHelper";
import BackToHomeBtn from "/src/components/UI/BackToHomeBtn";


export const checkAndChangeSecurity = (dispatch, options) => {
  const { changeSecurityTo = true, whitelistUrls = [], appType, onSuccess = () => {}, onError = () => {}, mrIntl } = options;
  if (changeSecurityTo) {
    if (supportsMacAACSecurity(appType)) {
      if (checkNativeMethodSupported(appType, "getSecurityStatus")) {
        // For Mac App 10.2.0 and above with AAC supported
        // For iOS 3.2.0 and above with AAC supported
        dispatch(
          offlineAppActions.executeMethod([{
            key: "getSecurityStatus",
            value: "status",
            successCallback: (response) => {
              if (response === "true") {
                // If security is already on, we don't need to turn it on again
                dispatch(
                  offlineAppActions.executeMethod(
                    [
                      { 
                        key: "setWhitelistedUrls", 
                        value: whitelistUrls,
                        errorCallback: () => {
                          console.error("Error in setting whitelist urls", whitelistUrls);
                          message.error(mrIntl("CheckAndChangeSecurity.whitelist_urls_could_not_be_set"));
                        } 
                      },
                    ]
                  )
                );
                onSuccess();
              } else {
                dispatch(
                  offlineAppActions.executeMethod(
                    [
                      { 
                        key: "changeSecurity", 
                        value: true,
                        successCallback: (value) => {
                          if(value === "true"){
                            onSuccess();
                          } else {
                            onError()
                          }
                        },
                        errorCallback: () => {
                          onError()
                        } 
                      },
                      { 
                        key: "setWhitelistedUrls", 
                        value: whitelistUrls,
                        errorCallback: () => {
                          // NOTE: @Uttam Handle here if security is turned on but whitelist urls are not set
                        } 
                      },
                    ], 
                    // {
                    //   successCallback: onSuccess,
                    //   errorCallback: onError,
                    // }
                  )
                );
              }
            }
          }],
          {
            // successCallback: onSuccess, TODO: DO NOT USE successCallback here, as we need to check the security status first
            errorCallback: onError,
          })
        );
      } else {
        // For Mac App 10.1.0 and below with AAC supported
        // ios with 2.0.5 and below
        dispatch(
          offlineAppActions.executeMethod(
            [
              { 
                key: "changeSecurity", 
                value: true,
                errorCallback: () => {
                  changeSecurityFailedAlert(true, mrIntl, appType, dispatch)
                } 
              },
              { key: "setWhitelistedUrls", value: whitelistUrls },
            ],
            {
              successCallback: onSuccess,
              errorCallback: onError,
            }
          )
        );
      }
    } else {
      // For Mac App 9.0.2 and below with AAC not supported
      dispatch(
        offlineAppActions.executeMethod(
          [
            { 
              key: "changeSecurity", 
              value: true,
              errorCallback: () => {
                changeSecurityFailedAlert(true, mrIntl, appType, dispatch)
              } 
            },
            { key: "clearClipboard", value: true },
            { key: "closeAllApps", value: macAppsToClose },
            { key: "setWhitelistedUrls", value: whitelistUrls },
          ],
          {
            successCallback: onSuccess,
            errorCallback: onError,
          }
        )
      );
    }
  } else {
    dispatch(
      offlineAppActions.executeMethod([{
        key: "changeSecurity",
        value: false,
        errorCallback: () => {
          changeSecurityFailedAlert(false, mrIntl, appType, dispatch)
        }
      }], 
      {
        successCallback: onSuccess,
        errorCallback: onError,
      })
    );
  }
}

export const changeSecurityFailedAlert = (value, mrIntl, appType, dispatch) => {
  // v10.2 and v11 - have a native bug that they give incorrect getSecurityStatus after security interrupted once - so trying to check and turn on security on refresh is failing - fix in v12 - @rajesh1 

  // @uttam When sec on fails on app launch with new join view - show native quit alert - in v10.2 and v11, currently not showing. check v12 also
  // To handle case after sec interruption - since this error won't allow students to proceed - save in LS once sec interrupted and after that try to turn on sec but DONt show this alert/error message to student on failing to turn on
  if (getFromLS("securityHasBeenInterrupted")) {
    return;
  }

  if (checkNativeMethodSupported(appType, "showQuitAlert")) {
    // mac app v10.2.0 , Native side we have showQuitAlert function to showing alert with info, Quit app option.
    // If security could not to be turned on , we have a option to app Quit only

    // NOTE: need to handle value = false case also - @uttam
    dispatch(
      offlineAppActions.executeMethod([
        {
          key: "showQuitAlert",
          value: "Security couldn't be turned on, and the test cannot be started. In case this issue continues, please restart your machine and try again."
        }
      ])
    );
  } else {
    showConfirmModal({
      title: value ? mrIntl("ExperienceTake.security_could_not_be_turned_on") : mrIntl("ExperienceTake.security_could_not_be_turned_off"),
      content: value ? mrIntl("ExperienceTake.change_security_failed_message_for_turning_on") : mrIntl("ExperienceTake.change_security_failed_message_for_turning_off"),
      okText: "Quit",
      closable: false,
      footer: [
        <QuitBtn />
      ],
    });
  }
}

export const changeSecurityFailedMessage = (mrIntl) => {
  message.error(
    mrIntl("CheckAndChangeSecurity.security_couldnt_be_turned_on_and_the_test_cannot_be"), 10
  );
}


export const CheckAndChangeSecurity = (props) => {
  const {
    setUserInfo,
  } = props;

  // appType == "mac" &&
  // experienceSettings.mode == "offline" &&
  // currentUser.role == "student"
  const appType = useSelector(appTypeSelector());
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const onError = () => {
    // TODO: @Uttam: get it back after resolving get firestore data issue inside getUserInfoFromBackup
    setUserInfo(
      {},
      {
        log: {
          // logging: experienceProps.log.logging,
          logging: true,
          log_level: "warning",
          // logging: true,
          action: "security_could_not_be_turned_on"
        },
      }
    );
    changeSecurityFailedAlert(true, mrIntl, appType, dispatch)
  }
  
  useEffect(() => {
    checkAndChangeSecurity(dispatch, {...props, appType: appType, mrIntl, onError})
  }, [appType]);

  return <></>;
};
