import React, { useEffect, useState } from "react";
import {MrSelect, MrTreeSelect} from "mr_react_framework";
import {useDispatch, useSelector} from "react-redux";
import {Select, DatePicker, Typography, Space, Radio, TreeSelect} from "antd";
import { buildCountriesOptionsArr, buildOptionsArr, getSubjectIconMap, getUTCDate, userRoleMap } from "/src/lib/utils/helperMethods";
import {actions as genericActions} from "/src/App/genericRedux";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";
import { programmegradesSelector, programmesubjectsSelector } from "/src/views/Programmes/selector";
import _, { isArray } from "lodash";
import { findStandardsByGrade, sortListByPosition, convertToNestedList } from "/src/lib/utils/helperMethods";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";

const { Option, OptGroup } = Select

export const SelectSubject = MrSelect({
  actions: genericActions,
  resourceName: "subjects",
  config: {
    url: "/subjects",
    isJsx: true,
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    params: {unarchived: true},
    processData: (data, props) => {
      const { config } = props
      const { widgetConfig = {} } = config
      console.log("data select groups subjects", data);
      console.log("props select groups====>", props);
      const mbPassport = widgetConfig.enabledFeatures && widgetConfig.enabledFeatures.mb_passport ? true : false
      let allSubjects = []
      let allSubjectGroups = []
      let finalRender
      const useIconBased = import.meta.env.VITE_MODE === "development" || (import.meta.env.VITE_MODE === "demo" && mbPassport) ? true : false;
      const subjectIconsMap = getSubjectIconMap()
      let limitToSubjectGroupName = widgetConfig.limitToSubjectGroupName
      let subjectsOfSameSubjectGroup = data.filter(item => item.code === limitToSubjectGroupName)
      let filteredData = limitToSubjectGroupName ? subjectsOfSameSubjectGroup : data
      filteredData.map((item) => {
        if(item.children.length != 0){
          let optGroupChildren = [] 
          let disabled = false
          if(props.restrictEditWithinSubjectGroup){
            let selectedSG = item.children.find(child => child.id === props.value)
            
            if(!selectedSG){
              disabled = true
            }
          }
          item.children.map((child) => {
            const subjectIconFileName = subjectIconsMap?.[child?.label?.toLowerCase()];
            optGroupChildren.push(<Option value={child.id} disabled={disabled} tooltip={disabled ? <MrTranslate id={"SelectSubject.criteria_changes_when_you_change_the_subject_group_msg"}/> : "" }>{useIconBased && subjectIconFileName != undefined && false ? <><img alt="subject-icon" src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconFileName}`}  style={{width: "20px", marginRight: "5px"}}/>{child.label}</> : child.label}</Option>)
            // TODO: removing icons because breaking search
          })
          let optGroup = <OptGroup label={item.label}>
            {optGroupChildren}
          </OptGroup>
          allSubjectGroups.push(optGroup)
          finalRender = allSubjectGroups
        }
      })
      return <>
        {finalRender}
      </>


      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   let label = item.parent_name ? `${item.name} - ${item.parent_name}` : item.name
      //   return {label: label, value: item.id};
      // });
    },
    // searchApi: true,
    widgetConfig: {
      showSearch: true,
      placeholder: <MrTranslate id={"CommonText.select_subject"}/>,
      allowClear: true,
      optionFilterProp: "label",
      filterOption: (input, option) => {
        const subjectName = option.children;
        const groupName = option.props.label;
        return (
          (groupName &&
            groupName.toString().toLowerCase().includes(input.toLowerCase())) ||
          (subjectName &&
            subjectName.toString().toLowerCase().includes(input.toLowerCase()))
        );
      },
    },
  },
});

// OrgProgramme
export const SelectProgram = MrSelect({
  actions: genericActions,
  resourceName: "orgprogrammes",
  apiResourceName: "org_programmes",
  config: {
    url: "/org_programmes",
    params: { should_serialize: false }, //default is org_id on BE
    processData: (data, props) => {
      const { disableMBIntegratedProgrammes } = props
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);

        let disabled = false
        if (disableMBIntegratedProgrammes && item.settings?.mb_integration_enabled !== false) {
          disabled = true
        }

        return {label: item.name, value: item.id, disabled: disabled};
      });
    },
    searchApi: false,
    widgetConfig: {
      className: "select-program",
      showSearch: true, 
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.select_program"}/>,
      // placeholder: "Select program",
      allowClear: true,
      dropdownMatchSelectWidth: false,
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});

export const SelectGrade = MrSelect({
  actions: genericActions,
  resourceName: "grades",
  config: {
    url: "/grades",
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      console.log("data select grade", data);
      console.log("props select grade", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.id};
      });
    },
    searchApi: false,
    // searchApi: true,
    widgetConfig: {
      // showSearch: false,
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.select_grade"}/>,
      // placeholder: "Select grade",
      allowClear: true
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});

// Programme
export const SelectProgramme = MrSelect({
  actions: genericActions,
  resourceName: "programmes",
  config: {
    url: "/programmes",
    params: { should_serialize: false },
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      // placeholder: "Select programme",
      placeholder: <MrTranslate id={"CommonText.select_programme_placeholder"}/>,
      // mode: "multiple",
      // style: { width: "500px" },
    },
  },
});

export const SelectProgrammeSubject = MrSelect({
  actions: genericActions,
  resourceName: "programmesubjects",
  apiResourceName: "programme_subjects",
  config: {
    url: "/programme_subjects",
    isJsx: true,
    params: {unarchived: true},
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      const { config } = props
      const { widgetConfig = {} } = config
      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   return {label: item.name, value: item.name};
      // });
      let allSubjects = []
      let allSubjectGroups = []
      let finalRender
      let showQPSResourceCounts = widgetConfig.showQPSResourceCounts
      let limitToSubjectGroupName = widgetConfig.limitToSubjectGroupName
      let onlyShowSubjectsWithPublishedAssessments = widgetConfig.onlyShowSubjectsWithPublishedAssessments
      let subjectsOfSameSubjectGroup = data.filter(item => item.code === limitToSubjectGroupName)
      console.log("subjectsOfSameSubjectGroup ====>", subjectsOfSameSubjectGroup, limitToSubjectGroupName, data);
      let filteredData = limitToSubjectGroupName ? subjectsOfSameSubjectGroup : data
      filteredData.map((item) => {
        if(item && item.children_attributes && item.children_attributes.length != 0){
          let optGroupChildren = [] 
          let disabled = false
          if(props.restrictEditWithinSubjectGroup){
            let selectedSG = item.children_attributes.find(child => child.id === props.value)
            if(!selectedSG){
              disabled = true
            }
          }
          item.children_attributes.map((child) => {
            let experiencesCount = !_.isEmpty(child.qps_resource_counts) && child.qps_resource_counts.experiences.total_published
            let showSubject = ((onlyShowSubjectsWithPublishedAssessments && experiencesCount > 0) || !onlyShowSubjectsWithPublishedAssessments)
            if(showSubject){
              let label = child.label
              if(showQPSResourceCounts && experiencesCount){
                label = label.concat(` (${experiencesCount})`)
              }
              // keep label as 1 string, if we add space or 2 elements, in search below, children comes as array and gives an error - see APL-3089
              optGroupChildren.push(<Option value={child.id} disabled={disabled}>{label}</Option>)
            }
          })
          let optGroup = <OptGroup label={item.label}>
            {optGroupChildren}
          </OptGroup>
          if(optGroupChildren.length > 0){
            allSubjectGroups.push(optGroup)
          }
          finalRender = allSubjectGroups
        }
        else{
          allSubjects.push(<Option value={item.id}>{item.label}</Option>)
          let optGroup = <OptGroup label="All subjects">
            {allSubjects}
          </OptGroup>
          finalRender = optGroup
        }
      })
      return <>
        {finalRender}
      </>
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      // filterOption: true,
      filterOption: (input, option) => {
        // console.log("SelectSubject", input, option);
        // // return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // let found = false
        // if(option.options){
        //   found = option.options.filter(o => o.children && o.children.toLowerCase().indexOf(input.toLowerCase()) >= 0).length === 0 ? false : true
        // }
        // return found

        const subjectName = option.children;
        const groupName = option.props.label;
        return (
          (groupName &&
            groupName.toString().toLowerCase().includes(input.toLowerCase())) ||
          (subjectName &&
            subjectName.toString().toLowerCase().includes(input.toLowerCase()))
        );

      },
      // placeholder: "Select subject",
      placeholder: <MrTranslate id={"CommonText.select_subject"}/>,

      allowClear: true
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});

export const SelectSubjectGroup = MrSelect({
  actions: genericActions,
  // resourceName: "subjects",
  resourceName: "programmesubjects",
  apiResourceName: "programme_subjects",
  config: {
    url: "/programme_subjects",
    params: { by_type_c: "group" },
    processData: (data, props) => {
      console.log("programme_subjects=====>", data)
      return data.map((item) => {
        // add to programme_label
        return { label: item.programme_name+" - "+item.label , value: item.id };
      });
    },
    // searchApi: true,
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"Rubrics.select_subject_group_placeholder"}/>,
      allowClear: true
    },
  },
});

export const SelectProgrammeGrade = MrSelect({
  actions: genericActions,
  resourceName: "programmegrades",
  apiResourceName: "programme_grades",
  config: {
    url: "/programme_grades",
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      console.log("data select grade", data);
      console.log("props select grade", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.name, value: item.id, grade_number: item.grade_number};
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      // placeholder: "Select grade",
      placeholder: <MrTranslate id= {"CommonText.select_grade"} />,
      allowClear: true
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});


// move to helper methods?
export const buildTreeData = (
  data,
  fieldsMapping = { title: "title", value: "id", children: "children" },
  options
) => {
  console.log("buildTreeData====>", options);

  let treeData = [];
  const { maxNestingLevel = 4, currentLevel = 1, titleContainsHtml = false, rootParentKey = "" } =
    options;

  let maxLevelReached = false; // Initialize maxLevelReached outside the if block

  // sample data
  treeData = [
    {
      title: '1',
      value: '0-0',
      children: [
        {
          title: '1.1',
          value: '0-0-1',
          children: [
            {
              title: '1.1.1',
              value: 'av',
            },
            {
              title: '1.1.2',
              value: 'ab',
            },
          ],
        },
        {
          title: '1.2',
          value: '0-0-222',
          children: [
            {
              title: '1.2.1',
              value: 'aaa',
            },
            {
              title: '1.2.2',
              value: 'sds',
            },
          ],
        },
      ],
    },
    {
      title: '2',
      value: '0-1',
      children: [
        {
          title: '2.1',
          value: 'x',
        },
        {
          title: '2.2',
          value: 'z',
        },
      ],
    },
  ];
  
  // Check if max level reached
  if (maxNestingLevel) {
    maxLevelReached = currentLevel >= maxNestingLevel;
  }

  // Build tree data
  treeData = data.map((item) => {
    let title = titleContainsHtml ? <RenderHtml text={item[fieldsMapping.title]} /> : item[fieldsMapping.title];
    let key = `${rootParentKey}-${item[fieldsMapping.value]}`
    return {
      title: title,
      searchTitle: item[fieldsMapping.title],
      value: item[fieldsMapping.value],
      key: key,
      checkable: maxLevelReached
        ? true
        : item[fieldsMapping.children] &&
          item[fieldsMapping.children].length > 0
            ? false
            : true,
      children: maxLevelReached
        ? []
        : item[fieldsMapping.children].length > 0
          ? buildTreeData(item[fieldsMapping.children], fieldsMapping, {
              maxNestingLevel: maxNestingLevel,
              currentLevel: currentLevel + 1,
              titleContainsHtml: titleContainsHtml,
              rootParentKey: rootParentKey,
            })
          : item[fieldsMapping.children],
    };
  });
  return treeData;
};

export const getTreeData = (data = {}, gradeLabelOverride = "", extraOptions = {}) => {
  const contentAreaTag = data.tag || {};
  const finalJson = contentAreaTag.final_json || {};
  const gradeLabel = gradeLabelOverride || data.grade_group_label || "";
  const maxNestingLevel = contentAreaTag.custom_fields 
    ? parseInt(contentAreaTag.custom_fields?.max_nesting_level_for_tagging) 
    : undefined;

  if (!finalJson.data) {
    return null;
  }

  const tags = finalJson.data;
  const filteredList = findStandardsByGrade(tags, gradeLabel, null, "grade_group");
  const nestedList = convertToNestedList(filteredList);
  const sortedNestedList = sortListByPosition(nestedList);

  const fieldsMapping = {
    title: "label",
    value: "id",
    children: "children"
  };
  const options = {
    maxNestingLevel: maxNestingLevel,
    currentLevel: 1,
    titleContainsHtml: true,
    rootParentKey: extraOptions.rootParentKey || "",
  };

  return buildTreeData(sortedNestedList, fieldsMapping, options);
};

export const SelectTreeSyllabusContents = MrTreeSelect({
  // actions: genericActions,
  // resourceName: "tags",
  // apiResourceName: "tags",
  config: {
    // url: "/tags",
    // // isJsx: true,
    // params: {by_type_c: "topic"},
    // // searchApi: true,
    processData: (data, props) => {
      console.log("data SelectSyllabusContents", data);
      console.log("props SelectSyllabusContents", props);
      const { config = {} } = props;
      const { widgetConfig = {} } = config;
      const { enableStandardsContentAreaTagMultiple = false } = widgetConfig;
      let allTreeData = [];
      
      if (enableStandardsContentAreaTagMultiple && data.length > 1) {
        data.forEach(dataItem => {
          let treeData = getTreeData(dataItem);
          const dataItemTag = dataItem?.tag || {};
          const tagLabel = dataItemTag.label || "";
          const tagGradeGroupLabel = dataItem.grade_group_label || "";
          const title = `${tagLabel} ${tagGradeGroupLabel && `- ${tagGradeGroupLabel}`}`
          if (treeData) {
            let firstLevelTreeData = {
              title: title,
              value: dataItemTag.id,
              key: `tag-item-${dataItemTag.id}`,
              children: treeData,
              checkable: false
            };
            allTreeData.push(firstLevelTreeData);
          }
        });
        return allTreeData;
      } else {
        const treeData = isArray(data) && data.length > 0 
          ? getTreeData(data[0])
          : getTreeData(data);
        return treeData || [];
      }
    },  
    isJsx: false,
    widgetConfig: {
      // read docs for this from AntDesign TreeSelect
      // placeholder: "Select topics",
      placeholder: <MrTranslate id={"SelectSubject.select_topics"} />,
      treeCheckable: true,
      multiple: true,
      treeNodeFilterProp: "searchTitle",
      allowClear: true,
      className: "custom-select-tree",
      maxTagCount: 2,
      // showCheckedStrategy: TreeSelect.SHOW_PARENT,
    },

  }
});

const getTreeDataForSingle = (dataItem = {}) => {
  let contentAreaTag = dataItem.tag || {};
  let finalJson = contentAreaTag.final_json || {};
  let gradeLabel = dataItem.grade_group_label || "";

  if (!finalJson.data) {
    return null;
  }

  let tags = finalJson.data;
  let filteredList = findStandardsByGrade(tags, gradeLabel, null, "grade_group");
  let nestedList = convertToNestedList(filteredList);
  let sortedNestedList = sortListByPosition(nestedList);

  let fieldsMapping = {
    title: "label",
    value: "id",
    children: "children"
  };

  let options = {
    titleContainsHtml: true
  };

  const treeData = buildTreeData(sortedNestedList, fieldsMapping, options);
  return treeData
};

export const SelectTreeSyllabusContentsSingle = MrTreeSelect({
  // actions: genericActions,
  // resourceName: "tags",
  // apiResourceName: "tags",
  config: {
    // url: "/tags",
    // // isJsx: true,
    // params: {by_type_c: "topic"},
    // // searchApi: true,
    processData: (data, props) => {
      console.log("data SelectSyllabusContents", data);
      console.log("props SelectSyllabusContents", props);
      const { config = {} } = props;
      const { widgetConfig = {} } = config;
      const { enableStandardsContentAreaTagMultiple = false } = widgetConfig;
      let allTreeData = [];

      // Handle multiple or single data
      if (enableStandardsContentAreaTagMultiple && data.length > 1) {
        data.forEach(dataItem => {
          let treeData = getTreeDataForSingle(dataItem)
          const dataItemTag = dataItem?.tag || {};
          const tagLabel = dataItemTag.label || "";
          const tagGradeGroupLabel = dataItem.grade_group_label || ""
          const title = `${tagLabel} ${tagGradeGroupLabel && `- ${tagGradeGroupLabel}`}`
          if (treeData) {
            let firstLevelTreeData = {
              title: title,
              value: dataItemTag.id,
              key: `tag-item-${dataItemTag.id}`,
              children: treeData,
              selectable: false
            };
            allTreeData.push(firstLevelTreeData);
          }
        })
        return allTreeData;

      } else {
        const treeData = isArray(data) && data.length > 0 
          ? getTreeDataForSingle(data[0])
          : getTreeDataForSingle(data);
        return treeData || [];
      }
      // console.log("data SelectSyllabusContents", data);
      // console.log("props SelectSyllabusContents", props);

      // let fieldsMapping = {
      //   title: "label",
      //   value: "label",
      //   children: "children"
      // }
      // const treeData = buildTreeData(data, fieldsMapping)
      // return treeData;
    },
    isJsx: false,
    widgetConfig: {
      showSearch: true,
      // read docs for this from AntDesign TreeSelect
      // placeholder: "Select topics",
      placeholder: <MrTranslate id={"SelectSubject.select_topics"} />,
      treeCheckable: false,
      // treeDefaultExpandAll: true,
      // multiple: false,
      treeNodeFilterProp: "searchTitle",
      allowClear: true,
      className: "custom-select-tree",
      // treeCheckStrictly: true, // Only allow selecting one node at a time
      showCheckedStrategy: TreeSelect.SHOW_PARENT,
    },

  }
});

// export const SelectSyllabusContents = MrSelect({
//   actions: genericActions,
//   resourceName: "syllabuscontents",
//   apiResourceName: "syllabus_contents",
//   config: {
//     url: "/syllabus_contents",
//     isJsx: true,
//     // params: {by_org_programme_id: 8}, //get from top right dropdown
//     processData: (data, props) => {
//       console.log("data SelectSyllabusContents", data);
//       console.log("props SelectSyllabusContents", props);

//       let allChildren = []
//       let allSyllabusParents = []
//       let finalRender
//       data.map((item) => {
//         if(item.children.length != 0){
//           let optGroupChildren = [] 
//           item.children.map((child) => {
//             optGroupChildren.push(<Option value={child.id}>{child.label}</Option>)
//           })
//           let optGroup = <OptGroup label={item.label}>
//             {optGroupChildren}
//           </OptGroup>
//           allSyllabusParents.push(optGroup)
//           finalRender = allSyllabusParents
//         }
//         else{
//           allChildren.push(<Option value={item.id}>{item.label}</Option>)
//           let optGroup = <OptGroup label="All topics">
//             {allChildren}
//           </OptGroup>
//           finalRender = optGroup
//         }
//       })

//       return <>
//         {finalRender}
//       </>

//     },
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false,
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Select topics",
//       allowClear: true,
//       mode: "multiple",
//       // style: {width: "400px"},
//     },
//   }
// });

export const SelectRubric = MrSelect({
  actions: genericActions,
  resourceName: "rubrics",
  config: {
    url: "/rubrics",
    // url: "/rubrics_combined",
    // params: { by_type_c: "group" },
    processData: (data, props) => {
      console.log("rubrics=====>", data)
      return data.map((item) => {
        // add to programme_label
        return { label: item.title , value: item.id };
      });
    },
    // searchApi: true,
    // searchApi: false,
    // clearValueOnParamsChange: false,
    widgetConfig: {
      // showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select rubric",
      placeholder: <MrTranslate id={"SelectSubject.select_rubric"}/>,
      allowClear: false,
      style: { width: "300px" },
    },
  },
});

export const SelectFeatures = MrSelect({
  actions: genericActions,
  resourceName: "features",
  config: {
    url: "/features",
    // url: "/features_combined",
    // params: { by_type_c: "group" },
    processData: (data, props) => {
      console.log("features", data)
      return data.map((item) => {
        // add to programme_label
        return { label: item.name , value: item.id };
      });
    },
    // searchApi: true,
    // searchApi: false,
    // clearValueOnParamsChange: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select feature",
      placeholder: <MrTranslate id={"SelectSubject.select_feature"}/>,
      allowClear: false,
      style: { width: "300px" },
    },
  },
});

export const SelectFeaturesCode = MrSelect({
  actions: genericActions,
  resourceName: "features",
  config: {
    url: "/features",
    // url: "/features_combined",
    // params: { by_type_c: "group" },
    processData: (data, props) => {
      console.log("features ==>", data)
      const { config = {} } = props;
      const { widgetConfig = {} } = config;
      const hiddenFeatureIds = widgetConfig.hiddenFeatureIds;
      let newData = data;
      if (hiddenFeatureIds) {
        newData = data.filter(item => !hiddenFeatureIds?.includes(parseInt(item.id)))
      }
      return newData.map((item) => {
        // add to programme_label
        // return { label: item.name , value: item.code };
        return { label: item.name , value: parseInt(item.id) };
      });
    },
    // searchApi: true,
    // searchApi: false,
    // clearValueOnParamsChange: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select feature",
      placeholder: <MrTranslate id={"SelectSubject.select_feature"}/>,
      allowClear: false,
      style: { width: "300px" },
    },
  },
});

export const SelectFeedbackType = MrSelect({
  config: {
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: <MrTranslate id={"SelectSubject.feature_request"}/>, value: "feature_request" },
        { label: <MrTranslate id={"SelectSubject.report_an_issue"}/>, value: "reported_issue" },
        { label: <MrTranslate id={"SelectSubject.other_feedback"}/>, value: "other_feedback" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Feedback type",
      placeholder: <MrTranslate id={"CommonText.feedback_type"}/>,
      allowClear: true,
    },
  },
});

export const SelectChild = MrSelect({
  // actions: genericActions,
  // resourceName: "orgprogrammes",
  // apiResourceName: "org_programmes",
  config: {
    // url: "/org_programmes",
    // params: {}, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.first_name + " " + item.last_name, value: item.id};
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false, 
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select child",
      placeholder: <MrTranslate id={"SelectSubject.select_child"}/>,
      // allowClear: true
      style: {width: "200px"},
      // options: [
      //   { first_name: "Shikhar", value: 1 },
      //   { first_name: "Abhi", value: 2 },
      //   { first_name: "Uttam", value: 3 },
      // ],
    },
  }
});

export const SelectParent = MrSelect({
  actions: genericActions,
  resourceName: "parents",
  apiResourceName: "users",
  config: {
    url: "/users",
    // params: {by_role: "student", by_org_programme_id: org_programme_id, by_grade_id: grade_id}, //default is org_id on BE
    params: {by_role: "parent", should_serialize: false}, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select parents", data);
      console.log("props select parents", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.first_name + " " + item.last_name, value: item.id};
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Search for parent",
      placeholder: <MrTranslate id={"SelectSubject.search_for_parent"} />
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});

export const SelectTag = MrSelect({
  actions: genericActions,
  resourceName: "tags",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    // params: {by_type_c: "difficulty_level"},
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // placeholder: "Select difficulty",
      allowClear: true,
      // mode: "multiple",
      // style: { width: "500px" },
    },
  },
});

export const SelectDepthOfKnowledge = MrSelect({
  actions: genericActions,
  resourceName: "depthofknowledge",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "depth_of_knowledge"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "Accessible", value: "accessible" },
      //   { label: "Moderate", value: "moderate" },
      //   { label: "Discriminating", value: "discriminating" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select DOK",
      placeholder: <MrTranslate id={"SelectSubject.select_dok"}/>,
      allowClear: true,
    },
  },
});

export const SelectDifficultyLevel = MrSelect({
  actions: genericActions,
  resourceName: "difficultylevels",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "difficulty_level"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "Accessible", value: "accessible" },
      //   { label: "Moderate", value: "moderate" },
      //   { label: "Discriminating", value: "discriminating" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select teaching level",
      placeholder: <MrTranslate id={"SelectSubject.select_teaching_level"}/>,
      allowClear: true,
    },
  },
});
export const SelectCommandTermLabel = MrSelect({
  actions: genericActions,
  resourceName: "commandterms",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "command_term"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.label };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "Accessible", value: "accessible" },
      //   { label: "Moderate", value: "moderate" },
      //   { label: "Discriminating", value: "discriminating" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select teaching level",
      placeholder: <MrTranslate id={"SelectSubject.select_command_term"}/>,
      allowClear: true,
    },
  },
});

export const SelectCommandTerm = MrSelect({
  actions: genericActions,
  resourceName: "commandterms",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "command_term"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      mode: "multiple",
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"SelectSubject.select_command_term"}/>,
      allowClear: true,
    },
  },
});

export const SelectAssessmentObjective = MrSelect({
  actions: genericActions,
  resourceName: "assessmentobjectives",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "assessment_objective"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      // filterOption: true,
      // optionFilterProp: "label",
      placeholder: <MrTranslate id={"SelectSubject.select_assessment_objective"}/>,
      allowClear: true,
    },
  },
});

export const SelectTeachingLevel = MrSelect({
  actions: genericActions,
  resourceName: "teachinglevels",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "teaching_level"},
    processData: (data, props) => {
      console.log("processData ===>", data, props);
      const { hideSlHl, hideSl } = (props.config && props.config.widgetConfig) || {};
      const finalData = []
      data.map((item) => {
        if(((hideSlHl && item.label !== "SL & HL") || (hideSl && item.label !== "SL only")) || !hideSlHl && !hideSl) {
          finalData.push({ label: item.label, value: item.id })
        } 
      });
      return finalData
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Teaching level",
      allowClear: true,
    },
  },
});

export const SelectGradeGroup1 = MrSelect({
  actions: genericActions,
  resourceName: "contentareagradegroups",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "grade_group"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Grade",
      allowClear: true,
      dropdownMatchSelectWidth: false,
    },
  },
});

export const SelectGradeGroup = MrSelect({
  // actions: genericActions,
  // resourceName: "contentareagradegroups",
  // apiResourceName: "tags",
  config: {
    // url: "/tags",
    // params: {by_type_c: "grade_group"},
    processData: (data, props) => {
      const { config } = props;
      const { widgetConfig } = config;
      const { shouldDisableGradeGroupIds = [] } = widgetConfig;
      return data.map((item) => {
        let disabled = false
        if(shouldDisableGradeGroupIds.length > 0 && shouldDisableGradeGroupIds.includes(item.id)) {
          disabled = true
        }
        return { label: item.label, value: item.id, disabled: disabled };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Grade",
      allowClear: true,
      dropdownMatchSelectWidth: false,
    },
  },
});

export const SelectStandardCollectionContentArea = MrSelect({
  actions: genericActions,
  resourceName: "standardcollectioncontentareas",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "content_area"},
    processData: (data, props) => {
      const { config } = props;
      const { widgetConfig } = config;
      const { shouldDisableContentAreaIds = [] } = widgetConfig; 
      return data.map((item) => {
        let disabled = false
        if(shouldDisableContentAreaIds.length > 0 && shouldDisableContentAreaIds.includes(item.id)) {
          disabled = true
        }
        return { label: item.label, value: item.id, disabled: disabled };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Content area",
      allowClear: true,
      dropdownMatchSelectWidth: false,
    },
  },
});

export const SelectStandardCollection = MrSelect({
  actions: genericActions,
  resourceName: "standardcollections",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    isJsx: true,
    params: {by_type_c: "standard_collection"},
    processData: (data) => {
      console.log("standard collections", data);
      // return data.map((item) => {
      //   return { label: item.label, value: item.id };
      // });
      let finalRender = []
      let allStandardCollections = []
      data.map((item) => {
        if(item.children.length != 0){
          let optGroupChildren = [] 
          item.children.map((child) => {
            optGroupChildren.push(<Option value={child.id}>{child.label}</Option>)
          })
          let optGroup = <OptGroup label={item.label}>
            {optGroupChildren}
          </OptGroup>
          allStandardCollections.push(optGroup)
        }
      })
      finalRender = allStandardCollections
      return <>
        {finalRender}
      </>
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Standard collection",
      allowClear: true,
    },
  },
});

export const SelectStandardCollectionOnly = MrSelect({
  actions: genericActions,
  resourceName: "standardcollections",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "standard_collection"},
    processData: (data, props) => {
      const { config } = props;
      const { widgetConfig } = config;
      const { disabledStandardCollectionLabels = [] } = widgetConfig;
      return data.map((item) => {
        if(disabledStandardCollectionLabels.length > 0 && disabledStandardCollectionLabels.includes(item.label)){
          return { label: item.label, value: item.id, disabled: true };
        }
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Standard collection",
      allowClear: true,
      dropdownMatchSelectWidth: false,
    },
  },
});

export const SelectPaperType = MrSelect({
  actions: genericActions,
  resourceName: "papertype",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: {by_type_c: "paper_type"},
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "SL only", value: "sl" },
      //   { label: "SL & HL", value: "sl_hl" },
      //   { label: "HL only", value: "hl" },
      // ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Paper type",
      placeholder: <MrTranslate id={"SelectSubject.paper_type"}/>,
      allowClear: true,
    },
  },
});

export const SelectTagType = MrSelect({
  config: {
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   { label: "Syllabus topic", value: "topic" },
      //   { label: "Command term", value: "command_term" },
      //   { label: "Difficulty level", value: "difficulty_level" },
      //   { label: "Teaching level", value: "teaching_level" },
      //   { label: "Paper type", value: "paper_type" },
      //   { label: "Depth of knowledge", value: "depth_of_knowledge" },
      // ],
      options: [
        { label: <MrTranslate id={"SelectSubject.standard_collection"}/>, value: "standard_collection" },
        { label: <MrTranslate id={"SelectSubject.content_area"}/>, value: "content_area" },
        // { label: <MrTranslate id={"SelectSubject.syllabus_topic"}/>, value: "topic" },
        { label: <MrTranslate id={"SelectSubject.key_concept"}/>, value: "key_concept" },
        { label: <MrTranslate id={"SelectSubject.related_concept"}/>, value: "related_concept" },
        { label: <MrTranslate id={"SelectSubject.global_context"}/>, value: "global_context" },
        { label: <MrTranslate id={"SelectSubject.possible_exploration"}/>, value: "possible_exploration" },
        { label: <MrTranslate id={"SelectSubject.atl_skill_category"}/>, value: "atl_skill_category" },
        { label: <MrTranslate id={"SelectSubject.command_term"}/>, value: "command_term" },
        { label: <MrTranslate id={"SelectSubject.difficulty_level"}/>, value: "difficulty_level" },
        { label: <MrTranslate id={"SelectSubject.teaching_level"}/>, value: "teaching_level" },
        { label: <MrTranslate id={"SelectSubject.paper_type"}/>, value: "paper_type" },
        { label: <MrTranslate id={"SelectSubject.depth_of_knowledge"}/>, value: "depth_of_knowledge" },
        { label: <MrTranslate id={"SelectSubject.assessment_objective"}/>, value: "assessment_objective" },
        
      ],
      showSearch: false,
      // filterOption: true,
      // optionFilterProp: "label",
      // placeholder: "Tag type",
      placeholder: <MrTranslate id={"SelectSubject.tag_type"}/>,
      allowClear: true,
    },
  },
});


export const SelectCourseId = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "1173", value: 1173 },
        { label: "1174", value: 1174 },
        { label: "1138", value: 1138 },
        { label: "1175", value: 1175 },
        { label: "1176", value: 1176 },
        { label: "1178", value: 1178 },
        { label: "1179", value: 1179 },
        { label: "1180", value: 1180 },
        { label: "1181", value: 1181 },
        { label: "1182", value: 1182 },
        { label: "1183", value: 1183 },
        { label: "1186", value: 1186 },
        { label: "1187", value: 1187 },
        { label: "1188", value: 1188 },
        { label: "1190", value: 1190 },
        { label: "1191", value: 1191 },
        { label: "1140", value: 1140 },
        { label: "1203", value: 1203 },
        { label: "1167", value: 1167 },
        { label: "1168", value: 1168 },
        { label: "1169", value: 1169 },
        { label: "1237", value: 1237 },
        { label: "1315", value: 1315 },
        { label: "1316", value: 1316 },
        { label: "1317", value: 1317 },
        { label: "1318", value: 1318 },
        { label: "1328", value: 1328 },
        { label: "1329", value: 1329 },
        { label: "1330", value: 1330 },
        { label: "1331", value: 1331 },
        { label: "1341", value: 1341 },
        { label: "1342", value: 1342 },
        { label: "1343", value: 1343 },
        { label: "1344", value: 1344 },
        { label: "1345", value: 1345 },
        { label: "1346", value: 1346 },
        { label: "1347", value: 1347 },
        { label: "1348", value: 1348 },
        { label: "1350", value: 1350 },
        { label: "1351", value: 1351 },
        { label: "1352", value: 1352 },
        { label: "1353", value: 1353 },
        { label: "1354", value: 1354 },
        { label: "1355", value: 1355 },
        { label: "1356", value: 1356 },
        { label: "1357", value: 1357 },
        { label: "1360", value: 1360 },
        { label: "1361", value: 1361 },
        { label: "1364", value: 1364 },
        { label: "1365", value: 1365 },
        { label: "1366", value: 1366 },
        { label: "1367", value: 1367 },
        { label: "1368", value: 1368 },
        { label: "1369", value: 1369 },
        { label: "1370", value: 1370 },
        { label: "1371", value: 1371 },
        { label: "1373", value: 1373 },
        { label: "1374", value: 1374 },
        { label: "1375", value: 1375 },
        { label: "1377", value: 1377 },
        { label: "1378", value: 1378 },
        { label: "1379", value: 1379 },
        { label: "1380", value: 1380 },
        { label: "1383", value: 1383 },
      ],
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select course ID",
      placeholder: <MrTranslate id={"SelectSubject.select_course_id"}/>,
      // mode: "multiple",
      allowClear: true,
      // style: {width: "150px"},
      style: { width: "100%" },
    },
  },
});

export const SelectStatus = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      // We removed the MrTranslate key because the data is static and not fetched from the backend. MrIntl converts it into HTML data instead of a string, which caused the search functionality to not work.
      options: [
        { label: "Draft", value: "draft" },
        { label: "Published", value: "published" },
        { label: "Closed", value: "closed" },
      ],
      className: "select-status",
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      dropdownMatchSelectWidth: false,
      // placeholder: "Status",
      placeholder: <MrTranslate id={"SelectSubject.status"}/>,
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

export const SelectEvaluationType = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);

      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      // Note: Giving static options here means using MrTranslate which returns a component - can't search formative in this case - APL-3480 - solved by sending options - IDEALLY want to use mrIntl here like in getForms @AJ
      // options: [
      //   // { label: "Formative", value: "formative" },
      //   { label: <MrTranslate id={"CommonText.formative"}/>, value: "formative" },
      //   { label: "Summative", value: "summative" },
      //   // { label: <MrTranslate id={"CommonText.summative"}/>, value: "summative" }
      // ],
      className: "select-status",
      showSearch: true,
      filterOption: true,
      // filterOption: (input, option) => {console.log("custom jsx filter", option, input); return (option.children).includes(input)},
      optionFilterProp: "label",
      dropdownMatchSelectWidth: false,
      // placeholder: "Status",
      placeholder: <MrTranslate id={"CommonText.evaluation_type"}/>,
      // style: { width: "100%" },
      allowClear: true,
      labelRender: (value) => {
        return <Space direction="vertical">
          <span>Select type</span>
          <span>Formative</span>
        </Space>
      }
    },
  },
});

export const SelectDeliveryMode = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options : [
        { label: "Any browser", value: "online" },
        { label: "Any browser with security", value: "online_secure" },
        { label: "Lockdown app", value: "offline" },
      ],
      className: "select-status",
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      dropdownMatchSelectWidth: false,
      // placeholder: "Status",
      placeholder: <MrTranslate id={"Experiences.delivery_mode"}/>,
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

// export const SelectOrder = MrSelect({
//   // actions: genericActions,
//   // resourceName: "grades",
//   config: {
//     // url: "/grades",
//     // params: {by_org_programme_id: 3}, //get from previous filter
//     processData: (data, props) => {
//       console.log("data select groups", data);
//       console.log("props select groups", props);
//       return data.map((item) => {
//         console.log("class selector item", item);
//         return { label: item.label, value: item.value };
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       options: [
//         { label: "Upcoming", value: "upcoming" },
//         { label: "Newest", value: "newest" },
//       ],
//       showSearch: false,
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Sort by",
//       style: { width: "100%" },
//       allowClear: true,
//     },
//   },
// });

export const SelectStarting = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: <MrTranslate id={"SelectSubject.today"}/>, value: "today" },
        { label: <MrTranslate id={"SelectSubject.this_week"}/>, value: "next_7" },
        { label: <MrTranslate id={"SelectSubject.next_30_days"}/>, value: "next_30" },
        { label: <MrTranslate id={"SelectSubject.last_week"}/>, value: "prev_7" },
        { label: <MrTranslate id={"SelectSubject.last_30_days"}/>, value: "prev_30" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Starting",
      // placeholder: "Scheduled time",
      placeholder: <MrTranslate id={"SelectSubject.scheduled_time"}/>,
      // style: { width: "120px" },
      allowClear: true,
    },
  },
});

export const SelectOrgQuestionbank = MrSelect({
  actions: genericActions,
  resourceName: "orgquestionbanks",
  apiResourceName: "org_questionbanks",
  // resourceName: "questionbanks",
  config: {
    url: "/org_questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      // const currentUser = useSelector(currentUserSelector)
      // console.log("currentUser from selector", currentUser)
      console.log("data =====>", data, props)
      const widgetConfig = props.config.widgetConfig || {}
      const options = []
      data.map((item) => {
        let resourceCountRender = item.resource_count_from_qb_custom_fields ? `(${item.resource_count_from_qb_custom_fields.experiences.total_published})` : ""
        let title = `${item.custom_fields.title} ${resourceCountRender}`;
        options.push({ label: title, value: item.questionbank_id });
      });
      if(widgetConfig.showSchoolLibraryInOptions) {
        // Set the value to null because clicking on "school library" displays all assessments associated with it, given that the school library serves as the default option.
        options.unshift({ label: "School library", value: null})
      }
      return options
    },
    widgetConfig: {
      showSearch: false,
      // placeholder: "Select questionbank",
      placeholder: <MrTranslate id={"SelectSubject.select_questionbank"}/>,
      allowClear: true,
      // mode: "multiple",
      // style: { width: "300px" },
      style: { width: "100%" },
    },
  },
});


export const SelectOrgQuestionbankRadio = (props) => {
  const { onChange, value, config } = props;
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const dataItems = useSelector(orgquestionbanksSelector());
  let params = {}
  if(config && config.params){
    params = {...config.params, params}
  }
  useEffect(() => {
    if (!dataItems || !dataItems.length) {
      // dispatch(genericActions.orgquestionbanks())
      
      dispatch(
        genericActions.orgquestionbanks(
          { params: params },
          {
            resourceName: "orgquestionbanks",
            apiResourceName: "org_questionbanks",
            url: "/org_questionbanks",
            successCallback: (data) => {
              // callback && callback(data);
              //
            },
          }
        )
      );
    }
    return () => {};
  }, []);

  let radioButtons = [];
  let finalRender = [];
  let schoolQBDefault = (
    <Space direction="vertical" size={4} className={"radio-button-inner"}>
      <Space className={"title"}>
        <img
          alt="icon"
          src={
            "https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/icons/sebo_icons/OSC+Logo.png"
          }
        ></img>
        <span className={"title-text"}>School library</span>
      </Space>
      <span className={"description"}>234 assessments</span> {/* TODO count */}
    </Space>
  );
  radioButtons.push(
    <Radio.Button value={null}>{schoolQBDefault}</Radio.Button>
  );
  if (dataItems) {
    console.log("SelectOrgQuestionbankRadio dataItems", dataItems);
    dataItems.forEach((item, i) => {
      // let title = `${item.custom_fields.title} (${item.resource_count})`
      let title = (
        <Space direction="vertical" size={4} className={"radio-button-inner"}>
          <Space className={"title"}>
            <img
              alt="icon"
              src={
                "https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/icons/sebo_icons/OSC+Logo.png"
              }
            ></img>
            <span className={"title-text"}>{item.custom_fields.title}</span>
          </Space>
          {/* <span className={"description"}>54 Past Papers</span> */}
          <span className={"description"}>
            {item.resource_count.experiences} Assessments
          </span>
          {/* <span className={"description"}>{item.resource_count.segments} Resources</span> */}
        </Space>
      );
      radioButtons.push(
        <Radio.Button value={item.questionbank_id}>{title}</Radio.Button>
      );
    });
  }
  finalRender.push(<h3>Select Questionbank</h3>);
  finalRender.push(
    <Radio.Group
      defaultValue={value}
      buttonStyle="solid"
      className="custom-radio-filters qb-filters borderless"
      onChange={onChange}
    >
      {radioButtons}
    </Radio.Group>
  );
  // TODO: click to set, click again to clear - needed in case of item type selection - not so much in qb and subjects select

  return finalRender;
};

export const SelectProgrammeGradeRadio = (props) => {
  const { onChange, value, config } = props;
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const dataItems = useSelector(programmegradesSelector());
  let params = {}
  if(config && config.params){
    params = {...config.params, params}
  }
  useEffect(() => {
    // if (!dataItems || !dataItems.length) {
      // dispatch(genericActions.orgquestionbanks())
      dispatch(
        genericActions.programmegrades(
          { params: params },
          {
            resourceName: "programmegrades",
            apiResourceName: "programme_grades",
            url: "/programme_grades",
            successCallback: (data) => {
              // callback && callback(data);
              //
            },
          }
        )
      );
    // }
    return () => {};
  }, [params.by_questionbank_id]);

  let radioButtons = [];
  let finalRender = [];
  if (dataItems) {
    console.log("SelectProgrammeGradeRadio dataItems", dataItems);
    dataItems.forEach((item, i) => {
      let title = (
        <Space direction="vertical" size={4} className={"radio-button-inner"}>
          <Space className={"title"}>
            <span className={"title-text"}>{item.label}</span>
          </Space>
        </Space>
      );
      radioButtons.push(
        <Radio.Button value={item.id}>{title}</Radio.Button>
      );
    });
  }
  // finalRender.push(<h3>Select Grade</h3>);
  finalRender.push(<h3><MrTranslate id={"CommonText.select_grade"} /></h3>);
  finalRender.push(
    <Radio.Group
      defaultValue={value}
      buttonStyle="solid"
      className="custom-radio-filters borderless"
      onChange={onChange}
    >
      {radioButtons}
    </Radio.Group>
  );
  // TODO: click to set, click again to clear - needed in case of item type selection - not so much in qb and subjects select

  return finalRender;
};

export const SelectProgrammeSubjectRadio = (props) => {
  // TODO: use this one as template to make generic MrRadio
  const { onChange, value, config } = props;
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const dataItems = useSelector(programmesubjectsSelector());
  const [activeSubjectGroupId, setActiveSubjectGroupId] = useState(null);
  console.log("SelectProgrammeSubjectRadio props", props, dataItems);
  let params = {}
  if(config && config.params){
    params = {...config.params, params}
  }
  useEffect(() => {
    // if (!dataItems || !dataItems.length) {
      console.log("SelectProgrammeSubjectRadio fetching", props, dataItems);
      // dispatch(genericActions.orgquestionbanks())

      dispatch(
        genericActions.programmesubjects(
          { params: params },
          {
            resourceName: "programmesubjects",
            apiResourceName: "programme_subjects",
            url: "/programme_subjects",
            successCallback: (data) => {
              // callback && callback(data);
              //
            },
          }
        )
      );
    // }
    return () => {};
  }, [params.by_questionbank_id]); // TODO make dynamic

  let radioButtons = [];
  let finalRender = [];
  finalRender.push(<h3>Select Subject</h3>);

  if (dataItems) {
    console.log("SelectProgrammeSubjectRadio dataItems", dataItems);
    if(config.nested){
      dataItems.forEach((item, i) => {
        let innerRadioButtons = []
        let innerRender = []
        let title = (
          <Space direction="vertical" size={4} className={"radio-button-inner"}>
            <Space className={"title"}>
              <img
                alt="icon"
                src={
                  "https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/icons/sebo_icons/OSC+Logo.png"
                }
              ></img>
              <span className={"title-text"}>{item.label}</span>
            </Space>
            {/* <span className={"description"}>54 Past Papers</span> */}
            {/* <span className={"description"}>
              20 
            </span> */}
            {/* <Space className={"title"}>
              <span className={"title-text"}>{item.label}</span>
            </Space> */}
          </Space>
        );
        

        if(item.children_attributes && item.children_attributes.length > 0){
          item.children_attributes.forEach((item1, i) => {
            let title = (
              <Space direction="vertical" size={4} className={"radio-button-inner"}>
                <Space direction="vertical" size={4} className={"title"}>
                  <img
                    alt="icon"
                    src={
                      "https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/icons/sebo_icons/OSC+Logo.png"
                    }
                  ></img>
                  <span className={"title-text"}>{item1.label}</span>
                </Space>
                {/* <span className={"description"}>54 Past Papers</span> */}
                <span className={"description"}>
                  20 
                </span>
                {/* <Space className={"title"}>
                  <span className={"title-text"}>{item1.label}</span>
                </Space> */}
              </Space>
            );
            innerRadioButtons.push(
              <Radio.Button value={item1.id}>{title}</Radio.Button>
            );
          });
        }
        innerRender.push(
          <Radio.Group
            defaultValue={value}
            buttonStyle="solid"
            className="custom-radio-filters borderless card-view"
            onChange={onChange}
          >
            {innerRadioButtons}
          </Radio.Group>
        );
        radioButtons.push(
          <Space direction="vertical" style={{width: "100%"}} size={4}>
            <Radio.Button style={{width: "100%"}} value={item.id}>{title}</Radio.Button>
            {activeSubjectGroupId == item.id && innerRender}
          </Space>
        );
      })
      finalRender.push(
        <Radio.Group
          defaultValue={value}
          buttonStyle="outline"
          className="custom-radio-filters"
          onChange={(e) => {setActiveSubjectGroupId(e.target.value)}}
        >
          {radioButtons}
        </Radio.Group>
      );
    }else{
      dataItems.forEach((item, i) => {
        let title = (
          <Space direction="vertical" size={4} className={"radio-button-inner"}>
            <Space className={"title"}>
              <span className={"title-text"}>{item.label}</span>
            </Space>
          </Space>
        );
        radioButtons.push(
          <Radio.Button value={item.id}>{title}</Radio.Button>
        );
      });
  
      finalRender.push(
        <Radio.Group
          defaultValue={value}
          buttonStyle="solid"
          className="custom-radio-filters borderless"
          onChange={onChange}
        >
          {radioButtons}
        </Radio.Group>
      );
    }

    // TODO: click to set, click again to clear - needed in case of item type selection - not so much in qb and subjects select
  }else{
    finalRender.push(<span><MrTranslate id={"CommonText.no_data"}/></span>);
  }
  

  return finalRender;
};

export const SelectAIModel = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "GPT 4o", value: "gpt-4o" },
        { label: "Claude3.5 Sonnet", value: "claude-3-5-sonnet-20240620" },
        { label: "O1-mini", value: "o1-mini-2024-09-12" },
        // { label: "Claude", value: "claude" },
        // { label: "Vicuna 13B", value: "vicuna_13b" },
        // { label: "Google text-bison-001 fine-tuned", value: "google_text_bison_fine_tuned" },
        // { label: "Vicuna 13B fine-tuned", value: "vicuna_13b_fine_tuned" },
      ],
      className: "select-status",
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Model",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

export const SelectResponseFormat = MrSelect({
  config: {
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "Text", value: "text" },
        { label: "Json", value: "json" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Response format",
      allowClear: true,
    },
  },
});


export const SelectPromptType = MrSelect({
  config: {
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "Text", value: "text" },
        { label: "Image", value: "image" },
        { label: "PDF", value: "pdf" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      allowClear: false,
    },
  },
});

export const SelectPromptStatus = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        { label: "Published", value: "published" },
        { label: "Draft", value: "draft" },
      ],
      className: "select-status",
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Status",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

export const SelectCountry = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildCountriesOptionsArr(),
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Country",
      // style: { width: "100%" },
      allowClear: true
    },
  },
});

export const GenericWidgetCreator  = (hocProps, Component) => {  
  return (props) => {
    let finalHocProps = {}
    if(typeof(hocProps) === "function"){
      finalHocProps = hocProps(props);
    }
    return <Component {...props} {...finalHocProps}/>
  }
}



// ========== Using these Selects in the GenerateAiWithPrompt component ==========
export const SelectQuestionbankUUID = MrSelect({
  actions: genericActions,
  resourceName: "questionbanks",
  config: {
    url: "/questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        // TODO: CHANGED TO CODE but need to change all names
        // return { label: item.title, value: item.uuid };
        return { label: item.title, value: item.code };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      // placeholder: "Select questionbank",
      placeholder: <MrTranslate id={"CommonText.select_questionbank"} />
      // mode: "multiple",
      // style: { width: "300px" },
    },
  },
});

export const SelectKeyConcepts = MrSelect({
  actions: genericActions,
  resourceName: "keyconcepts",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: { by_type_c: "key_concept" },
    processData: (data) => {
      console.log("SelectKeyConcepts==>", data);
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"GenerateAIWithPrompt.select_key_concepts"} />,
      allowClear: true,
      mode: "multiple",
      style: {
        width: "250px",
      }
    },
  },
});
export const SelectRelatedConcepts = MrSelect({
  actions: genericActions,
  resourceName: "relatedconcepts",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: { by_type_c: "related_concept" },
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"GenerateAIWithPrompt.select_related_concepts"} />,
      allowClear: true,
      mode: "multiple",
      style: {
        width: "250px",
      }
    },
  },
});

export const SelectGlobalContext = MrSelect({
  actions: genericActions,
  resourceName: "globalcontexts",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: { by_type_c: "global_context" },
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"GenerateAIWithPrompt.select_global_context"} />,
      allowClear: true,
      style: {
        width: "250px",
      }
    },
  },
});

export const SelectPossibleExplorations = MrSelect({
  actions: genericActions,
  resourceName: "possibleexplorations",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: { by_type_c: "possible_exploration" },
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"GenerateAIWithPrompt.select_possible_exploration"} />,
      allowClear: true,
      style: {
        width: "250px",
      }
    },
  },
});

export const SelectATLSkills = MrTreeSelect({
  actions: genericActions,
  resourceName: "atlskills",
  apiResourceName: "tags",
  config: {
    url: "/tags",
    params: { by_type_c: "atl_skill_category" },
    processData: (data, props) => {
      let fieldsMapping = {
        title: "label",
        value: "id",
        children: "children"
      }
      let options = {
        maxNestingLevel: 3,
        currentLevel: 1,
        titleContainsHtml: false
      }
      const treeData = buildTreeData(data, fieldsMapping, options)
      return treeData;
    },
    isJsx: false,
    widgetConfig: {
      placeholder: <MrTranslate id="GenerateAIWithPrompt.select_atl_skills" />,
      treeCheckable: true,
      multiple: true,
      treeNodeFilterProp: "title",
      allowClear: true,
      className: "custom-select-tree",
      style: {
        width: "250px",
      }
      // showCheckedStrategy: TreeSelect.SHOW_PARENT,
    },

  }
});

export const SelectGradingScale = MrSelect({
  // actions: genericActions,
  // resourceName: "grading_scales",
  config: {
    // url: "/grading_scales",
    processData: (data, props) => {
      console.log("SelectGradingScale data ====>", data)
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      placeholder: "Grading scale",
      allowClear: false,
      style: { width: "300px" },
    }
  }
})

export const SelectPlan = MrSelect({
  actions: genericActions,
  resourceName: "plans",
  config: {
    url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      // console.log("select plan", data)
      let options = []
      data.map((item) => {
        // if(item.tiers_json.length > 0){
          // item.tiers_json.map((tier) => {
            // let label = item.name + "-" + tier.name + "-" + tier.max_users
            // options.push({ label: label, value: item.id })
            // })
            // }
        options.push({ label: item.name, value: item.id })
      });
      return options
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select plan",
      // mode: "multiple",
      // style: { width: "200px" },
    },
  },
});