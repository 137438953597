import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Tag, Modal, Button, Card, Tree, Typography, Input } from 'antd';
import { useEffect, useState } from 'react';
import { getTreeData } from '/src/components/AppSpecific/SelectSubject/SelectSubject';
import "./SyllabusTopicsCustom.scss"
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const { Text } = Typography;
const { Search } = Input;

const SyllabusTopicsCustom = (props) => {
  console.log("SyllabusTopicsCustom props", props);
  const { tagged_standards_content_areas_data = [], value = [], onChange, selectable = false, className = '', multiple = true, checkable = true, defaultExpandAll = false } = props;

  const mrIntl = useTranslate();
  const allTreeData = [];
  const defaultSelectedData = []

  const getSelectedDataFromJSON = (data = {}, ids = []) => {
    const selectedDataFromJSON = [];
    const idsArray = Array.isArray(ids) ? ids : [ids];

    idsArray.forEach(id => {
      const found = data.tag?.final_json?.data?.find(item => item.id === id);
      if (found) {
        return selectedDataFromJSON.push({ key: `tag-item-${data.tag_item_id}-${id}`, id: id, label: found.label });
      }
    });

    return selectedDataFromJSON;
  };

  tagged_standards_content_areas_data.forEach((dataItem, index) => {
    const dataItemTag = dataItem?.tag || {};
    const tagLabel = dataItemTag.label || "";
    const tagGradeGroupLabel = dataItem.grade_group_label || "";
    const title = `${tagLabel} ${tagGradeGroupLabel && `- ${tagGradeGroupLabel}`}`
    let treeData = getTreeData(dataItem, "", {rootParentKey: `tag-item-${dataItem.tag_item_id}`});
    if (treeData) {
      let firstLevelTreeData = {
        title: title,
        value: `${dataItemTag.id}`,
        key: `tag-item-${dataItem.tag_item_id}`,
        children: treeData,
        checkable: false,
        selectable: false
      };
      allTreeData.push(firstLevelTreeData);
    }
    const selectedDataFromJSON = getSelectedDataFromJSON(dataItem, value)
    if (selectedDataFromJSON.length > 0) {
      defaultSelectedData.push(...selectedDataFromJSON);
    }
  })

  const [selectedData, setSelectedData] = useState(defaultSelectedData);
  const [savedTopics, setSavedTopics] = useState(defaultSelectedData);
  const [showSyllabusTopicsModal, setShowSyllabusTopicsModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const uniqueSyllabusTopicIds = [];

  useEffect(() => {
    if (Array.isArray(savedTopics) && !savedTopics.length) {
      setSavedTopics(defaultSelectedData);
      setSelectedData(defaultSelectedData);
    }
  }, [defaultSelectedData.length]);

  const filterTreeData = (data, query) => {
    return data
      .map(node => {

        if (node.children) {
          const filteredChildren = filterTreeData(node.children, query);
          if (filteredChildren.length > 0) {
            return { ...node, children: filteredChildren };
          }
        }
        if (node?.searchTitle?.toLowerCase()?.includes(query?.toLowerCase())) {
          return { ...node };
        }
        return null;
      })
      .filter(node => node !== null);
  };

  const filteredData = filterTreeData(allTreeData, searchQuery);

  const openSyllabusTopicsModal = () => setShowSyllabusTopicsModal(true);
  const handleOnSearch = e => setSearchQuery(e.target.value)

  const handleCancel = () => {
    setShowSyllabusTopicsModal(false);
    setSearchQuery("");
  }
  const handleChange = (keys, info) => {
    const nodes = info.selectedNodes || info.checkedNodes;
    const updatedNodes = nodes.map(node => ({
      key: node.key,
      id: node.value,
      label: node.title
    }));
    setSelectedData(updatedNodes);
  };

  const handleSave = () => {
    setSavedTopics(selectedData);
    const finalValues = selectedData.map(item => item.id)
    onChange(finalValues);
    setShowSyllabusTopicsModal(false);
  };

  const removeSyllabusTopic = (itemId) => {
    const updatedSelectedValues = savedTopics.filter(t => t.id !== itemId);
    setSavedTopics(updatedSelectedValues);
    setSelectedData(updatedSelectedValues);
    onChange(updatedSelectedValues.map(item => item.id));
  }

  // Get first 2 unique topics and render as tags
  const uniqueSelectedValues = 
    savedTopics
    .filter(item => {
      // Filter out duplicates based on id
      const isDuplicate = uniqueSyllabusTopicIds.includes(item.id);
      if (!isDuplicate) {
        uniqueSyllabusTopicIds.push(item.id);
        return true;
      }
      return false;
    })
    // Take only first 2 items
    .slice(0, 2)
    // Create Tag component for each topic
    .map((item, index) => (
      <Tag
        key={`selected-syllabus-topic-${item?.id}-${index}`}
        className="syllabus-topics-tag"
        closable
        onClose={() => removeSyllabusTopic(item?.id)}
      >
        {item?.label}
      </Tag>
    ));

  const totalUniqueSyllabusTopicsSelected = uniqueSyllabusTopicIds.length

  return (
    <Row className={`select-syllabus-topics-container ${className}`} justify="space-between">
      <Col span={24} className="syllabus-topics-header" >
        <Text>{mrIntl("SyllabusTopicsCustom.select_syllabus_topics")}</Text>
        <Button type="default" icon={<PlusOutlined />} onClick={() => openSyllabusTopicsModal()} >
          {mrIntl("SyllabusTopicsCustom.add_topics")}
        </Button>
      </Col>
      <Col span={24} className="syllabus-topics-tag-container" onClick={() => openSyllabusTopicsModal()}>
        {savedTopics?.length > 0 ? (
          <>
            {uniqueSelectedValues}
            {totalUniqueSyllabusTopicsSelected > 2 && (
              <Tag className="syllabus-topics-tag-more">
                {`+ ${totalUniqueSyllabusTopicsSelected - 2} ...`}
              </Tag>
            )}
          </>
        ) : (
          <Text className="syllabus-topics-no-topics-text" >
            {mrIntl("SyllabusTopicsCustom.no_topics_selected_click_add_topics_to_tag_this_question")}
          </Text>
        )}
      </Col>
      <Modal
        title={mrIntl("SyllabusTopicsCustom.select_syllabus_topics")}
        open={showSyllabusTopicsModal}
        onCancel={() => handleCancel()}
        className="syllabus-topics-modal"
        footer={[
          <Text key="selected-topics" className="syllabus-topics-total-selected-topics-text" >
            {(selectedData?.length || 0)} topic{selectedData?.length !== 1 ? 's' : ''} selected
          </Text>,
          <Button key="done" type="primary" onClick={() => handleSave()}>
            {mrIntl("CommonText.done")}
          </Button>,
        ]}
      >
        <Search
          placeholder={mrIntl("SyllabusTopicsCustom.search_all_topics")}
          allowClear
          value={searchQuery}
          onChange={(e) => handleOnSearch(e)}
        />
        <Card className="syllabus-topics-card">
          {filteredData?.length === 0 ? (
            <Text className="syllabus-topics-not-found" >
              {mrIntl("CommonText.no_data")}
            </Text>
          ) : (
            <Tree
              checkable={checkable}
              multiple={multiple}
              selectable={selectable}
              defaultExpandAll={defaultExpandAll}
              onSelect={(selectedKeys, info) => handleChange(selectedKeys, info)} // Use onSelect for "Generate with Similar"/"AI" (selectable only)
              onCheck={(selectedDataValue, info) => handleChange(selectedDataValue, info)} // Use onCheck for "Segment" (checkable only)
              selectedKeys={selectedData?.map(item => item.key)}
              checkedKeys={selectedData?.map(item => item.key)}
              treeData={filteredData}
            />
          )}
        </Card>
      </Modal>
    </Row>
  );
}

export default SyllabusTopicsCustom;