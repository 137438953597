import React, {useEffect, useState} from "react";
// import "./GroupItemDetail.scss";
import {Route, useRouteMatch, useLocation} from "react-router-dom";
import {Tabs, Button, Card, Row, Col, Tag, Alert, Badge, Modal, Space, Table, Breadcrumb, Avatar, Dropdown, Menu} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  SearchOutlined,
  GlobalOutlined,
  LeftOutlined,
  DownOutlined
} from "@ant-design/icons";
import {actions as genericActions} from "/src/App/genericRedux";
import Experiences from "/src/views/Experiences/Experiences";
import LibraryExperiences from "/src/views/Experiences/LibraryExperiences/LibraryExperiences";
import Segments from "/src/views/Segments/Segments";
import LibrarySegments from "/src/views/Segments/LibrarySegments/LibrarySegments";
import { currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from '/src/views/Auth/Login/selector';
import SegmentList from "/src/views/Segments/SegmentList/SegmentList";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import LogoImage from "../../assets/images/logo.png";
import IBLogoOSC from "../../assets/images/ib_logo_osc.svg";
import "./LibraryPage.scss";
import { getCurrentPlanStatus, getQBDesc, getQBIcon, getSubjectIconMap, openUrl } from "/src/lib/utils/helperMethods";
import { programmesubjectsSelector } from "/src/views/Programmes/selector";
import Spinner from "/src/components/UI/Spinner/Spinner";
import _ from "lodash";
import { useIntl } from "react-intl";
import { actions as questionbanksActions } from "/src/views/Questionbanks/redux";
import { message } from "/src/components/UI/AntdAppHelper";
import { orgProgrammesSelector } from "../OrgProgrammes/selector";
import { orgSubjectsSelector } from "../Orgs/selector";
import { showExperiencePreviewSelector, libraryExperienceFetchLoadingSelector } from "../Experiences/selector";
import DummyCardForUpgradePlan from "/src/lib/UpgradePlanFeature/DummyCardForUpgradePlan";
const LibraryActions = (props) => {
  console.log("LibraryActions props =====>", props);
  const { selectedQB = {} } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [zipFileUrl, setZipFileUrl] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate();

  const handleMenuClick = () => {
    setIsModalVisible(true);
  };

  const handleExportClick = () => {
    setIsExporting(true);
    dispatch(questionbanksActions.exportStudentPerformance({
      org_id: currentUser.org_id,
      questionbank_id: selectedQB.questionbank_id
    }, {
      successCallback: (data) => {
        console.log("exportStudentPerformanceSaga response", data);
        message.success(data.message);
        setZipFileUrl(data.zip_url)
        setIsExporting(false)
      },
      errorCallback: () => setIsExporting(false)
    }))
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="export_student_performance">
        {mrIntl("LibraryPage.export_student_performance")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={"library-actions-wrapper"}>
      <Dropdown overlay={menu}>
        <Button type={"text"}>
          {mrIntl("LibraryPage.actions")} <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        title={mrIntl("LibraryPage.export_student_performance")}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <p>{mrIntl("LibraryPage.click_on_the_export_button_to_export_student_performance")}</p>
        <Button
          type="primary"
          loading={isExporting}
          onClick={handleExportClick}
          style={{ marginRight: 10 }}
        >
          {isExporting ? `${mrIntl("LibraryPage.exporting")}...` : mrIntl("LibraryPage.export")}
        </Button>
        <Button
          disabled={!zipFileUrl}
          type="default"
          onClick={() => openUrl(zipFileUrl)}
        >
          {mrIntl("LibraryPage.download_zip")}
        </Button>
      </Modal>
    </div>
  );
};

const LibraryPage = (props) => {
  console.log( "LibraryPage props", props );
  const {item, history, intl } = props;
  const match = useRouteMatch();
  const {path, url} = match;
  const location = useLocation()
  console.log( "LibraryPage props", match, location, path, url );
  const { TabPane } = Tabs;
  const mrIntl = useTranslate();
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const isQbAuthor = currentUser.role === "qb_author" ? true : false
  const isSupport = currentUser.role === "support" ? true : false
  const libraryExperiencesLoading = useSelector(libraryExperienceFetchLoadingSelector())
  const showExperiencePreview = useSelector(showExperiencePreviewSelector())
  
  // const showExploreLibraryTab = isQbAuthor || true ? false : (enabledFeatures.explore_library_tab || true);
  const showExploreLibraryTab = !isQbAuthor && !isSupport;
  
  
  const [activeKey, setActiveKey] = useState('tests')
  const [activeKey1, setActiveKey1] = useState(null)
  // useEffect(() => {
  //   if(showExploreLibraryTab){
  //     handleTabClick1('explore')
  //   }else{
  //     handleTabClick1('search')
  //   }
  //   if(location.pathname.indexOf('search') !== -1)
  //     setActiveKey("search")
  //   if(location.pathname.indexOf('tests') !== -1)
  //     setActiveKey("tests")
  //   if(location.pathname.indexOf('resources') !== -1)
  //     setActiveKey("resources")
  //   if(location.pathname.indexOf('library') !== -1 && location.pathname.indexOf('tests') == -1 && location.pathname.indexOf('resources') == -1 && location.pathname.indexOf('explore') == -1 && location.pathname.indexOf('search') == -1){
  //     history.push(`${url}/tests`)
  //     setActiveKey("tests")
  //   }
  // }, [])

  useEffect(() => {
    const isActiveTestTab = activeKey === 'tests' || activeKey1 === 'tests';

    if (!libraryExperiencesLoading && isActiveTestTab) {
      autoFocusSearch();
    }
  }, [libraryExperiencesLoading]);
  

  useEffect(() => {
    console.log("location.pathname 58", location.pathname);
    if(location.pathname === '/u/library') {
      if(showExploreLibraryTab){
        handleTabClick1('explore')
      }else{
        // handleTabClick1('search')
        handleTabClick('tests')
      }
    }else if(location.pathname === '/u/library/explore'){
      setActiveKey1('explore')
    }else if(location.pathname === '/u/library/tests'){
      setActiveKey1('tests')
    }else{

    }
  }, [location.pathname])

  const autoFocusSearch = () => {
    const searchInput = document.querySelector('.custom-main-crud-list-header .ant-input-search input.ant-input');

    if(searchInput) {
      searchInput.focus();
    } 
  }
  
  const handleTabClick = (key, event) => {
    history.push(`${url}/${key}`)
    setActiveKey(key)
  }
  const handleTabClick1 = (key, event) => {
    history.push(`${url}/${key}`)
    setActiveKey1(key)
  }
  

  const canViewResourcesTab = currentUser.role == "superadmin" || currentUser.role == "qb_author" ? true : false
  // const canViewResourcesTab = false
  // TEMP DISABLED FOR ALL - allow only for Pamoja qb authors

  // const canCreateAssessments = currentUser.role == "superadmin" ? true : false
  // only letting qb_author create, not even superadmin
  const canCreateAssessments = currentUser.role == "qb_author" ? true : false

  let libExperiencesCrudConfig = {list: {forceReload: true}, filter: {show: true, config: {create: {show: false}, search: {show: false}}}} // IMP: hide search in Filter component, rendering separately above list in custom layout
  if(canCreateAssessments){
    // params = {by_experience_id: experience.id, by_user_id: currentUser.id}
    libExperiencesCrudConfig = {filter: {show: true, config: {create: {show: true}, search: {show: false}} }}
  }

  

  const DPBankAnnouncementAlert = (props) => {
    let finalRender = []
    const enabledFeatures = useSelector(enabledFeaturesSelector())
    const orgQBs = useSelector(orgquestionbanksSelector())
    const currentUser = useSelector(currentUserSelector())
    const embedded = useSelector(getEmbeddedSelector())
    const [visible, setVisible] = useState(false);
    
    let isDPBundle = (currentUser.org.org_subscriptions_attributes && currentUser.org.org_subscriptions_attributes.filter(os => os.code == "AP-DP" && os.archived == false).length > 0) ? true : false
    let isAnyDPBankEnabled = orgQBs && orgQBs.filter(oqb => oqb.custom_fields.title.indexOf("DP") > -1).length > 0 ? true : false
    let isIBDPBankEnabled = orgQBs && orgQBs.filter(oqb => oqb.custom_fields.title.indexOf("IB DP") > -1).length > 0 ? true : false
    console.log("isAnyDPBankEnabled", orgQBs, isAnyDPBankEnabled, isIBDPBankEnabled);

    const columns = [
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: 'Assessment count',
        dataIndex: 'count',
        key: 'count',
        align: 'right'
      },
    ]
    const ib_dp_data = [
      {
        key: '1',
        subject: 'Maths A&A',
        count: 4,
      },
      {
        key: '2',
        subject: 'Maths A&I',
        count: 4,
      },
      {
        key: '3',
        subject: 'Biology',
        count: 4,
      },
      {
        key: '4',
        subject: 'Chemistry',
        count: 16,
      },
      {
        key: '5',
        subject: 'Physics',
        count: 20,
      },
      {
        key: '6',
        subject: 'I&S',
        count: "Coming soon",
      },
    ]
    const osc_dp_data = [
      {
        key: '1',
        subject: 'Maths A&A',
        count: 10,
      },
      {
        key: '2',
        subject: 'Maths A&I',
        count: 10,
      },
    ]
    const osc_dp_mocks_data = [
      {
        key: '1',
        subject: 'Maths A&A',
        count: 5,
      },
      {
        key: '2',
        subject: 'Maths A&I',
        count: 5,
      },
    ]
    
    let subjectInfoModal = <Modal
      title={"Available subjects"}
      open={visible}
      footer={null}
      // onOk={handleOk}
      onCancel={() => setVisible(false)}
      width={500}
      wrapClassName="height-centered-modal"
      closable={true}
      maskClosable={true}
      keyboard={true}
      bodyStyle={{overflowY: "auto"}}
    >
      <Row>
        <Space direction="vertical">
          {/* {orgQBs && orgQBs.map((oqb, i) => {
            if(oqb.custom_fields.title.indexOf("DP") > -1){
              
              return 
            }
          })} */}
          {isIBDPBankEnabled && <span>
            <h4><b>IB DP</b></h4>
            <Table size={"small"} pagination={false} columns={columns} dataSource={ib_dp_data} />
            <br></br>
          </span>}
          <span>
            <h4><b>OSC DP</b></h4>
            <Table size={"small"} pagination={false} columns={columns} dataSource={osc_dp_data} />
            <br></br>
          </span>
          <span>
            <h4><b>OSC DP Mocks</b></h4>
            <Table size={"small"} pagination={false} columns={columns} dataSource={osc_dp_mocks_data} />
          </span>
        </Space>
      </Row>
    </Modal>


    if(!embedded && isAnyDPBankEnabled && (currentUser.role === "admin" || currentUser.role === "teacher")){
      finalRender.push(<div style={{marginLeft: "10px"}}>
        <Badge.Ribbon text="New!" color="red" placement="start">
          <Alert message="Select a DP questionbank to view ready-to-use DP assessments" action={<Button size="small" type={"primary"} onClick={() => setVisible(true)}>See available subjects</Button>} className="m-b-10" style={{maxWidth: "98%", paddingLeft: "50px"}}/>
        </Badge.Ribbon>
      </div>)
      finalRender.push(subjectInfoModal)
    }

    return finalRender;
  }

  const ExploreLibraryGrid = (props) => {
    const dispatch = useDispatch();
    const subjectIconMap = getSubjectIconMap();
    const orgQuestionbanks = useSelector(orgquestionbanksSelector());
    const programmeSubjects = useSelector(programmesubjectsSelector());
    const orgProgrammes = useSelector(orgProgrammesSelector())
    const orgSubjects = useSelector(orgSubjectsSelector())
    const currentUser = useSelector(currentUserSelector());
    const [loading, setLoading] = useState(false)
    const orgLogoUrl = currentUser.org.attachments_json.top_nav_logo || ""
    const showStudentPerformanceExport = enabledFeatures.export_student_performance
    const isExploreViewForSchoolLibrary = enabledFeatures.explore_view_for_school_library || import.meta.env.VITE_MODE !== "production";

    let finalRender = [];
    console.log("ExploreLibraryGrid orgQuestionbanks programmeSubjects", orgQuestionbanks, programmeSubjects);
    let qbs = [
      {
        custom_fields: {
          title: mrIntl("CommonText.school_library")
        },
        qb_code: "school",
        // resource_count: {
        //   experiences: 256,
        //   segments: 1000 
        // },
        // resource_count: 256,
        icon: orgLogoUrl
      },
    ]

    if(orgQuestionbanks){
      qbs = qbs.concat(orgQuestionbanks)
    }
    

    
    const getBreadCrumbItems = () => {
      let items = []
      
    }

    useEffect(() => {
      // Dispatching to fetch each time to avoid APL-3603 issue
      // if(!orgQuestionbanks){
        // dispatch(genericActions.orgquestionbanks())
        dispatch(genericActions.orgquestionbanks(
          {params: {
            unarchived: true, 
            // with_resource_count: true,
            // with_resource_count_for_resource_type: "experience",
            without_qb_codes: ["osc_dp_mocks", "osc_dp"]
          }}, 
          {
            resourceName: "orgquestionbanks",
            apiResourceName: "org_questionbanks",
            url: "/org_questionbanks",
            successCallback: (data) => {
              // callback && callback(data);
              // 
            }
          }
        ));
      // }
      return () => {
        
      };
    }, []);

    const [selectedQB, setSelectedQB] = useState(null);
    const [selectedOrgProgramme, setSelectedOrgProgramme] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const shouldShowLibraryActions = selectedQB?.qb_code !== "school" && showStudentPerformanceExport;

    const getProgrammeSubjects = (qb) => {
      setSelectedQB(qb)
      // above actually orgQB
      setLoading(true)
      dispatch(genericActions.programmesubjects(
        {params: {
          unarchived: true, 
          by_questionbank_id: qb.questionbank_id,
          by_type_c: "group",
          // with_resource_count: true,
          // with_resource_count_for_resource_type: "experience",
        }}, 
        {
          resourceName: "programmesubjects",
          apiResourceName: "programme_subjects",
          url: "/programme_subjects",
          successCallback: (data) => {
            setLoading(false)
            // callback && callback(data);
            // 
          }
        }
      ));
    }

    const getOrgProgramme = (qb) => {
      // above actually orgQB
      setSelectedQB(qb)
      setLoading(true)
      dispatch(genericActions.orgprogrammes(
        {params: {
          unarchived: true, 
          by_org_id: currentUser.org.id,
          by_non_zero_experience_count: true,
          // by_type_c: "group",
          // with_resource_count: true,
          // with_resource_count_for_resource_type: "experience",
        }}, 
        {
          resourceName: "orgprogrammes",
          apiResourceName: "org_programmes",
          url: "/org_programmes",
          successCallback: (data) => {
            setLoading(false)
            // callback && callback(data);
            // 
          }
        }
      ));
    }

    const openSelectedSubject = (subjectId, selectedQB) => {
      console.log("openSelectedSubject qbId", selectedQB?.questionbank_id, subjectId, selectedGrade);
      const isSchool = selectedQB?.qb_code === "school";
      let path = `${url}/tests?`;

      if (isSchool) {
        path += `by_org_programme_id=${selectedOrgProgramme.id}&by_subject_ids[]=${subjectId}&by_grade_ids[]=${selectedGrade.id}`;
      } else {
        path += `by_questionbank_id=${selectedQB?.questionbank_id}&by_programme_subject_ids[]=${subjectId}`;
      }

      history.push(path);
    };

    const getSrc = (qb) => {
      const icon = qb.qb_code === "school" ? qb.icon : getQBIcon(qb.qb_code)
      return icon
    }

    const getOrgProgrammeSubjects = (op, grade) => {
      console.log("getOrgProgrammeSubjects ===>", op,grade);
      setSelectedOrgProgramme(op)
      setSelectedGrade(grade)
      setLoading(true)
      dispatch(genericActions.subjects(
        {params: {
          unarchived: true, 
          by_org_programme_id: op.id,
          zero_experience_count_with_grade_id: grade.id,
        }}, 
        {
          resourceName: "subjects",
          apiResourceName: "subjects",
          url: "/subjects",
          successCallback: (data) => {
            setLoading(false)
            // callback && callback(data);
            // 
          }
        }
      ));
    }
    
    if(qbs && qbs.length > 0 && !selectedQB){
      qbs.forEach(qb => {
        const qbIconBlank = (qb.icon === "" || getQBIcon(qb.qb_code) === "")
        const firstLatterOfQb = qb.qb_code === "school" ? currentUser.org.name.charAt(0) : qb.custom_fields.title.charAt(0)
        let cardRender = <Col xs={24} sm={12} md={8} lg={6}>
          <Card className="text-center cursor-pointer clickable-card qb-card" style={{"marginBottom":"15px"}} onClick={() => {
            if(qb.qb_code === "school"){
              // handleTabClick1('tests?by_school=true')
              // history.push(`${url}/tests?by_school=true`)
              if (isExploreViewForSchoolLibrary) {
                getOrgProgramme(qb)
              } else {
                history.push(`${url}/tests?by_school=true`)
              }
            }else{
              getProgrammeSubjects(qb)
            }
          }}>
            <Space direction="vertical">
              <Avatar src={!qbIconBlank && getSrc(qb)} className={qbIconBlank && "default-logo-button"}>{qbIconBlank && firstLatterOfQb}</Avatar>
              <h2 className="m-t-10">{qb.custom_fields.title}</h2>
              {/* {qb.resource_count && <span style={{color: "#aaa"}}>{qb.resource_count} Assessments</span>} */}
              {qb.resource_count_from_qb_custom_fields && <span style={{color: "#aaa"}}>{qb.resource_count_from_qb_custom_fields.experiences.total_published} {mrIntl("LibraryPage.assessments")}</span>}
              <p style={{color: "#999", fontSize: "12px", minHeight: "37px"}}>{getQBDesc(qb.qb_code, mrIntl)}</p>
              {/* {qb.resource_count_from_qb_custom_fields && <span style={{color: "#aaa"}}>{qb.resource_count_from_qb_custom_fields.experiences.total} Assessments</span>}
              {qb.resource_count_from_qb_custom_fields && <span style={{color: "#aaa"}}>{qb.resource_count_from_qb_custom_fields.segments.total_published} Items</span>}
              {qb.resource_count_from_qb_custom_fields && <span style={{color: "#aaa"}}>{qb.resource_count_from_qb_custom_fields.segments.total} Items</span>} */}
            </Space>
          </Card>
        </Col>

        finalRender.push(cardRender)
      });
    }
    const { isStandardPlan, hasMaxOrPlusPlan } = getCurrentPlanStatus(currentUser);
    const isAPMYPBankEnabled = orgQuestionbanks && orgQuestionbanks.filter(oqb => oqb.custom_fields?.title === "AP MYP").length > 0 ? true : false
    const isAPMYPStandardBankEnabled = orgQuestionbanks && orgQuestionbanks.filter(oqb => oqb.custom_fields?.title === "AP MYP Standard").length > 0 ? true : false

    //The below condition is to make Dummy Card for Upgrade Plan visible in Library explore page only, where banks are visible
    if (qbs && qbs.length > 0 && !selectedQB && isStandardPlan && !hasMaxOrPlusPlan && !isAPMYPBankEnabled && isAPMYPStandardBankEnabled) {
      finalRender.push(<DummyCardForUpgradePlan />);
    }
    console.log("selectedQB ====>", selectedQB);
    if(orgProgrammes && orgProgrammes.length && selectedQB && selectedQB.qb_code === "school" && !selectedOrgProgramme) {
      if(loading) {
        finalRender.push(<Spinner />)
      } else {
        orgProgrammes.map((op) => {
          console.log("ps =======>", op);
          if(op.grades.length) {
            finalRender.push(<Col className="card-header-title" span={24}><Space direction="vertical">{op.name}</Space></Col>)
          }
          op.grades && op.grades.length && op.grades.map((grade) => {
            let cardRender = <Col xs={24} sm={12} md={8} lg={6}>
                <Card className="text-center cursor-pointer clickable-card subject-card" style={{marginBottom:"15px",display:"flex",justifyContent:"center",alignItems:"center"}} onClick={() => {
                  getOrgProgrammeSubjects(op, grade)
                }}>
                  <Space direction="vertical">
                    <Avatar src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconMap[grade.name]}`} className={!subjectIconMap[grade.name] && "default-logo-button"}>{!subjectIconMap[grade.label] && grade.label.charAt(0)}</Avatar>
                    <h2 className="p-t-10">{grade.label}</h2>
                    {/* {experiencesCount && <span style={{color: "#aaa"}}>{experiencesCount} {mrIntl("LibraryPage.assessments")}</span>} */}
                  </Space>
                </Card>
              </Col>
              finalRender.push(cardRender)
          }) 
        })
      }
    }

    if(selectedQB && selectedQB.qb_code === "school" && selectedOrgProgramme) {
      if(loading) {
        finalRender.push(<Spinner />)
      } else {
        orgSubjects.map((os) => {
          console.log("os =======>", os);
          if(os.children.length) {
            finalRender.push(<Col className="card-header-title" span={24}><Space direction="vertical">{os.name}</Space></Col>)
          }
          os.children && os.children.length && os.children.map((subject) => {
            let cardRender = <Col xs={24} sm={12} md={8} lg={6}>
                <Card className="text-center cursor-pointer clickable-card subject-card" style={{marginBottom:"15px",display:"flex",justifyContent:"center",alignItems:"center"}} onClick={() => {
                  openSelectedSubject(subject.id, selectedQB)
                }}>
                  <Space direction="vertical">
                    <Avatar src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconMap[subject.name?.toLowerCase()]}`} className={!subjectIconMap[subject.name?.toLowerCase()] && "default-logo-button"}>{!subjectIconMap[subject.name?.toLowerCase()] && subject.name?.charAt(0)}</Avatar>
                    <h2 className="p-t-10">{subject.name}</h2>
                    {/* {experiencesCount && <span style={{color: "#aaa"}}>{experiencesCount} {mrIntl("LibraryPage.assessments")}</span>} */}
                  </Space>
                </Card>
              </Col>
              finalRender.push(cardRender)
          }) 
        })
      }
    }

    if(selectedQB && selectedQB.qb_code !== "school" && programmeSubjects){
      if(loading) {
        finalRender.push(<Spinner />)
      } else {
        programmeSubjects.forEach(subjectGroup => {
          subjectGroup.children_attributes.forEach(subject => {
            let experiencesCount = !_.isEmpty(subject.qps_resource_counts) && subject.qps_resource_counts.experiences.total_published
            const subjectIconFileName = subjectIconMap?.[subject?.name?.toLowerCase()];
            const icon = subject?.custom_fields?.icon;
            if(experiencesCount > 0){
              let cardRender = <Col xs={24} sm={12} md={8} lg={6}>
                <Card className="text-center cursor-pointer clickable-card subject-card" style={{"marginBottom":"15px"}} onClick={() => {
                  openSelectedSubject(subject.id, selectedQB)
                }}>
                  <Space direction="vertical">
                    {/* <img alt="icon" src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconMap[subject.name]}`} style={{width: "40px"}}></img> */}
                    <Avatar src={icon ||`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconFileName}`} className={icon ? "subject-card-icon" : !subjectIconFileName && "default-logo-button"} >{!subjectIconFileName && subject.name.charAt(0)}</Avatar>
                    <h2 className="p-t-10">{subject.name}</h2>
                    {experiencesCount && <span style={{color: "#aaa"}}>{experiencesCount} {mrIntl("LibraryPage.assessments")}</span>}
                    {/* {!_.isEmpty(subject.qps_resource_counts) && <span style={{color: "#aaa"}}>{subject.qps_resource_counts.segments.total_published} Items</span>} */}
                  </Space>
                </Card>
              </Col>
              finalRender.push(cardRender)
            }
          });
        });
      }
    }

    const breadcrumbItems = [{
      title: <Button type="text" style={{background: "none"}}>{selectedQB?.custom_fields?.title}</Button>,
    }]
    if(selectedOrgProgramme) {
      breadcrumbItems.push({
        title: <Button type="text" style={{background: "none"}}>{selectedOrgProgramme?.name}</Button>,
      })
    }
    if(selectedGrade) {
      breadcrumbItems.push({
        title: <Button type="text" style={{background: "none"}}>{selectedGrade?.label}</Button>,
      })
    }

    return (
      <>
        {selectedQB && (
          <Row className="library-breadcrumb-wrapper" align="middle">
            <Col span={1} >
              <Button
                type="text"
                icon={<LeftOutlined />}
                onClick={() => {
                  setSelectedOrgProgramme(null);
                  if (selectedGrade) {
                    setSelectedGrade(null);
                  } else if (selectedQB && !selectedGrade) {
                    setSelectedQB(null);
                  }
                }}
              >
                {mrIntl("LibraryPage.back")}
              </Button>
            </Col>
            {"|"}
            <Col span={showStudentPerformanceExport ? 18 : 22}>
              <Breadcrumb
                separator=">"
                items={breadcrumbItems}
              />
              {/* <Breadcrumb separator=" ">
                  <Breadcrumb.Item>
                    <Button type="text" icon={<LeftOutlined />} onClick={() => { setSelectedQB(null) }}><MrTranslate id={"LibraryPage.back"}/></Button>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{marginTop: "5px"}}>
                    {selectedQB.custom_fields.title}
                  </Breadcrumb.Item>
                  </Breadcrumb> 
              */}
            </Col>
            {shouldShowLibraryActions && (
              <Col span={4} className="library-actions-container">
                <LibraryActions selectedQB={selectedQB} />
              </Col>
            )}
          </Row>
        )}
        <Row gutter={16} className={"library-grid"}>
          {finalRender}
        </Row>
      </>
    );
  }

  return <React.Fragment>

    {isQbAuthor ? 
    <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
      <TabPane tab= {mrIntl("LibraryPage.all_assessments")} key="tests">
        {/* <DPBankAnnouncementAlert /> */}
        <Route path={`${path}/tests`} render={routeProps => <LibraryExperiences intl={intl} mrIntl={mrIntl} isRoute={false} {...routeProps} isLibrary={true} params={{is_library: true}} config={libExperiencesCrudConfig}/>}/>
      </TabPane>

      {canViewResourcesTab && <TabPane tab={mrIntl("LibraryPage.all_resources")} key="resources">
        <Row>
          <Col span={18} className="segments-list">
            {/* <Route path={`${path}/resources`} render={routeProps => <Segments experienceViewMode="apEditTest" isLibrary={true} params={{is_library: true}} isRoute={false} {...routeProps} config={{filter: {show: true, config: {create: {show: false}} }, pagination: {show: true}}}/>}/> */}
            <Route path={`${path}/resources`} render={routeProps => <LibrarySegments experienceViewMode="apEditTest" nestingLevel={1} isLibrary={true} params={{is_library: true, by_original: true, page_size: 20}} isRoute={false} {...routeProps} config={{filter: {show: true, config: {create: {show: false}} }, pagination: {show: true}, list: { widget: SegmentList }}}/>}/>
          </Col>
        </Row>
      </TabPane>}
      
    </Tabs>
    :
    // <Route path={`${path}/tests`} render={routeProps => <LibraryExperiences isRoute={false} {...routeProps} isLibrary={true} params={{is_library: true, by_original: true}} config={libExperiencesCrudConfig}/>}/>
    <Tabs activeKey={activeKey1} onTabClick={handleTabClick1} className="custom-tabs-pills">
      {showExploreLibraryTab && <TabPane tab={<><GlobalOutlined />{mrIntl("LibraryPage.explore")}</>} key="explore">
        <ExploreLibraryGrid />
      </TabPane>}

      <TabPane tab={<><SearchOutlined />{mrIntl("LibraryPage.search")}</>} key="tests">
        {/* <DPBankAnnouncementAlert /> */}
        <Route path={`${path}/tests`} render={routeProps => <LibraryExperiences intl={intl} mrIntl={mrIntl} isRoute={false} {...routeProps} isLibrary={true} params={{is_library: true}} config={libExperiencesCrudConfig} showExperiencePreview={showExperiencePreview}/>}/>
      </TabPane>
    </Tabs>

  }


    
    
  </React.Fragment>
}


LibraryPage.defaultProps = {};

LibraryPage.propTypes = {};

export default LibraryPage;


                