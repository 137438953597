import React, { useState, useEffect } from "react";
import {
  PrinterOutlined,
  DownOutlined,
  UndoOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  StarOutlined,
  StarFilled
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Select, Space, Tooltip } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  buildOptionsArr,
  checkMob,
  displayPoints,
  dropDownTrigger,
  removeHTMLTagsFromText,
  extraTimeValues,
  isGradingWithAIInProgress,
} from "/src/lib/utils/helperMethods";
import { exportLoadingSelector } from "/src/views/Experiences/selector";
import { useDispatch, useSelector } from "react-redux";
import MBIcon from "/src/assets/images/MB-icon-new.png";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import PublishResultButton from "./PublishResultButton";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import GradeSubmission from "./GradeSubmission";

const ExperienceUserActions = (props) => {
  console.log("ExperienceUserActions props ==>", props)
  const {
    buttonText,
    experience,
    currentUser,
    experienceUser,
    gradingConfig,
    exportModalConfig = {},
    setExportModalConfig,
    item,
    closeModal,
    updateResource,
    showPublishButton,
  } = props;

  const exportLoading = useSelector(exportLoadingSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate();
  
  const experienceSettings = experience.settings || {};
  let isAllowStudentUploadFiles = experienceSettings.allow_student_upload_files;
  let isTimedAssessment = experienceSettings.is_timed;
  const customFields = experienceUser.custom_fields || {};
  
  const showMarkAsSeedButton = (enabledFeatures.grade_with_ai_using_seeds || import.meta.env.VITE_MODE === "development") && (currentUser.role === "teacher" || currentUser.role === "admin");

  const showGradeSubmissionWithAIButton = experience.rubric.type_c === "points" && !experienceSettings.peer_review_enabled && (enabledFeatures.grade_submission_with_ai || import.meta.env.VITE_MODE === "development");
  
  const isGradingWithAIInProgressForEU = isGradingWithAIInProgress(experienceUser);

  const dispatch = useDispatch();

  const toggleMarkAsSeed = () => {
    console.log("toggleMarkAsSeed ===>", experienceUser)
    updateResource(
      {
        id: experienceUser.id,
        custom_fields: {
          ...experienceUser.custom_fields,
          is_seed: !experienceUser.custom_fields?.is_seed
        }
      },
      {
        successCallback: () => {
          // handleCloseModal()
          // setLocalCriteriumAssociations([])
        },
        success: {
          showMessage: true,
          message: experienceUser.custom_fields?.is_seed ? mrIntl("ExperienceUserActions.unmarked_as_seed") : mrIntl("ExperienceUserActions.marked_as_seed"),
        },
        error: {
          showMessage: true,
          message: mrIntl("ExperienceUserActions.error_in_marking_as_seed"),
        },
      }
    );
  };

  const showResetConfirm = () => {
    let duration = 0;
    showConfirmModal({
      // title: "Are you sure you want to reset this submission?",
      title: mrIntl("ExperienceUserActions.are_you_sure_you_want_to_reset_this_submission"),
      icon: <ExclamationCircleOutlined />,
      width: isTimedAssessment && 600,
      okText: mrIntl("CommonText.ok"),
      okType: "primary",
      cancelText: mrIntl("CommonText.cancel"),
      content: isTimedAssessment ? (
        <Space>
          {/* Select additional time you would like to grant the student for
          completing the assessment. */}
          {mrIntl("ExperienceUserActions.select_additional_time_for_reset")}
          <Select
            placeholder="Select time"
            // defaultValue={value}
            style={{
              width: 120,
            }}
            onChange={(e) => {
              duration = e;
            }}
            options={buildOptionsArr(extraTimeValues)}
          />
        </Space>
      ) : (
        mrIntl("ExperienceUserActions.this_will_allow_the_student_to_resubmit_this_test")
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          if (isTimedAssessment && duration == 0) {
            // message.error("Please select valid time from the Select time dropdown")
            message.error(mrIntl("ExperienceUserActions.please_select_valid_time_from_dropdown"))
            reject();
          } else {
            let retakeExperienceParams = {
              experienceUser: experienceUser,
              retake_submission: true,
            };
            if (isTimedAssessment) {
              retakeExperienceParams = {
                ...retakeExperienceParams,
                durationAfterReset: parseInt(duration),
              };
            }
            dispatch(experienceUserActions.retakeExperience(retakeExperienceParams, {
              successCallback: () => {
                if (experienceSettings.peer_review_enabled) {
                  dispatch(experienceUserActions.fetch({
                    params: {
                      by_experience_id: experience.id,
                    }
                  }));
                }
              }
            }));
            resolve();
            if (closeModal) return closeModal();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showUploadBtnConfirm = () => {
    showConfirmModal({
      title: "Are you sure you want to allow more uploads from student?",
      icon: <ExclamationCircleOutlined />,
      content: "This will allow the student to upload extra files.",
      onOk() {
        console.log("OK");
        dispatch(
          experienceUserActions.retakeExperience(
            { experienceUser: experienceUser },
            {
              successCallback: (response) => {
                const updatedExperienceUser = {
                  ...experienceUser,
                  uploads_allowed: true,
                };

                dispatch(
                  experienceUserActions.showSuccess(
                    { data: { experience_user: updatedExperienceUser } },
                    { success: { showMessage: false } }
                  )
                );
              },
            }
          )
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showUploadMoreFilesConfirm = () => {
    showConfirmModal({
      centered: true,
      title: `Upload more files for ${experienceUser.name}?`,
      // content: `Are you sure to upload more files for ${item.name}?`,
      onOk: () => console.log("showUploadMoreFilesConfirm ==>"),
    });
  };

  const studentHeaderExtraActionsMenu = (
    <Menu
      onClick={({ domEvent, key }) => {
        // console.log("studentHeaderExtraActionsMenu params", params)
        domEvent.stopPropagation();
      }}
    >
      {/* <Menu.Item key="menu-item-print" title="Print responses" icon={<PrinterOutlined />} onClick={() => { window.open(`/print/experiences_submission/${experienceUser.experience_uuid}/student/${experienceUser.user_id}`, "_blank"); return false}} >
        Print responses
      </Menu.Item> */}
      {["support", "admin", "teacher"].indexOf(currentUser.role) > -1 &&
        <Menu.Item
          key="menu-item-export-to-pdf"
          disabled={(exportModalConfig.exportType === "all_student_submissions" && exportLoading) || gradingConfig.anonymousGradingEnabled || isGradingWithAIInProgressForEU ? true : false}
          icon={<PrinterOutlined />}
          onClick={() => {
            setExportModalConfig({
              ...exportModalConfig,
              showModal: true,
              // modalTitle: `Export submission for ${experienceUser.name}`,
              modalTitle: mrIntl("ExperienceUserActions.export_submission", {user: experienceUser.name,}),
              exportButtonText: mrIntl("ExperienceUserActions.export_to_pdf"),
              exportType: "student_submission",
              exportURLType: "pdf",
              downloadMessage: mrIntl("ExperienceUserActions.download_exported_pdf"),
              timeMessage: mrIntl("ExperienceUserActions.this_will_take_about_30s"),
              delay: 10,
              experienceUser: experienceUser,
            });
            // printStudentSubmission()
          }}
        >
          <Tooltip
            title={
              gradingConfig.anonymousGradingEnabled
                ? mrIntl("ExperienceUserActions.export_disabled_with_anonymous")
                : exportModalConfig.exportType === "all_student_submissions" &&
                  exportLoading
                ? mrIntl("ExperienceUserActions.export_all_in_progress")
                : isGradingWithAIInProgressForEU
                ? mrIntl("CommonText.grading_is_in_progress")
                : mrIntl("ExperienceUserActions.export_responses_to_pdf")
            }
          >
            {mrIntl("ExperienceUserActions.export_to_pdf")}
          </Tooltip>
        </Menu.Item>
      }

      {(experience.is_mb_linked && 
      ["support", "admin", "teacher"].indexOf(currentUser.role) > -1 &&
      !experienceUser.dup_from_id &&
        <Menu.Item
          key="menu-item-send-to-managebac"
          disabled={
            (exportModalConfig.exportType === "all_student_submissions" &&
              exportLoading) ||
            gradingConfig.anonymousGradingEnabled || isGradingWithAIInProgressForEU
          }
          // icon={<SendOutlined />}
          onClick={() => {
            setExportModalConfig({
              ...exportModalConfig,
              showModal: true,
              // modalTitle: `Send submission (grades, comments and PDF) to MB gradebook for ${experienceUser.name}`,
              modalTitle: mrIntl("ExperienceUserActions.send_submission_to_mb_gradebook", {experienceUserName: experienceUser.name}),
              exportButtonText: mrIntl("ExperienceUserActions.send_submission"),
              exportType: "student_submission",
              exportURLType: "pdf",
              downloadMessage: mrIntl("CommonText.download_pdf"),
              timeMessage: mrIntl("ExperienceUserActions.this_will_take_about_30s"),
              delay: 10,
              experienceUser: experienceUser,
              postToMb: true
            });
            // printStudentSubmission()
          }}
        >
          <Tooltip
            title={
              gradingConfig.anonymousGradingEnabled
                ? mrIntl(
                    "ExperienceUserActions.sending_disabled_with_anonymous_grading"
                  )
                : exportModalConfig.exportType === "all_student_submissions" &&
                  exportLoading
                ? mrIntl(
                    "ExperienceUserActions.export_all_submissions_in_progress"
                  )
                : isGradingWithAIInProgressForEU
                ? mrIntl("CommonText.grading_is_in_progress")
                : mrIntl("ExperienceUserActions.send_to_mb")
            }
          >
            <img
              alt={mrIntl("ExperienceUserActions.mb_logo")}
              style={{
                width: "16px",
                // marginTop: "-3px",
                marginLeft: "-2px",
                marginRight: "2px",
                verticalAlign: "middle"
              }}
              src={MBIcon}
            ></img>{" "}
            {mrIntl("ExperienceUserActions.send_to_managebac")}
          </Tooltip>
        </Menu.Item>
      )}

      { !experienceUser.dup_from_id && // Allowing only original submissions to reset not reviewers
        (["support", "admin"].indexOf(currentUser.role) > -1 ||
          (currentUser.role === "teacher" &&
            currentUser.permission.experience_user &&
            currentUser.permission.experience_user.reset === true)) && (
            <Menu.Item
              key="menu-item-reset-submission"
              // title={
              //   experience.status === "closed"
              //     ? mrIntl("ExperienceUserActions.published_before_reset_submission_msg")
              //     : mrIntl("CommonText.reset_submission")
              // }
              icon={<UndoOutlined />}
              onClick={() => {
                showResetConfirm();
              }}
              disabled={experience.status === "closed" || experienceUser.marked || isGradingWithAIInProgressForEU}
            >
              <Tooltip
                title={
                  experience.status === "closed"
                    ? mrIntl("ExperienceUserActions.published_before_reset_submission_msg")
                    : experienceUser.marked
                    ? mrIntl("ExperienceUserActions.unpublished_grades_to_reset_submission")
                    : isGradingWithAIInProgressForEU
                    ? mrIntl("CommonText.grading_is_in_progress")
                    : mrIntl("CommonText.reset_submission")
                }
              >
                {mrIntl("CommonText.reset_submission")}
              </Tooltip>
            </Menu.Item>
        )}
      {/* {!experienceUser.marked &&
        (currentUser.role === "admin" ||
          (currentUser.role === "teacher" &&
            currentUser.permission.experience_user &&
            currentUser.permission.experience_user.reset === true)) &&
        isAllowStudentUploadFiles && (
          <Menu.Item
            key="menu-item-reopen-uploads"
            title={
              experience.status === "closed"
                ? "Change assessment to Published and then reopen uploads"
                : "Reopen uploads"
            }
            icon={<UndoOutlined />}
            onClick={() => {
              showUploadBtnConfirm();
            }}
            disabled={experience.status === "closed"}
          >
            Reopen uploads
          </Menu.Item>
        )} */}
      {/* {!experienceUser.marked &&
        (currentUser.role === "admin" || currentUser.role === "teacher") &&
        isAllowStudentUploadFiles && (
          <>
            <Menu.Item
              key="menu-item-upload-files"
              title="Upload more files"
              // icon={<UploadOutlined />}
            >
              <ViewUploadsModal
                buttonText="Upload files"
                type="text"
                item={experienceUser}
                icon={<UploadOutlined />}
              />
            </Menu.Item>
          </>
        )} */}
      {showMarkAsSeedButton ? (
        <Menu.Item
          key="menu-item-mark-seed"
          icon={experienceUser.custom_fields?.is_seed ? <StarFilled /> : <StarOutlined />}
          onClick={() => {
            toggleMarkAsSeed();
          }}
          disabled={isGradingWithAIInProgressForEU}
          // disabled={experience.status === "closed" || experienceUser.marked}
        >
          <Tooltip
            title={
              experienceUser.custom_fields?.is_seed
                ? mrIntl("ExperienceUserActions.unmark_as_seed")
                : isGradingWithAIInProgressForEU
                ? mrIntl("CommonText.grading_is_in_progress")
                : mrIntl("ExperienceUserActions.mark_as_seed")
            }
          >
            {experienceUser.custom_fields?.is_seed
              ? mrIntl("ExperienceUserActions.unmark_as_seed")
              : mrIntl("ExperienceUserActions.mark_as_seed")}
          </Tooltip>
        </Menu.Item>
      ) : null}
      {showGradeSubmissionWithAIButton ? (
        <Menu.Item
          key="menu-item-grade-submission"
          className="grade-submission-menu-item"
        >
          <GradeSubmission item={experienceUser} />
        </Menu.Item>
      ) : null}
      {showPublishButton ? (
        <Menu.Item key="menu-item-publish-button">
          <PublishResultButton
            item={experienceUser}
            experience={experience}
            updateResource={updateResource}
            type={"insideDropDown"}
            publishReviewers={experienceUser.dup_from_id ? false : true}
          />
        </Menu.Item>
      ) : null}
    </Menu>
  );

  // Dropdown is disabled when grade all using AI is in progress to prevent concurrent modifications
  return (
    <Dropdown
      overlay={studentHeaderExtraActionsMenu}
      placement="bottomRight"
      trigger={[dropDownTrigger]}
    >
      <Button
        type="text"
        size={"small"}
        ghost={false}
        // style={{ padding: "0px" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="experience-user-action-button"
      >
        {/* More  */}
        {buttonText}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ExperienceUserActions;
