import React, { useState } from "react";
import { Modal, Alert, Typography, Button, theme } from "antd";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { activeAdjustedExperienceSelector } from "../../../selector";
import { experiencePausedAtSelector, experiencePausedSelector } from "../../FirestoreInteractions/selector";
import { useDispatch, useSelector } from "react-redux";
import { appRegionSelector, currentUserSelector } from "/src/views/Auth/Login/selector";
import { realtimeBatchActions } from "../../FirestoreInteractions/firebaseHelper";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import './PauseUnpauseButton.scss';

const PauseUnpauseButton = (props) => {
  const { teacherSubmitLoading, updateRecord, data = [] } = props;
  const experience = useSelector(activeAdjustedExperienceSelector());
  const experiencePaused = useSelector(experiencePausedSelector)
  const experiencePausedAt = useSelector(experiencePausedAtSelector)
  const appRegion = useSelector(appRegionSelector())
  const [openPauseUnpauseConfirmationModal, setOpenPauseUnpauseConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const { token } = theme.useToken();
  const statusPaused = "be paused";
  const statusPause = "pause";
  const statusUnpaused = "unpause";
  const pauseUnpauseLabel = experiencePaused ? statusUnpaused : statusPause;
  const pauseUnpauseStatus = experiencePaused ? statusUnpaused : statusPaused;
  const currentUser = useSelector(currentUserSelector())

  console.log("PauseUnpauseButton ====>", currentUser);
  const handleOk = () => {
    if (data.length === 0) return;
    console.log(
      "PauseUnpauseButton confirmation ====>",
      experience,
      experiencePaused,
      experiencePausedAt
    );
    let experienceInfo = {};
    const currentTime = Date.now();

    if (experiencePaused) {
      experienceInfo.paused = false;
      experienceInfo.unpaused_at = currentTime;
      let finalData = [];
      data.forEach((student) => {
        let docIds = {
          [student.uid]: student.name,
        };
        let valuesToUpdate = {
          unpaused_offset:
            (student.currentView !== "joined"
              ? currentTime - experiencePausedAt
              : 0) + (student.unpaused_offset || 0),
        };
        let objToPush = {
          basePath: `/experiences/${experience.id}/user_info`,
          docIds: docIds,
          tableName: "user_info",
          uuids: [`eId${experience.id}_uId${student.uid}`],
          options: {
            merge: true,
          },
          object: valuesToUpdate,
        };
        finalData.push(objToPush);
      });
      realtimeBatchActions(finalData, {
        successCallback: () => {},
        errorCallback: (error) => {},
        appRegion: appRegion,
      });
    } else {
      experienceInfo.paused = true;
      experienceInfo.paused_at = currentTime;
    }
    dispatch(
      firestoreInteractionActions.setExperienceInfo({
        experienceId: experience.id,
        experienceInfo,
      })
    );

    let message = experiencePaused 
      ? 
      `${currentUser.first_name} unpaused the assessment` 
        : 
        `${currentUser.first_name} paused the assessment`;
    
    setPauseUnPauseLogs(message)
    setOpenPauseUnpauseConfirmationModal(false);
  }


  const setPauseUnPauseLogs = (msg) => {
    dispatch(
      firestoreInteractionActions.setLogs({
        logging: true,
        log_type: "experience",
        category: "actions",
        msg: msg,
        itemId: experience.id,
        experienceId: experience.id,
      })
    );
  }

 

  return (
    <>
      <Modal
        centered={true}
        className="pause-unpause-modal"
        style={{
          '--primary-color': token.colorPrimary,
          '--error-color': token.colorError,
        }}
        open={openPauseUnpauseConfirmationModal}
        width={450}
        onOk={() => handleOk()}
        onCancel={() => {
          setOpenPauseUnpauseConfirmationModal(false);
        }}
        okText={experiencePaused ? mrIntl("PauseUnpauseButton.unpause") : mrIntl("PauseUnpauseButton.pause")}
        okButtonProps={{
          danger: experiencePaused ? false : true,
        }}
      >
        <div className={`pause-unpause-modal-icon ${experiencePaused ? 'unpause-icon' : 'pause-icon'}`}>
          {experiencePaused ? (
            <PlayCircleOutlined />
          ) : (
            <PauseCircleOutlined />
          )}
        </div>

        <Typography.Title
          level={5}
          className="pause-unpause-modal-title"
        >
          {experiencePaused ? mrIntl("PauseUnpauseButton.unpause_the_assessment") : mrIntl("PauseUnpauseButton.pause_the_assessment")}
        </Typography.Title>

        <div className="pause-unpause-modal-content">
          <p className="pause-unpause-modal-content-text">
            {mrIntl("PauseUnpauseButton.this_will_pausetext_the_assessment_for_all_students_who_are", { pauseUnpauseLabel: pauseUnpauseLabel, pauseUnpauseStatus: pauseUnpauseStatus })}
          </p>

          <Alert
            message={
              experiencePaused
                ?
                mrIntl("PauseUnpauseButton.for_timed_assessments_their_countdown_timer_continues_from_where_it")
                :
                mrIntl("PauseUnpauseButton.for_timed_assessments_this_also_pauses_their_countdown_timer_until")
            }
            className="pause-unpause-modal-info-box"
            type={experiencePaused ? "info" : "error"}
            showIcon={true}
            icon={<ClockCircleOutlined className="pause-unpause-modal-info-box-icon" />}
          />
        </div>
      </Modal>

      <Button
        disabled={data.length === 0}
        type={"primary"}
        ghost
        onClick={() => setOpenPauseUnpauseConfirmationModal(true)}
        icon={experiencePaused ? <PlayCircleOutlined /> : <PauseOutlined />}
      >
        {experiencePaused ? mrIntl("PauseUnpauseButton.unpause_test") : mrIntl("PauseUnpauseButton.pause_test")}
      </Button>
    </>
  );
};

export default PauseUnpauseButton;
