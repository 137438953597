import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Tooltip, Alert, Space, Result } from "antd";
import { FontColorsOutlined, SendOutlined } from "@ant-design/icons";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import {
  checkIPAD,
  getTimeRemainingToTargetTime,
  checkIfSEBConfigIsValid,
  checkMob,
  checkOffsetInterval,
  currentTimeValidWrtServerTime,
  getFromLS,
  getPermissions,
  getServerTimeOffset, 
  timeInMiliSeconds,
  setUserInfoOnBeforeEndSession,
  setToLS,
} from "/src/lib/utils/helperMethods";
import {
  currentViewSelector,
  extraTimeOffsetSelector,
  extraTimeSelector,
  focusLostStatusSelector,
  getFirebaseTokenLoadingSelector,
  isFirebaseAuthenticatedSelector,
  mobileUploadModeSelector,
  securitySelector,
  startedAtSelector,
  experienceInfoSelector,
  focusLostCountSelector,
  sessionIdSelector,
  durationAfterResetSelector,
  allowedInBrowserSelector,
  publishedAtSelector,
  firestoreConnectionClosedSelector,
  currentSessionIdSelector,
  sessionSwitchedSelector,
  spellCheckEnabledSelector,
  calcEnabledSelector,
  sttAllowedSelector,
  signInToFirestoreErrorSelector,
  graphingCalcEnabledSelector,
  experienceUnpausedAtSelector,
  unpausedOffsetUserInfoSelector,
  experiencePausedAtSelector,
  experiencePausedSelector,
  requestResumeSessionIdSelector,
  uploadSessionIdSelector,
  enableLocalStorageSyncSelector,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFullScreenHandle } from "react-full-screen";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { firestoreFieldValue } from "/src/config/initializers";
import ProgressBar from "/src/views/Experiences/ExperienceShow/Components/ProgressBar";
import CountDownTimer from "/src/views/Experiences/ExperienceShow/Components/CountDownTimer";
import FocusLostModal from "/src/views/Experiences/ExperienceShow/Components/FocusLostModal";
import StartView from "./StartView/StartView";
import EndView from "/src/views/Experiences/ExperienceShow/ExperienceTake/EndView/EndView";
import SubmitView from "./SubmitView/SubmitView";
import ResultView from "./ResultView/ResultView";
import ResumeView from "/src/views/Experiences/ExperienceShow/ExperienceTake/ResumeView/ResumeView";
import JoinView from "/src/views/Experiences/ExperienceShow/ExperienceTake/JoinView/JoinView";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import FirestoreInteractions from "../FirestoreInteractions/FirestoreInteractions";
import { appTypeSelector, appValidSelector, changeSecurityLoadingSelector, changeSecurityStatusSelector, getSecurityStatusSelector} from "/src/App/OfflineApp/offlineAppSelectors";
import screenfull from 'screenfull';
import { experienceConfigTakeExperienceSelector, experienceTypeSelector, getOfflineDBStatusSelector, getServerTimeOffsetSelector, switchFromStartToResumeViewSelector, skipFocusLostModalSelector } from "/src/views/Experiences/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import TakeScreenshots from "/src/components/UI/TakeScreenshots";
import dayjs from "dayjs";
import loadable from "@loadable/component";
import { Redirect } from "react-router-dom";
import ToolsSidebar from "../Components/ToolsSidebar";
import { showNotification } from "/src/components/UI/Segment/UIHelper";
import PauseView from "./PauseView/PauseView";
import InvalidTimeAlert from "../Components/InvalidTimeAlert";
import EndButton from "../Components/EndButton";
import ExternalVideoCallButton from "../Components/ExternalVideoCallButton";
import OfflineExperienceAndNotAllowedOnlineWarning from "../Components/OfflineExperienceAndNotAllowedOnlineWarning";
import NotAllowedAlert from "../Components/NotAllowedAlert";
import { usersSelector } from "/src/views/Users/selector";
import { usePrevious } from "/src/views/Segments/InteractiveHelpers";
import { SolarClockCircleLinear } from "/src/components/UI/Icons/SolarIcons";
import { CheckAndChangeSecurity } from "/src/App/OfflineApp/CheckAndChangeSecurity";
import { checkIfSEBHasDynamicConfig, getAppVersion, getWhiteListUrls, isNewAppVersion, macAppsToClose, supportsMacAACSecurity } from "/src/App/OfflineApp/offlineAppHelper";
import { getOrCreateSessionId } from "../FirestoreInteractions/redux";
import LocalStorageSyncView from "./LocalStorageSyncView/LocalStorageSyncView";

const grammarlyDisableCSS = loadable.lib(() => import('/src/App/GrammarlyDisable.scss'))

const ExperienceTake = (props) => {
  const { experienceProps } = props;

  const {
    experience,
    experienceSettings,
    embedded,
    experienceViewMode,
    setUserInfo,
    fromResourceName, 
    fromUrl
  } = experienceProps;

  console.log(
    "userInfoFromReduxsecurity",
    experienceSettings.mode,
    !embedded,
    experienceViewMode
  );
  console.log("window.desktopApp", window.desktopApp);
  console.log("ExperienceTake props", props, experienceProps);

  // const isMobile = checkMob();
  // const isIPAD = experienceSettings.mode != "offline" && checkIPAD();
  let isTab = experienceSettings.mode != "offline" && checkIPAD();
  let isMobile = checkMob();

  if(isTab) {
    isMobile = false
  }
  
  // if (import.meta.env.VITE_MODE != 'production') {
  //   isMobile = checkScreen('mobile') 
  //   isTab = (!checkScreen('mobile') && checkScreen('tab')) || (experienceSettings.mode != "offline" && checkIPAD());
  // }
 
  const location = useLocation();
  const dispatch = useDispatch();
  const mainTestScreen = useFullScreenHandle();

  console.log('location inside take==>', location);

  const currentUser = useSelector(currentUserSelector());
  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  const signInToFirestoreError = useSelector(signInToFirestoreErrorSelector);
  const userInfoFromReduxsecurity = useSelector(securitySelector);
  const currentViewFromUserInfo = useSelector(currentViewSelector);
  const userInfoFromReduxEnableLocalStorageSync = useSelector(enableLocalStorageSyncSelector);
  const userInfoFromReduxstartedAt = useSelector(startedAtSelector);
  const userInfoFromReduxextraTime = useSelector(extraTimeSelector);
  const userInfoFromReduxextraTimeOffset = useSelector(extraTimeOffsetSelector);
  const durationAfterReset = useSelector(durationAfterResetSelector)
  const userInfoFromReduxmobileUploadMode = useSelector(mobileUploadModeSelector);
  const userInfoFromReduxFocusLostCount = useSelector(focusLostCountSelector);
  const allowedInBrowser = useSelector(allowedInBrowserSelector);
  const calcEnabledFromUserInfo = useSelector(calcEnabledSelector()) ?? undefined; // For Firestore default is undefined and for supabase default is null
  const graphingCalcEnabledFromUserInfo = useSelector(graphingCalcEnabledSelector()) ?? undefined;
  const spellCheckEnabledFromUserInfo = useSelector(spellCheckEnabledSelector()) ?? undefined;
  const sttCheckEnabledFromUserInfo = useSelector(sttAllowedSelector) ?? undefined;  
  const serverTimeOffset = useSelector(getServerTimeOffsetSelector())
  const appRegion = useSelector(appRegionSelector());
  const appType = useSelector(appTypeSelector());
  const isFirebaseTokenLoading = useSelector(getFirebaseTokenLoadingSelector);
  const userInfoFromReduxFocusLostStatus = useSelector(focusLostStatusSelector);
  const offlineDBStatus = useSelector(getOfflineDBStatusSelector());
  const switchFromStartToResumeView = useSelector(switchFromStartToResumeViewSelector())
  const skipFocusLostModal = useSelector(skipFocusLostModalSelector())
  const experienceType = useSelector(experienceTypeSelector())
  const experienceConfigTakeFromRedux = useSelector(experienceConfigTakeExperienceSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const changeSecurityStatus = useSelector(changeSecurityStatusSelector());
  const changeSecurityLoading = useSelector(changeSecurityLoadingSelector());
  const experienceInfo = useSelector(experienceInfoSelector);
  const publishedAt = useSelector(publishedAtSelector);
  const firestoreConnectionClosed = useSelector(firestoreConnectionClosedSelector())
  const userInfoFromReduxUploadSessionId = useSelector(uploadSessionIdSelector)
  const userInfoFromReduxRequestResumeSessionId = useSelector(requestResumeSessionIdSelector);
  const currentSessionIdFromRedux = useSelector(currentSessionIdSelector);

  const [showQRModal, setShowQRModal] = useState(false);
  const [mobileUploadMode, setMobileUploadMode] = useState(null);
  const [focusLostModalVisible, setFocusLostModalVisible] = useState(false);
  const [resumeModalVisible, setResumeModalVisible] = useState(false);
  const [showStudentCodeAckModal, setShowStudentCodeAckModal] = useState(false);
  const [focusLostExplanation, setFocusLostExplanation] = useState("");
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isUserInfoInRedux, setIsUserInfoInRedux] = useState(false);

  const currentSystemAppVersion = getAppVersion(appType)
  const mrIntl = useTranslate();
  // const [
  //   switchFromStartToResumeView,
  //   setSwitchFromStartToResumeView,
  // ] = useState(true);
  const [backToTestButtonLoading, setBackToTestButtonLoading] = useState(false);
  const userInfoFromReduxUnpausedAt = useSelector(experienceUnpausedAtSelector)
  const userInfoFromReduxUnpausedOffset = useSelector(unpausedOffsetUserInfoSelector)
  const pausedAt = useSelector(experiencePausedAtSelector)
  const experiencePaused = useSelector(experiencePausedSelector)
  const userInfoFromReduxSessionId = useSelector(sessionIdSelector)
  const prevCalcEnabled = usePrevious(calcEnabledFromUserInfo);
  const prevGraphingcalcEnabled = usePrevious(graphingCalcEnabledFromUserInfo);
  const prevSpellcheckEnabled = usePrevious(spellCheckEnabledFromUserInfo);
  const prevSttEnabled = usePrevious(sttCheckEnabledFromUserInfo);

  const safeExamBrowserObject = window.SafeExamBrowser || {};
  const sebSecurity = safeExamBrowserObject.security || {}
  const configKeyFromJSAPI = sebSecurity.configKey;

  const locationQueryParams = queryString.parse(location.search);
  const lockdownConfig = experienceSettings.lockdown_config || {};
  const sebConfigKeys = lockdownConfig.seb_config_keys || [];


  const isOfflineStudentModeWithDynamicConfig =
    experienceSettings.mode == "offline" &&
    currentUser.role == "student"
    // && locationQueryParams.appLogin;

  const isDynamicLockdownConfigForMacApp =
    appType === "mac" && isOfflineStudentModeWithDynamicConfig;

  const isDynamicLockdownConfigForiPadApp =
    enabledFeatures.dynamic_lockdown_config_for_ipad &&
    appType === "ios" &&
    isOfflineStudentModeWithDynamicConfig;

  const whitelistUrls = getWhiteListUrls(experienceSettings)

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // TODO: Config key from JSAPI and SEB config key are not matching because we are generating a dynamic    config, seb_config_key is a static value coming from the backend.
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {validKey, hashedKeys} = 
    appType === "seb" && 
    checkIfSEBConfigIsValid(configKeyFromJSAPI, sebConfigKeys)
  
  const isInvalidSEBConfig = 
    appType === "seb" && 
    !checkIfSEBHasDynamicConfig(appType) && 
    !validKey ;

  let isStudentSideOfflineModeAACSupportedMacApp =
    appType == "mac" &&
    experienceSettings.mode == "offline" &&
    currentUser.role == "student" &&
    experienceViewMode === "apTakeTest" &&
    supportsMacAACSecurity(appType);

  let shouldSecurityInterruptedEventCheck =
    isStudentSideOfflineModeAACSupportedMacApp &&
    !isNewAppVersion(currentSystemAppVersion, "10.2.0");

     //!isNewAppVersion(currentSystemAppVersion, "10.2.0") => Lower version of app < 10.2.0 with not supported AAC will quit the app automatically when security interrupted. Because we did not handled native Alert or Method to quit the app but now v10.2.0, We will showing the alert with quit button whenever security was interrupted.

  const handleFocusLostEventMac = (e) => {
    console.log("focusLost e", e);
    //APL-2478
    if (currentViewFromUserInfo != "resumeTest") {
      setUserInfo(
        {
          currentView: "resumeTest",
        },
        {
          log: {
            logging: true,
            action: "focus_lost_in_mac",
          },
        }
      );
    }
  }
  console.log("experience Settings=====>>", experienceSettings);

  useEffect(() => {
    grammarlyDisableCSS.load()
  }, []);

  // useEffect(() => {
  //   if (experiencePaused !== undefined) {
  //    if(experiencePaused === false) {
  //     let userInfo = {
  //       unpaused_offset: currentViewFromUserInfo !== "joined" ? (Date.now() - pausedAt) + (userInfoFromReduxUnpausedOffset || 0) : 0
  //     }
  //     setUserInfo(userInfo)
  //    }
  //   }
  // }, [experiencePaused])

  // useEffect(() => {
  //   if (experiencePaused !== undefined) {
  //     let userInfo = {}
  //     let logsAttrs = {
  //       logging: true,
  //     }
  //     if (experiencePaused && !pauseTest) {
  //       userInfo = {
  //         ...userInfo,
  //         paused_at: new Date().getTime(),
  //         pauseTest: true
  //       }
  //       logsAttrs = {
  //         ...logsAttrs,
  //         action: "paused_test"
  //       }
  //     } else if (pauseTest && !experiencePaused) {
  //       userInfo = {
  //         ...userInfo,
  //         pauseTest: false,
  //         unpaused_at: new Date().getTime(),
  //         unpaused_offset: currentViewFromUserInfo !== "joined" ? (Date.now() - pausedAt) + (userInfoFromReduxUnpausedOffset || 0) : 0
  //       }
  //       logsAttrs = {
  //         ...logsAttrs,
  //         action: "resume_test"
  //       }
  //     }
  //     if((experiencePaused && !pauseTest) || (!experiencePaused && pauseTest)) {
  //       setUserInfo(userInfo, {
  //         log: logsAttrs
  //       })
  //     }
  //   }
  // }, [experiencePaused])

  useEffect(() => {
    if (currentViewFromUserInfo && currentViewFromUserInfo !== "joined") {
      // dispatch(experienceReduxActions.show({ id: experience.uid, with_topics: true }));

      dispatch(experienceReduxActions.topicsAndSegmentsFetch({ id: experience.id }));
    }
  }, [currentViewFromUserInfo]);

  useEffect(() => {
    // mobileUploadMode

    // DONT REMOVE
    // FULL SCREEN and FOCUS LOST
    // console.log("addEventListener blur", tempMobileUploadMode, embedded, experienceViewMode, experience.settings.mode, currentViewFromUserInfo)
    // if(!embedded && !isMobile && experienceViewMode == "apTakeTest" && experience.settings.mode == "online_secure" ){
    //   window.addEventListener("blur", blurFunction, {capture: true});
    //   window.addEventListener("focus", focusFunction, {capture: true});
    // }
    // FULL SCREEN and FOCUS LOST

    if (appType === "ios"){
      dispatch(offlineAppActions.executeMethod({key: "clearClipboard", value: "true"}))
    }
    if (
      // !(isMobile && !((currentUser.org_id == 948 || currentUser.org_id == 99) && isScreenSizeSupported)) &&
      !isMobile &&
      !embedded &&
      experienceViewMode == "apTakeTest" &&
      (experienceSettings.mode == "online_secure" ||
        (experienceSettings.mode == "offline" && appType == "web")) &&
      !mobileUploadMode
    ) {

      if (!document.hasFocus()) {
        setFocusLostExplanation("");
        setFocusLostModalVisible(true);
        setResumeModalVisible(false);
      } else {
        setTimeout(() => {
          if (screenfull.isEnabled && false) {
            console.log(
              "focus lost nahi hua==>",
              screenfull.isEnabled,
              screenfull.isFullscreen,
              resumeModalVisible
            );
            if (screenfull.isFullscreen) {
              setResumeModalVisible(false);
            } else {
              setResumeModalVisible(true);
            }
          }
        }, 1000);
      }
    }

    console.log(
      "isMobile, mobileUploadMode ==>",
      isMobile,
      location.search.indexOf("mobileUploadMode")
    );
    if (
      (isMobile || isTab) &&
      location.search.indexOf("mobileUploadMode") > -1
    ) {
      // if(location.search.indexOf("mobileUploadMode") > -1){
      setMobileUploadMode(true); // get from params and true only if isMobile also true
    } else {
      setMobileUploadMode(false);
    }
    // mobileUploadMode END

    // if user account type guest, then show this modal by default on start test to note down their s_code
    //  console.log("currentUser.custom_fields", currentUser.custom_fields, currentViewFromUserInfo)
    if (currentUser && currentUser.custom_fields.account_type == "guest") {
      setShowStudentCodeAckModal(true);
    }

    getServerTimeOffset(setServerTimeCallback); // still need this here for iframes and guests - TODO: can make condiitonal if we feel too many requests

    if (appType == "web" || experienceSettings.mode !== "offline"){ // for web tests and app tests that are without security
      checkOffsetInterval("on", setServerTimeCallback) // TODO: not limiting to scheduled tests only - confirm
    }

    //TODO Calling from mac to logout feature
    // if(appType == "mac"){
    //   window.addEventListener("executeSessionLogout", (e) => {
    //     console.log("call executeSessionLogout", e.detail);
    //     dispatch(loginActions.logout())
    //   })
    // }

    // if (
    //   appType == "mac" &&
    //   experienceSettings.mode == "offline" &&
    //   currentUser.role == "student" 
    //   && !supportsMacAACSecurity(appType)
    // ) {
    //   dispatch(
    //     offlineAppActions.executeMethod([
    //       { key: "changeSecurity", value: true },
    //       { key: "clearClipboard", value: true },
    //       { key: "closeAllApps", value: macAppsToClose },
    //       { key: "setWhitelistedUrls", value: whitelistUrls },
    //     ])
    //   );
    // }

    return () => {
      if (appType == "web" || experienceSettings.mode !== "offline"){ // for web tests and app tests that are without security
        checkOffsetInterval("off", setServerTimeCallback)
      }
    }
  }, []);

  useEffect(() => {
    if (userInfoFromReduxFocusLostStatus) {
      setFocusLostExplanation("");
      setFocusLostModalVisible(true);
      setResumeModalVisible(false);
    }
  }, [userInfoFromReduxFocusLostStatus]);

  useEffect(() => {
    if (
      appType == "mac" &&
      experienceSettings.mode == "offline" &&
      currentUser.role == "student" && 
      !supportsMacAACSecurity(appType)
    ) {
      // removing event listener for AAC (v10 onwards)
      window.addEventListener("AssessPrepFocusLost", handleFocusLostEventMac);
    }

    return () => {
      window.removeEventListener("AssessPrepFocusLost", handleFocusLostEventMac);
    };
  }, [handleFocusLostEventMac])

  //When security is interrupted, Resume/Turning on the session again from native side and then give 2 buttons.Resume and Quit. 
  //When security is interrupted, calling ==> handleSetAACSecurityInterruptedLog => save the logs
  //Native side give 2 buttons.  => save the logs
  //Resume test =>  handleSetSecurityResumeLog  => save the logs
  //Resume and Quit => handleSetNativeQuitLog  => save the logs

  useEffect(() => {
    if (
      isStudentSideOfflineModeAACSupportedMacApp &&
      isNewAppVersion(currentSystemAppVersion, "10.2.0") // supportsMacAACSecurity => 10.2.0 onward apps supported the AAC apple security mode
    ) {
      // removing event listener for AAC (v10 onwards)
      window.addEventListener(
        "SetAACSecurityInterruptedLog",
        handleSetAACSecurityInterruptedLog
      );

      window.addEventListener(
        "SetSecurityResumeLog",
        handleSetSecurityResumeLog
      );

      window.addEventListener("SetNativeQuitLog", handleSetNativeQuitLog);

    }

    return () => {
      window.removeEventListener(
        "SetAACSecurityInterruptedLog",
        handleSetAACSecurityInterruptedLog
      );
      window.removeEventListener(
        "SetSecurityResumeLog",
        handleSetSecurityResumeLog
      );
      window.removeEventListener(
        "SetNativeQuitLog",
        handleSetNativeQuitLog
      );
    };
  }, []);
  
  useEffect(() => {
    if (
      shouldSecurityInterruptedEventCheck
      // Check Mac and iOS appType from there supportsMacAACSecurity function and now handling security interrupted to both mac and iOS app type
    ) {
      window.addEventListener(
        "AACSecurityInterrupted",
        handleAACSecurityInterrupted
      );
    }

    return () => {
      window.removeEventListener(
        "AACSecurityInterrupted",
        handleAACSecurityInterrupted
      );
    };
  }, [shouldSecurityInterruptedEventCheck]);

  useEffect(() => {
    if (
      (appType === "ios" &&
        isNewAppVersion(currentSystemAppVersion, "4.0.0")) || (appType === "mac" &&
          isNewAppVersion(currentSystemAppVersion, "12.0.0")) //iPad 4.0.0 supported native session end and mac v12 native quit 
    ) {
      window.addEventListener("onBeforeEndSession", handleOnBeforeEndSession);
    }

    return () => {
      window.removeEventListener(
        "onBeforeEndSession",
        handleOnBeforeEndSession
      );
    };
  }, [currentViewFromUserInfo]);
  

// useEffect(() => {
//   // don't want to open test if isDynamicLockdownConfigForMacApp and not supported AAC mode
//   if (
//     (isDynamicLockdownConfigForMacApp || isDynamicLockdownConfigForiPadApp) &&
//     supportsDynamicConfig(appType)
//   ) {
//     dispatch(
//       offlineAppActions.executeMethod(
//         [
//           {
//             key: "getSecurityStatus",
//             value: "status",
//             successCallback: (response) => {
//               if (response !== "true") {
//                 dispatch(
//                   offlineAppActions.executeMethod([
//                     { key: "changeSecurity", value: true },
//                     { key: "setWhitelistedUrls", value: whitelistUrls },
//                   ])
//                 );
//               }
//             },
//             errorCallback: () => {
//               showQuitAlertOnNativeSide();
//             },
//           },
//         ],
//         {
//           errorCallback: () => {
//             showQuitAlertOnNativeSide();
//           },
//         }
//       )
//     );
//     // setUserInfo({ 
//     //   closeLaunchInAppWindow: true, 
//     // }, {
//     //   successCallback: () => {
//     //     dispatch(
//     //       offlineAppActions.executeMethod([
//     //         { key: "changeSecurity", value: true },
//     //         { key: "setWhitelistedUrls", value: whitelistedUrls },
//     //       ])
//     //     );
//     //   }
//     // })
//   }
// }, []);


// useEffect(() => {
//   if (
//     (isDynamicLockdownConfigForMacApp || isDynamicLockdownConfigForiPadApp) &&
//     supportsDynamicConfig(appType) &&
//     changeSecurityStatus === "false"
//   ) {
//     console.log("Show error interrupted");
//     // mac app v10.2.0 , Native side we have showQuitAlert function to showing alert with info, Quit app option.
//     // If security could not to be turned on , we have a option to app Quit only if launching app from dynamic config
//     showQuitAlertOnNativeSide();
//   }
// }, [changeSecurityStatus]);

// useEffect(() => {
//   message.error(`call get security status=====>> ${getSecurityStatus}`,10);
// }, [getSecurityStatus]);


const refreshModalDescription = (
  <>
    <p>
      {mrIntl("ExperienceTake.there_are_some_changes_in_the_test_please_refresh_the")}
    </p>
    <Button type="primary" onClick={() => window.location.reload()}>{mrIntl("CommonText.refresh")}</Button>
  </>
);

useEffect(() => {
  if (userInfoFromReduxSessionId) {
    setIsUserInfoInRedux(true);
  }
}, [userInfoFromReduxSessionId]);

useEffect(() => {
  console.log(
    "expe selector==>",
    experienceInfo.published_at,
    publishedAt,
    Date.parse(experience.updated_at)
  );
  // NOTE: when teacher publish exeperience then only show notification (handled at backend), not in case of close submissions or any other action affecting updated_at change
  // 
  // Also showing notification of changing values for calculaor and spellcheck from monitor tab for each student.
  // Using Previous values so that notification won't show on inital mount

  console.log("check updatebooks ==>", {publish: publishedAt > Date.parse(experience.updated_at), calcbool: prevCalcEnabled !== undefined, spellbool: prevSpellcheckEnabled !== undefined });
  
  if ((publishedAt > Date.parse(experience.updated_at) || ((prevCalcEnabled !== calcEnabledFromUserInfo || prevGraphingcalcEnabled !== graphingCalcEnabledFromUserInfo || prevSpellcheckEnabled !== spellCheckEnabledFromUserInfo || prevSttEnabled !== sttCheckEnabledFromUserInfo) && isUserInfoInRedux)) && (currentViewFromUserInfo === "startTest" || currentViewFromUserInfo === "joined")
  ) {
    showNotification("warning", {
      key: "refresh-page",
      message: mrIntl("StartView.refresh_page_msg"),
      description: refreshModalDescription,
      duration: 0,
      top: 65,
      // closeIcon: () => { // making issue it applying globally for all notification
      //   return null
      // },
    });
  }
  return () => {
    showNotification("destroy", "refresh-page")
  }
}, [publishedAt, calcEnabledFromUserInfo, spellCheckEnabledFromUserInfo, sttCheckEnabledFromUserInfo, graphingCalcEnabledFromUserInfo]);

  // Not usefull for mac
  // useEffect(() => {
  //   if (experienceSettings.enable_ap_video_monitoring) {
  //     getPermissions(
  //       { audio: true, video: true },
  //       {
  //         successCallback: () => {
  //           console.log("permission given.");
  //         },
  //         errorCallback: () => {
  //           console.log("some error happened in taking permission.");
  //         },
  //       }
  //     );
  //   }
  // }, []);

  // TODO: check if still needed-  I think its for native mac app logout and login case/ sleep case
  // // MAC app focus lost
  // useEffect(() => {
  //   console.log("window.hasNativeAppLostFocus", window.hasNativeAppLostFocus, appType)
  //   if(window.hasNativeAppLostFocus == true && appType == "mac" && experienceViewMode === "apTakeTest" && experience.settings.mode == "offline"){
  //     setFocusLostModalVisible(true)
  //   }
  // }, [window.hasNativeAppLostFocus])
  // // MAC app focus lost

  // FOCUS LOST
  useEffect(() => {
    if (
      currentViewFromUserInfo == "startTest" ||
      currentViewFromUserInfo == "endTest"
    ) {
      // && !isTab
      if (	
        !isMobile &&	
        !embedded &&	
        experienceViewMode === "apTakeTest" &&	
        (experienceSettings.mode === "online_secure" ||	
          (experienceSettings.mode === "offline" && appType === "web" && allowedInBrowser)) &&	
        !mobileUploadMode
      ) {
        window.addEventListener("blur", blurFunction, { capture: true });
        window.addEventListener("focus", focusFunction, { capture: true });
        if (isTab) {
          window.addEventListener("visibilitychange", blurFunction, {
            capture: true,
          }); // for ipad chrome and safari - tab switching was not triggering blur event - see https://whatwebcando.today/foreground-detection.html - should use this in all cases?
        }
      }
      if (	
        !embedded &&	
        experienceViewMode == "apTakeTest" &&	
        (experienceSettings.mode == "online_secure" ||	
          (experienceSettings.mode == "offline" && appType == "web")) &&	
        !mobileUploadMode	
      ) {
        // Done by uttam
        // window.addEventListener("beforeunload", beforeunloadFunction);
      }

      if (((isMobile || isTab) && appType !== "ios") && userInfoFromReduxmobileUploadMode) {
        // in case refreshed while already in mobileUploadMode
        setMobileUploadMode(userInfoFromReduxmobileUploadMode);
      }
    }
    return () => {
      if (
        currentViewFromUserInfo == "startTest" ||
        currentViewFromUserInfo == "endTest"
      ) {
        // && !isTab
        if (	
          !isMobile &&	
          !embedded &&	
          experienceViewMode == "apTakeTest" &&	
          (experienceSettings.mode == "online_secure" ||	
            (experienceSettings.mode == "offline" && appType == "web")) &&	
          !mobileUploadMode	
        ) {
          window.removeEventListener("blur", blurFunction, { capture: true });
          window.removeEventListener("focus", focusFunction, { capture: true });
          if (isTab) {
            window.removeEventListener("visibilitychange", blurFunction, {
              capture: true,
            });
          }
        }

        if (	
          !embedded &&	
          experienceViewMode == "apTakeTest" &&	
          (experienceSettings.mode == "online_secure" ||	
            (experienceSettings.mode == "offline" && appType == "web")) &&	
          !mobileUploadMode	
        ) {
          // Done by uttam
          // window.removeEventListener("beforeunload", beforeunloadFunction);
        }
      }
    };
  }, [currentViewFromUserInfo, focusLostModalVisible, skipFocusLostModal]);

  useEffect(() => {
    if (
      !embedded &&
      !mobileUploadMode &&
      experienceViewMode == "apTakeTest" &&
      experienceSettings.mode == "online_secure"
    ) {
      if (!screenfull.isFullscreen && false) {
        setResumeModalVisible(true);
        console.log(
          "experienceViewMode",
          experienceViewMode,
        );
      }
    }
  }, [screenfull.isFullscreen])

  const setSwitchFromStartToResumeView = (value) => {
    console.log("experienceReduxActions ==>", experienceReduxActions)
    dispatch(experienceReduxActions.setSwitchFromStartToResumeViewSuccess({switchFromStartToResumeView: value}))
  }

  const setServerTimeCallback = (offset) => {
    console.log("setServerTimeCallback offset", offset);
    dispatch(
      experienceReduxActions.setServerTimeOffsetSuccess({
        serverTimeOffset: offset,
      })
    );
  };  

  const handleAACSecurityInterrupted = (e) => {
    // Not setting false since decided to Quit the app
    // dispatch(
    //   offlineAppActions.executeMethodSuccess({ key: "changeSecurity", response: "false" })
    // );

    if (shouldSecurityInterruptedEventCheck && currentViewFromUserInfo !== "viewFeedback") {
      setUserInfo(
        {},
        {
          log: {
            // logging: experienceProps.log.logging,
            logging: true,
            log_level: "warning",
            // logging: true,
            action: "security_interrupted_in_aac_mode_quitting_app"
          },
        }
      );
      // Decided to quit the app for now - refreshing the page after showing the error was causing issues since changeSecurity only in redux
      setTimeout(() => {
        dispatch(
          offlineAppActions.executeMethod([
            {
              key: "quit",
              value: true,
            },
          ])
        );
      }, 1500); // for the log to be sent
    }
  }
  
  const handleSetAACSecurityInterruptedLog = (e) => {
    // Before the Quit app from native side alert , We will Update the log whenever security interrupted 
    setUserInfo(
      {},
      {
        log: {
          logging: true,
          log_level: "warning",
          action: "security_interrupted_in_aac_mode",
        },
      }
    );
    //Send native error log to sentry
    Sentry.captureMessage(`Security Interrupted appVersion: ${currentSystemAppVersion} error: ${e.detail}`);
  };


  const handleSetSecurityResumeLog = (e) => {
    // On the native side, when a security interruption occurs, an alert message is displayed with the option to 'Resume the session.' Clicking on this option triggers a function to save log information.
    setUserInfo(
      {},
      {
        log: {
          logging: true,
          log_level: "info",
          action: "resuming_after_security_interrupted_in_aac_mode",
        },
      }
    );
    // For mac app v12.0.0 and above, we are not setting the LS securityHasBeenInterrupted to true because in 12 version, the security status is coming correctly
    if(appType === "mac" && !isNewAppVersion(currentSystemAppVersion, "12.0.0")) {
      setToLS("securityHasBeenInterrupted", true)
    }
  };

  const handleSetNativeQuitLog = (e) => {
    // On the native side, in the event of a security interruption, an alert message is presented with the option to 'Quit.' Clicking on this option triggers a function to save the log information.
    setUserInfo(
      {},
      {
        log: {
          logging: true,
          log_level: "info",
          action: "quit_after_security_interrupted_in_aac_mode",
        },
      }
    );
  };
  
  const handleOnBeforeEndSession = (e) => {
    // On the native side, in the event of 'Session End.' taping on this option triggers a function to save the log information.
    let actionFrom = signInToFirestoreError ? "errorInPage" : currentViewFromUserInfo
    setUserInfoOnBeforeEndSession(actionFrom, setUserInfo, appType);
  };

  const showQuitAlertOnNativeSide = (e) => {
    setUserInfo(
      {},
      {
        log: {
          // logging: experienceProps.log.logging,
          logging: true,
          log_level: "warning",
          // logging: true,
          action: "security_could_not_be_turned_on"
        },
    });
    // mac app v10.2.0 , Native side we have showQuitAlert function to showing alert with info, Quit app option.
    // If security could not to be turned on , we have a option to app Quit only 
    dispatch(
      offlineAppActions.executeMethod([
        { 
          key: "showQuitAlert", 
          value: "Security couldn't be turned on, and the test cannot be started. In case this issue continues, please restart your machine and try again." 
        }
      ])
    );
  }

  const reportChange = useCallback(
    (state, handle) => {
      if (handle === mainTestScreen) {
        console.log("Screen 1 went to", state, handle);
        if (state === false) {
          setFocusLostModalVisible(true);
        }
      }
    },
    [mainTestScreen]
  );

  // const handleVisibilityChange = (isVisible) => {
  //   console.log('handleVisibilityChange', isVisible, document.visibilityState, document.hasFocus());
  //   if(isVisible === false){
  //     setFocusLostModalVisible(true)
  //   }
  // }
  var hasUserLeft = false;

  // TODO: Move these function to helper mathods
  const blurFunction = () => {
    console.log(
      "blurFunction",
      document.visibilityState,
      document.hasFocus(),
      currentViewFromUserInfo,
      focusLostModalVisible
    );

    if (!document.hasFocus() && focusLostModalVisible != true && !hasUserLeft && !skipFocusLostModal ) {
      //!skipFocusLostModal => Skipping the focus lost modal when camera permission will show for ImageCaptureUpload modal component, It will skip for only 2 sec,

      // no focus and modal not alrady visible
      setFocusLostExplanation("");
      // mainTestScreen.exit() // enable again when fullscreen fixed
      setFocusLostModalVisible(true);
      setResumeModalVisible(false);
      // setUserInfo({is_focus_lost: true, focus_lost_count: focusLostCount + 1}, {
      let focusLostCount = userInfoFromReduxFocusLostCount;
      setUserInfo(
        {
          is_focus_lost: true,
          focus_lost_at:  Date.now(),
          focus_lost_count:
            appRegion == "china" ? ++focusLostCount : firestoreFieldValue.increment(1),
        },
        {
          log: {
            logging: true,
            action: "focus_lost_on_blur",
          },
        }
      );
    }
  };

  const focusFunction = () => {
    console.log("focusFunction", document.visibilityState, document.hasFocus());
    // TODO: show modal on focus also
  };

  const settingHasUserLeftAfterInterval = () => {
    hasUserLeft = false;
  };

  const beforeunloadFunction = (e) => {
    setTimeout(settingHasUserLeftAfterInterval, 100);
    hasUserLeft = true;
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "Data will be lost if you leave the page, are you sure?";
  };

  // NEED TO FIX THIS;
  let isSetUserInfoLoading = false;

  let finalRender = [];
  // not being used right now but keep - used for dynamically changing securty from monitor
  if (userInfoFromReduxsecurity != undefined) {
    window.desktopApp &&
      window.downloadHelper.changeSecurity(userInfoFromReduxsecurity);
  }

  let videoCallButton = 
    <ExternalVideoCallButton
      videoCallLink={experience.settings.video_call_link}
      experienceSettings={experience.settings}
      showTitle={true}
    />

  // TODO: move this also to ExperiencePageHeader
  // let engagementConfig = experienceConfigTakeFromRedux.engagement
  let progressBar = (
    <ProgressBar
      key="progress-bar"
      experienceTopics={experience.topics}
      questionsCount={experience.attemptable_questions_count}
      experienceId={experience.id}
      userId={currentUser.id}
    />
  );

  // let embeddedSubTitle = <span>
  //   <Tag>{experience.questions_count} questions</Tag>
  //   {experience.points && <Tag>{parseFloat(experience.points)} points</Tag>}
  //   {displayUserName}
  // </span>
  // let defaultSubTitle = <span className="title">{userName} <Tag>{sCode}</Tag></span>

  // let subTitle = embedded ? embeddedSubTitle : defaultSubTitle

  const experienceDuration = durationAfterReset ? durationAfterReset : experienceSettings.duration

  let timer =
    experienceSettings.is_timed && experienceSettings.duration > 0 ? (
      <span>
        {/* <CountDown key="experience-timer" date={Date.now() + convertMs(experienceSettings.duration)} /> */}
        
        <CountDownTimer
          key="experience-timer-component"
          duration={experienceDuration}
          experienceSettings={experienceSettings}
          extra_time={userInfoFromReduxextraTime}
          extra_time_offset={userInfoFromReduxextraTimeOffset}
          startedAt={userInfoFromReduxstartedAt}
          mobileUploadMode={userInfoFromReduxmobileUploadMode}
          currentView={currentViewFromUserInfo}
          setUserInfo={setUserInfo}
          setIsTimeUp={setIsTimeUp}
          unpaused_at={userInfoFromReduxUnpausedAt}
          unpaused_offset={userInfoFromReduxUnpausedOffset}
          paused_at={pausedAt}
          paused={experiencePaused}
          setSwitchFromStartToResumeView={setSwitchFromStartToResumeView}
          questionsCount={experience.attemptable_questions_count}
          setServerTimeCallback={setServerTimeCallback}
          countDownButtonDisable={["joined", "endTest", "resumeTest"].includes(currentViewFromUserInfo)}
          // countDownButtonIcon={(currentViewFromUserInfo === "endTest" || currentViewFromUserInfo === "resumeTest") && <SolarClockCircleLinear className='solar-clock-icon' />}
        />
      </span>
    ) : null;

  // let editModeSwitcher = experienceViewMode == "apPreviewTest" && EditModeButton  

  let endButton = (
    <EndButton
      key="end-button"
      isMobile={isMobile}
      experienceViewMode={experienceViewMode}
      userInfoFromReduxstartedAt={userInfoFromReduxstartedAt}
      experienceSettings={experienceSettings}
      userInfoFromReduxextraTimeOffset={userInfoFromReduxextraTimeOffset}
      userInfoFromReduxextraTime={userInfoFromReduxextraTime}
      experienceDuration={experienceDuration}
      setSwitchFromStartToResumeView={setSwitchFromStartToResumeView}
      setUserInfo={setUserInfo}
    />
  );

  // const videoCallConfig =
  //   currentUser.role === "student"
  //     ? studentVideoCallConfig
  //     : teacherVideoCallConfig;
  // let uuid = experience.access_code;

  // let QuickCheckSubmitButton = <Tooltip title="Submit">
  //   <Button key="1" type="danger" onClick={() => submitResponses()}>Submit</Button>
  // </Tooltip>

  // let viewResourcesButton = <Tooltip title="Open resource sheet">
  //   <Button shape="circle" icon={<FileOutlined />}/>
  // </Tooltip>

  let changeLanguageButton = (
    <Tooltip title="Change input language" placement="bottom">
      <Button shape="circle" icon={<FontColorsOutlined />} />
    </Tooltip>
  );

  let studentMainView = [];
  let totalCount;
  console.log("userinfo logs common ==> isFirebaseAuthenticated, currentViewFromUserInfo", isFirebaseAuthenticated, currentViewFromUserInfo);

  // Not getting used right now, as quitting the app
  // const SecurityInterrupted = () => {
  //   let subTitle = []
  //   let extra = []
  //   subTitle.push(
  //     <Alert
  //       key="security-interrupted-alert"
  //       style={{ marginTop: "20px", textAlign: "left" }}
  //       message={
  //         <>
  //           Lockdown security interrupted. This may occur due to unintentional
  //           key presses or system issues. <br />
  //           <br />
  //           Please follow these steps: <br />
  //           <br />
  //           1. Quit the application using the button below. <br />
  //           2. Inform your teacher about this interruption. <br />
  //           3. Restart the app and request permission from your teacher to
  //           resume this test. <br />
  //           <br />
  //           Remember, maintaining the integrity of the test is crucial. Thank
  //           you for helping us keep the test fair for everyone.
  //           <br />
  //         </>
  //       }
  //       type="error"
  //       //showIcon
  //     />
  //   );

  //   extra.push(<QuitBtn 
  //     // userInfo={{userInfo: {security_interrupted: false}}} 
  //     key="security-interrupted-quit-btn"
  //     withLog={true} 
  //     logMessage={"Student quit the test from the security interruption screen"} 
  //     />
  //   )


  //   // useEffect(() => {
  //   //   setUserInfo({},
  //   //   {
  //   //     log: {
  //   //       logging: true,
  //   //       log_level: "warning",
  //   //       msg: `System time is out of sync`,
  //   //     },
  //   //   })
  //   // }, [])

  //   return <React.Fragment>
  //     <Result
  //       className="end-test-result"
  //       status="warning"
  //       // icon={<EditOutlined />}
  //       title={experience.name}
  //       subTitle={subTitle}
  //       extra={extra}
  //     />

  //   </React.Fragment>;
  // }

  // TODO: separate Header component till here

  // TODO: point to discuss: we show PageHeader for student only in startTest view right now - all other views - joined, end, resume, submit do not - whether to show or not from UI perspective. One case for this is the instructions modal/icon, video call for eg. Also timer and chat (not in header I know) or improve UI for all other views to handle all the content better - ESP FOR MOBILE

  let showLoading = isFirebaseTokenLoading
  if ((appType === "mac") &&
    experienceSettings.mode === "offline" &&
    currentUser.role === "student") {
      // Need firestore to be authenticated to update logs if any error comes
      showLoading ||= (changeSecurityLoading && changeSecurityStatus !== "true") 
      finalRender.push(
        <CheckAndChangeSecurity 
          changeSecurityTo={true}
          whitelistUrls={whitelistUrls} 
          macAppsToClose={macAppsToClose} 
          setUserInfo={setUserInfo}
        />
      );
  }

  // isFirebaseAuthenticated = false;
  ///////////////////////////////////////////////////////
  // NOTE: isFirebaseAuthenticated getting set when signInToFirestore, but isFirebaseTokenLoading getting set when getFirebaseToken called which is currently behaving like initExperienceTakeData so need to check both to render views - As some of the child components (question - cke_subjetive) having there own state which is getting rendered before getFirebaseToken gets data, one of the case is student refresh in exam
  ///////////////////////////////////////////////////////
  
  if (isFirebaseAuthenticated && userInfoFromReduxSessionId && !showLoading) {
    // start test
    let viewToShow = currentViewFromUserInfo;
    // if(experience.attempts){  //add condition to compare to experienceSettings.attempt_no to support multiple attempts
    if (experience.experience_user){
      if(experienceType === "assessment") {
      //add condition to compare to experienceSettings.attempt_no to support multiple attempts
        viewToShow = "viewFeedback";
        if(experience.settings.assessment_type === "quick_check"){
          // don't override to viewFeedback basically to show view results button on Submit view
          viewToShow = currentViewFromUserInfo;
        }
      }
      if(experienceType === "learning") {
        viewToShow = "startTest";
      }
    }
    if (experienceViewMode === "apPreviewTest") {
      viewToShow = "startTest";
      // since no FB for non students now, setting this as default
    }
    if (experiencePaused && experienceViewMode === "apTakeTest" && currentViewFromUserInfo !== "submittedTest" && currentViewFromUserInfo !== "viewFeedback") {
      viewToShow = "pauseTest"
    }

    let sessionSwitching = false
    const currentSessionIdFromSessionStorage = getOrCreateSessionId();

    const isUploadDeviceSession = (
      isMobile && 
      userInfoFromReduxmobileUploadMode &&
      userInfoFromReduxUploadSessionId === currentSessionIdFromSessionStorage
    );
    const hasValidSessions = userInfoFromReduxSessionId && currentSessionIdFromSessionStorage;
    const isSessionMismatch = currentSessionIdFromSessionStorage !== userInfoFromReduxSessionId;
    const isUploadSessionMismatch = currentSessionIdFromSessionStorage !== userInfoFromReduxUploadSessionId;
    const isActiveViewIsStartOrEnd = ["startTest", "endTest"].includes(currentViewFromUserInfo);
    
    if (
      !embedded &&
      hasValidSessions &&
      isSessionMismatch &&
      isUploadSessionMismatch &&
      isActiveViewIsStartOrEnd
    ) {
      viewToShow = "resumeTest";
      // sessionSwitching = true
    }

    console.log("Session IDs ==>", {
      currentSessionIdFromSessionStorage,
      userInfoFromReduxRequestResumeSessionId,
      userInfoFromReduxUploadSessionId,
      userInfoFromReduxSessionId,
      viewToShow,
      sessionSwitching,
    })

    // const currentView = "startTest"
    // TODO: separate component
    let focusLostModal = (
      <FocusLostModal
        experienceProps={experienceProps}
        focusLostModalVisible={focusLostModalVisible}
        resumeModalVisible={resumeModalVisible}
        focusLostExplanation={focusLostExplanation}
        setFocusLostExplanation={setFocusLostExplanation}
        setFocusLostModalVisible={setFocusLostModalVisible}
        mainTestScreen={mainTestScreen}
        setResumeModalVisible={setResumeModalVisible}
      ></FocusLostModal>
    );

    if (userInfoFromReduxEnableLocalStorageSync) {
      viewToShow = "localStorageSync";
    }
    // let videoRender = uuid && (
    //   <>
    //     <VideoCall
    //       containerDimensions={{
    //         // height:
    //         //   currentView == "startTest" ? "0px" : isMobile ? "200px" : "300px",
    //         // width: currentView == "startTest" ? "0px" : "100%",
    //         height: "100%",
    //         width: "100%",
    //       }}
    //       config={{
    //         roomName: `AssessPrep-${uuid}`,
    //         // height: isMobile ? "200px" : "300px",
    //         width: "100%",
    //         height: "100%",
    //         // height: isMobile ? "300px" : "100%",
    //         // width: isMobile ? "400px" : "600px",
    //         // passwordRequired: true,
    //         userInfo: {
    //           email: currentUser.email,
    //           displayName: currentUser.name,
    //         },
    //         ...videoCallConfig,
    //       }}
    //     />
    //   </>
    // );

    const sessionModalConfig = {
      embedded,
      experienceViewMode,
      setUserInfo,
    };

    // viewToShow = "localStorageSync";
    switch (viewToShow) {
      case "joined":
        let joinViewConfig = {
          experienceProps: experienceProps,
          // videoRender: videoRender,
          showStudentCodeAckModal: showStudentCodeAckModal,
          experienceDuration: experienceDuration,
          videoCallButton: videoCallButton,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          setShowStudentCodeAckModal: setShowStudentCodeAckModal,
        };
        finalRender.push(<JoinView {...joinViewConfig} />);
        break;

      case "startTest":
        let startViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          sessionModalConfig: sessionModalConfig,
          showQRModal: showQRModal,
          progressBar: progressBar,
          endButton: endButton,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          focusLostModal: focusLostModal,
          // videoRender: videoRender,
          mobileUploadMode: mobileUploadMode,
          experienceDuration: experienceDuration,
          setShowQRModal: setShowQRModal,
          key: "start-view",
        };
        finalRender.push(<StartView {...startViewConfig} />);
        break;

      case "endTest":
        let endViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          progressBar: progressBar,
          isSetUserInfoLoading: isSetUserInfoLoading,
          focusLostModal: focusLostModal,
          // videoRender: videoRender,
          sessionModalConfig: sessionModalConfig,
          isTimeUp: isTimeUp,
          backToTestButtonLoading: backToTestButtonLoading,
          experienceDuration: experienceDuration,
          questionsCount: experience.attemptable_questions_count,
          setShowQRModal: setShowQRModal,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          setBackToTestButtonLoading: setBackToTestButtonLoading,
        };
        finalRender.push(<EndView {...endViewConfig} />);
        break;

      case "pauseTest":
        let pauseViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          progressBar: progressBar,
          endButton: endButton,
          // videoRender: videoRender,
          isTimeUp: isTimeUp,
          setIsTimeUp: setIsTimeUp,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          unpaused_offset: userInfoFromReduxUnpausedOffset,
          paused_at: pausedAt,
          experienceDuration: experienceDuration,
        };
        finalRender.push(<PauseView {...pauseViewConfig} />);
        break;

      case "resumeTest":
        let resumeViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          progressBar: progressBar,
          endButton: endButton,
          // videoRender: videoRender,
          isTimeUp: isTimeUp,
          experienceDuration: experienceDuration,
          sessionSwitching: sessionSwitching,
          isUploadDeviceSession: isUploadDeviceSession,
          setIsTimeUp: setIsTimeUp,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
        };
        finalRender.push(<ResumeView {...resumeViewConfig} />);
        break;

      case "submittedTest": // fetch this from experience_users from rails
        let submitViewConfig = {
          experienceProps: experienceProps,
          // videoRender: videoRender,
          experienceDuration: experienceDuration
        };
        finalRender.push(<SubmitView {...submitViewConfig} />);
        break;

      case "viewFeedback":
        let resultViewConfig = {
          experienceProps: experienceProps,
          experienceDuration: experienceDuration,
          fromUrl: fromUrl,
          fromResourceName: fromResourceName
        };

        finalRender.push(<ResultView {...resultViewConfig} />);
        break;

      case "localStorageSync":
        let localStorageSyncViewConfig = {
          experience: experience,
        };

        finalRender.push(<LocalStorageSyncView {...localStorageSyncViewConfig} />)
        break;

      default:
        finalRender.push(mrIntl("ExperienceTake.something_went_wrong"));
        break;
    }
    

    if (
      viewToShow !== "viewFeedback" &&
      (experienceViewMode === "apTakeTest" ||
        (experienceViewMode === "apPreviewTest" &&
          experienceSettings.mode !== "paper")) &&
      !(isMobile && mobileUploadMode)
    ) {
      finalRender.push(
        <ToolsSidebar
          experience={experience}
          experienceSettings={experienceSettings}
          setUserInfo={setUserInfo}
        />
      );
    }

    let takeSecurityScreenshots = (enabledFeatures.auto_screenshots && experienceSettings && experienceSettings.enable_auto_screenshots && currentUser.role === "student" && !embedded && experienceViewMode !== "apPreviewTest" && viewToShow !== "viewFeedback" && !(mobileUploadMode && isMobile)) ? true : false

    if(takeSecurityScreenshots){
      finalRender.push(<TakeScreenshots experienceId={experience.id} />)
    }

    // In every case we are showing this view if the is lockdown mode but not in allow online case.
    // APL-3187 - keep this at the end since overridding finalRender
    if(viewToShow && viewToShow !== "viewFeedback"  && viewToShow !== "joined" && viewToShow !== "submittedTest" && appType === "web" && !allowedInBrowser && experienceViewMode !== "apPreviewTest" && !(mobileUploadMode && isMobile)){
      finalRender = <OfflineExperienceAndNotAllowedOnlineWarning setUserInfo={setUserInfo} experience={experience} viewToShow={viewToShow} />
    }

    // console.log("OfflineExperienceAndNotAllowedOnlineWarning ==>", {
    //   experienceViewMode,
    //   allowedInBrowser,
    //   mobileUploadMode,
    //   viewToShow,
    //   isMobile
    // },
    // viewToShow && viewToShow !== "viewFeedback"  && viewToShow !== "joined" && viewToShow !== "submittedTest" && appType === "web" && !allowedInBrowser && experienceViewMode !== "apPreviewTest" && !(mobileUploadMode && isMobile)
    // )

    if (currentTimeValidWrtServerTime(serverTimeOffset) === false) {
      finalRender = <InvalidTimeAlert experience={experience} />
    }

    // let shouldShowSecurityInterruptedView =
    //   changeSecurityStatus !== "true" &&
    //   shouldSecurityInterruptedEventCheck &&
    //   currentView !== "viewFeedback"; //changeSecurityStatus  => can be undefined or "false"

    // if (shouldShowSecurityInterruptedView) {
    //   finalRender = <SecurityInterrupted key="security-interrupted" />;
    // }
  } else {
    if (showLoading) {
      finalRender.push(<Spinner />);
    }
  }

  let finalRenderWithFirestore = finalRender;

  if (mobileUploadMode != null) {
    finalRenderWithFirestore = (
      <>
        {finalRender}
        {/* Initializes firestore, gets token, sets initial values */}
        {!firestoreConnectionClosed && 
        <FirestoreInteractions
          experience={experience}
          mobileUploadMode={mobileUploadMode}
        />}
      </>
    );
  }

  if (experienceViewMode !== "apPreviewTest" && experienceSettings.start_condition === "scheduled_time" && getTimeRemainingToTargetTime(experienceSettings.start_at_datetime, "minutes") > 30) { // Time is calculated in minutes here
    return (
      <NotAllowedAlert 
        experience={experience} 
        message={mrIntl("ExperienceTake.cannot_join_until_30_minutes_before_message", {
          time: dayjs(experienceSettings.start_at_datetime).format("MMM DD, hh:mm a")
        })} 
      />
    )
  }

  if (isInvalidSEBConfig && false) {
    // history.push("/error")
    return <Redirect to="/error" />;
  }

  // if (
  //   (isDynamicLockdownConfigForMacApp || isDynamicLockdownConfigForiPadApp) &&
  //   !supportsDynamicConfig(appType)
  // ) {
  //   //if mac application !isNewAppVersion(currentSystemAppVersion, "10.2.0") , move to listing page instad of expereince take
  //   //because  showQuitAlertOnNativeSide() => showQuitAlert function ; not supported earlier version

  //   return <Redirect to="/u/tests" />;
  // }
  
  return finalRenderWithFirestore;
};

export default ExperienceTake;
