import Dexie from "dexie";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { checkMob } from "/src/lib/utils/helperMethods";

export const db = new Dexie("Assessprep");
const isMobile = checkMob();

/////////////////////
// NOTE: Increase version number when ever update index keys
/////////////////////
db.version(5).stores({
  userInfo:
    "++id,user_id,experience_id,currentView,student_updated_at,teacher_updated_at",
  userResponses:
    "++id,user_id,experience_id,segment_id,question_id,&user_response_id,updated_at",
  logs: "++id,log_id,user_id,experience_id,by_user_id,internet_status",
});

let isIndexedDBSupported = window.indexedDB ? true : false;

export const onlinePriorityKeys = [
  "extra_time",
  "extra_time_offset",
  "security",
  "request_resume",
  "needs_attention_at",
  "student_unread_count",
];

export function openOfflineDB(options) {
  const { updateOfflineDBStatus = () => {}} = options;
  if (isIndexedDBSupported) {
    db.open()
      .then(() => {
        clearOldItemsFromDB(30);
        if (updateOfflineDBStatus) {
          updateOfflineDBStatus("open");
        }
      })
      .catch((error) => {
        console.log(
          "Error in opening indexed DB, Disabling saving item offline ==>",
          error
        );
        if (updateOfflineDBStatus) {
          updateOfflineDBStatus("closed");
        }
        isIndexedDBSupported = false;
        Sentry.captureException(error);
      });
  } else {
    console.log("Indexed DB is not supported in this browser");
    if (updateOfflineDBStatus) {
      updateOfflineDBStatus("not_supported");
    }
    Sentry.captureException(isIndexedDBSupported);
  }
}

export async function saveItemToOfflineDB(
  tableName,
  searchObj,
  updateObject,
  options = {},
  retry = true
) {
  const { updateOfflineSaveStatus = () => {} } = options;

  console.log(
    "saveItemToOfflineDB tableName, searchObj, updateObject ==>",
    tableName,
    searchObj,
    updateObject
  );

  // if (isMobile) {
  //   // in mobile firestore by default uses indexed db for synchronisation
  //   return;
  // }

  if (!isIndexedDBSupported) {
    console.log("Indexed DB is not supported in this browser");
    return;
  }

  try {
    let dbItem = null;
    if (Object.keys(searchObj).length > 0) {
      dbItem = await db[tableName].where(searchObj).first();
    }

    let itemToUpdate = {
      ...searchObj,
      ...updateObject,
    };

    if (tableName === "userInfo") {
      delete itemToUpdate.active_students; // Offline db does not support firestore query object
      delete itemToUpdate.focus_lost_count;
      delete itemToUpdate.student_unread_count;
      // delete itemToUpdate.all_active_session_ids;
      // TODO - right now if you go offline, focus lost logs will be tracked and synced but count will not update
      if (itemToUpdate.online_priority_keys) {
        // Not saving teacher action keys offline as it will always have online priority
        itemToUpdate.online_priority_keys.forEach((key) => {
          delete itemToUpdate[key];
        });
      }
    }

    console.log(
      "saveItemToOfflineDB tableName, itemToUpdate, dbItem",
      tableName,
      itemToUpdate,
      dbItem
    );

    if (!dbItem) {
      // Change from add to put => put does both adds or updates data as per unique indexes
      db[tableName]
        .put(itemToUpdate)
        .then(() => {
          if (updateOfflineSaveStatus) {
            updateOfflineSaveStatus("saved");
          }
          console.log("successfully updated ==>", itemToUpdate);
        })
        .catch(function (e) {
          console.error(
            "Error in put saveItemToOfflineDB ==>",
            e,
            itemToUpdate,
            dbItem
          );
          if (updateOfflineSaveStatus) {
            updateOfflineSaveStatus("failed");
          }
          Sentry.captureException(e);
          throw e; // Re-throw the error to abort flow!
        });
    } else {
      db[tableName]
        .update(dbItem.id, itemToUpdate)
        .then(() => {
          if (updateOfflineSaveStatus) {
            updateOfflineSaveStatus("saved");
          }
          console.log("successfully updated ==>", itemToUpdate);
        })
        .catch(function (e) {
          console.error(
            "Error in updating saveItemToOfflineDB ==>",
            e,
            itemToUpdate,
            dbItem,
            e.name,
            e.inner
          );
          if (updateOfflineSaveStatus) {
            updateOfflineSaveStatus("failed");
          }
          Sentry.captureException(e);
          throw e; // Re-throw the error to abort flow!
        });
    }
  } catch (error) {
    console.error(
      "Error in saveItemToOfflineDB ==>",
      error,
      tableName,
      searchObj,
      updateObject
    );
    console.log("Is DB Open and retry allowed", db.isOpen(), retry);
    // if (error.name === "InvalidStateError" && db.isOpen() && retry) {
    if (db.isOpen() && retry) {
      // Re-Trying closing and opening db for all errors
      console.log("Dexie: Need to reopen db");
      db.close();
      db.open().then(() => {
        // Sentry.captureException(error);
        saveItemToOfflineDB(tableName, searchObj, updateObject, options, false);
      });
    } else {
      console.log("Something is wrong not resolved after retry", retry, error);
      if (updateOfflineSaveStatus) {
        updateOfflineSaveStatus("failed");
      }
      Sentry.captureException(error);
    }
  }
}

export async function getItemsFromOfflineDB(
  tableName,
  searchObj,
  options = {},
  retry = true
) {
  const { filterBy } = options;
  console.log(
    "getItemsFromOfflineDB tableName, searchObj",
    tableName,
    searchObj
  );

  // if (isMobile) {
  //   // in mobile firestore by default uses indexed db for synchronisation
  //   return [];
  // }

  if (!isIndexedDBSupported) {
    console.log("Indexed DB is not supported in this browser");
    return [];
  }

  try {
    // Get all items from the table that match the search criteria
    const dbItems = await db[tableName].where(searchObj).toArray() || [];
    
    // Apply additional filtering if filterBy options are provided
    let finalDBItems = dbItems;
    if (filterBy) {
      finalDBItems = dbItems.filter(item => {
        // Check that item matches all filter conditions
        return Object.entries(filterBy).every(([key, allowedValues]) => {
          if (Array.isArray(allowedValues)) {
            // If filter value is array, check item value is included
            return allowedValues.includes(item[key]);
          }
          // Otherwise do exact match
          return item[key] === allowedValues;
        });
      });
    }

    console.log(
      "getItemsFromOfflineDB dbItemArr, dbItem", 
      tableName, 
      searchObj, 
      finalDBItems
    );
    
    return finalDBItems;
  } catch (error) {
    console.error(
      "Error in getItemsFromOfflineDB ==>",
      error,
      tableName,
      searchObj
    );
    console.log("Is DB Open and retry allowed", db.isOpen(), retry);
    // if (error.name === "InvalidStateError" && db.isOpen() && retry) {
    if (db.isOpen() && retry) {
      // Re-Trying closing and opening db for all errors
      console.log("Dexie: Need to reopen db");
      db.close();
      return await db.open().then(() => {
        // Sentry.captureException(error);
        getItemsFromOfflineDB(tableName, searchObj, options, false);
      });
    } else {
      console.log("Something is wrong not resolved after retry", retry, error);
      Sentry.captureException(error);
      return [];
    }
  }
}

export async function deleteItemsFromOfflineDB(
  tableName,
  searchObj,
  options = {},
  retry = true
) {
  const {} = options;
  console.log(
    "deleteItemsFromOfflineDB tableName, searchObj",
    tableName,
    searchObj
  );

  // if (isMobile) {
  //   // in mobile firestore by default uses indexed db for synchronisation
  //   return;
  // }

  if (!isIndexedDBSupported) {
    console.log("Indexed DB is not supported in this browser");
    return;
  }

  try {
    // Before deleting data make sure offline data saved on firestore
    const dbItems = await db[tableName].where(searchObj).delete();

    console.log("deleteItemsFromOfflineDB dbItemArr, dbItem", dbItems);
  } catch (error) {
    console.error(
      "Error in deleteItemsFromOfflineDB ==>",
      error,
      tableName,
      searchObj
    );

    console.log("Is DB Open and retry allowed", db.isOpen(), retry);
    // if (error.name === "InvalidStateError" && db.isOpen() && retry) {
    if (db.isOpen() && retry) {
      // Re-Trying closing and opening db for all errors
      console.log("Dexie: Need to reopen db");
      db.close();
      await db.open().then(() => {
        // Sentry.captureException(error);
        deleteItemsFromOfflineDB(tableName, searchObj, options, false);
      });
    } else {
      console.log("Something is wrong not resolved after retry", retry, error);
      Sentry.captureException(error);
    }
  }
}

export async function clearAlreadySubmittedExperiencesFromDB(options = {}, retry = true) {
  // const {} = options;
  console.log("clearAlreadySubmittedExperiencesFromDB");

  // if (isMobile) {
  //   // in mobile firestore by default uses indexed db for synchronisation
  //   return;
  // }

  if (!isIndexedDBSupported) {
    console.log("Indexed DB is not supported in this browser");
    return;
  }

  try {
    const dbItems =
      (await db["userInfo"]
        .where({ currentView: "submittedTest" })
        .toArray()) || [];

    dbItems.forEach((item) => {
      deleteItemsFromOfflineDB("userInfo", {
        experience_id: item.experience_id,
        user_id: item.user_id,
      });
      deleteItemsFromOfflineDB("userResponses", {
        experience_id: item.experience_id,
        user_id: item.user_id,
      });
      deleteItemsFromOfflineDB("logs", {
        experience_id: item.experience_id,
        user_id: item.user_id,
      });
    });
    console.log(
      "clearAlreadySubmittedExperiencesFromDB dbItemArr, dbItem",
      dbItems
    );
  } catch (error) {
    console.error("Error in clearAlreadySubmittedExperienceFromDB ==>", error);
    console.log("Is DB Open and retry allowed", db.isOpen(), retry);
    // if (error.name === "InvalidStateError" && db.isOpen() && retry) {
    if (db.isOpen() && retry) {
      // Re-Trying closing and opening db for all errors
      console.log("Dexie: Need to reopen db");
      db.close();
      await db.open().then(() => {
        // Sentry.captureException(error);
        clearAlreadySubmittedExperiencesFromDB(options, false);
      });
    } else {
      console.log("Something is wrong not resolved after retry", retry, error);
      Sentry.captureException(error);
    }
  }
}

export async function clearOldItemsFromDB(days = 90, options = {}, retry = true) {
  // const {} = options;
  console.log("clearOldItemsFromDB");

  // if (isMobile) {
  //   // in mobile firestore by default uses indexed db for synchronisation
  //   return;
  // }

  if (!isIndexedDBSupported) {
    console.log("Indexed DB is not supported in this browser");
    return;
  }

  try {
    // Will delete all experiences data whose user info student_updated_at, teacher_updated_at is before 30 days
    let beforeTime = days * 24 * 60 * 60 * 1000;
    const dbItems =
      (await db["userInfo"]
        .where("currentView")
        .equals("submittedTest")
        .or("student_updated_at")
        .belowOrEqual(Date.now() - beforeTime)
        .or("teacher_updated_at")
        .belowOrEqual(Date.now() - beforeTime)
        .toArray()) || [];

    dbItems.forEach((item) => {
      deleteItemsFromOfflineDB("userInfo", {
        experience_id: item.experience_id,
        user_id: item.user_id,
      });
      deleteItemsFromOfflineDB("userResponses", {
        experience_id: item.experience_id,
        user_id: item.user_id,
      });
      deleteItemsFromOfflineDB("logs", {
        experience_id: item.experience_id,
        user_id: item.user_id,
      });
    });
    console.log("clearOldItemsFromDB dbItemArr, dbItem", dbItems);
  } catch (error) {
    console.error("Error in clearOldItemsFromDB ==>", error);
    console.log("Is DB Open and retry allowed", db.isOpen(), retry);
    // if (error.name === "InvalidStateError" && db.isOpen() && retry) {
    if (db.isOpen() && retry) {
      // Re-Trying closing and opening db for all errors
      console.log("Dexie: Need to reopen db");
      db.close();
      await db.open().then(() => {
        // Sentry.captureException(error);
        clearOldItemsFromDB(days, options, false);
      });
    } else {
      console.log("Something is wrong not resolved after retry", retry, error);
      Sentry.captureException(error);
    }
  }
}

// TODO: clear old indexed db data on the basis of submit or currentView or Old Date
// clearAlreadySubmittedExperiencesFromDB()
