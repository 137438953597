import { createSelector } from "reselect";
import {currentUserState, enabledFeaturesState} from "/src/views/Auth/Login/selector"
import {appTypeState} from "/src/App/OfflineApp/offlineAppSelectors"
import { activeExperienceIdSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector"
import { availableLanguageOptionsForAI, checkIfStartTime, getFromLS } from "/src/lib/utils/helperMethods";
import { includes } from "lodash";
// import lodash from "lodash";

const empty = {}
const defaultFalseValue = false;
export const defaultAIGradingSettingsState = {
  strictness: 'standard',
  feedback_language: 'english',
  feedback_length: 'standard',
  feedback_tone: 'neutral',
  additional_instructions: '',
  use_seeds_for_grading: false,
  decimal_values_in_points: false
}

export const defaultAIAuthoringSettingsState = {
  output_language: 'english',
  explanation_length: 'standard',
  number_of_questions: 3,
  // difficulty_level: 'medium',
  // overall_additional_instructions: ''
}

export const defaultAIAssistantSettingsState = {
  authoring: defaultAIAuthoringSettingsState,
  grading: defaultAIGradingSettingsState,
}

const loadingState = (state, props) => {
  return state.experiences.get("loading");
};

export const experienceLoadingSelector = () =>
  createSelector([loadingState], (loading) => loading);

// The action name for fetch topics and segments is TOPICS_AND_SEGMENTS_FETCH so the state name is topicsloading because it create loading name by adding "topics" topics is the first word of action name.
const topicsAndSegmentsLoadingState = (state, props) => {
  return state.experiences.get("topicsloading");
};
export const topicsAndSegmentsLoadingSelector = () =>
  createSelector([topicsAndSegmentsLoadingState], (topicsloading) => topicsloading);

// const errorState = (state, props) => state.experiences.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

// const experiencesState = (state, props) =>
//   state.experiences.get("experiences");

// export const experiencesSelector = () =>
//   createSelector([experiencesState], (experiences) => experiences);

// const experiencesShowState = (state, props) => {

//   const experiences = state.experiences.get("experiences");

//   if (experiences.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(experiences, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return experiences[idx];
//     }
//     return {};
//   }
// };

// export const experiencesShowSelector = () =>
//   createSelector([experiencesShowState], (experiences) => experiences);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.experiences.get("page");
//   pageObj.totalPages = state.experiences.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);


const experiencesState = (state, props) =>
  state.experiences.get("experiences");

const addCanCurrentUserEdit = (experiences, currentUser) => {
  const finalExperiences = experiences.map((exp) => {
    console.log( "exp", exp, currentUser );
    // add canCurrentUserEdit logic here
    let current_user_can_edit = false
    let current_user_can_monitor = false
    let current_user_can_view = false
    // if(currentUser.role === "superadmin"){
    //   current_user_can_edit = true
    // }
    if(currentUser.role === "qb_author"){
      let allowedQbIds = currentUser.custom_fields["allowed_questionbank_ids"]
      
      if(allowedQbIds && exp.questionbank && allowedQbIds.indexOf(exp.questionbank.id) !== -1){
        current_user_can_edit = true
      }
    }
    else if(currentUser.role === "admin" && exp.org_id === currentUser.org_id){
      current_user_can_edit = true
    }
    else{
      if(exp.experience_memberships_editors && exp.experience_memberships_editors.find(em => em.memberable_id === currentUser.id)){
        current_user_can_edit = true
      }
      if(exp.experience_membership_invigilator_ids && exp.experience_membership_invigilator_ids.indexOf(currentUser.id) > -1){
        current_user_can_monitor = true
      }
    }

    if(currentUser.role === "support") {
      current_user_can_view = true
    }

    console.log( "exp edited", exp, currentUser );

    exp.current_user_can_edit = current_user_can_edit
    exp.current_user_can_monitor = current_user_can_monitor
    exp.current_user_can_view = current_user_can_view

    return exp;
  })
  return finalExperiences;
  // return experiences;
}

const addCanCurrentUserEditForExperiences = (experiences, currentUser, appType, enabledFeatures) => {
  // for my assessments, - to not have separate urls and not need makeShowSaga like fns - filtering experiences -
  // this is being used to show exp - not above. above only for lib list i think
  const currentUserPermission = currentUser.permission || {}
  const experiencePermission = currentUserPermission.experience || {}
  const finalExperiences = []
  if(currentUser.role === "parent"){
    // console.log("addCanCurrentUserEditForExperiences appType", appType)
    // if(appType === "seb"){
    //   experiences.forEach((exp) => {
    //     if(exp.settings.mode === "offline"){
    //       finalExperiences.push(exp)
    //     }
    //   })
    //   return finalExperiences
    //   // return experiences.filter(exp => exp.settings.mode == "offline")
    // }
    return experiences
  } else if(currentUser.role === "student") {
    if (enabledFeatures.allow_peer_review_assessment) {
      experiences.forEach((exp) => {
        let current_user_can_view = false
        let current_user_can_grade = false
        let student_reviewer_mode = false
        if (exp.experience_user && exp.settings.peer_review_enabled) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const isReviewer = urlParams.get('reviewer');
          const review_for_experience_users = exp.review_for_experience_users || []
          
          if (isReviewer && review_for_experience_users.length > 0) {
            current_user_can_view = false
            current_user_can_grade = true
            student_reviewer_mode = true
          }
        }
  
        exp.current_user_can_view = current_user_can_view
        exp.current_user_can_grade = current_user_can_grade
        exp.student_reviewer_mode = student_reviewer_mode
  
        finalExperiences.push(exp)
      })
    } else {
      return experiences
    }
  } else{
    experiences.forEach((exp) => {
      console.log( "exp", exp, currentUser );
      // add canCurrentUserEdit logic here
      let current_user_can_edit = false
      let current_user_can_grade = false
      let current_user_can_monitor = false
      let current_user_can_deliver = false
      let current_user_can_view_in_list = false
      let current_user_can_view = false
      // This is for invigilator who can only monitor
      let current_user_can_only_monitor = false
      const specialUIForPamojaSchool = enabledFeatures.special_ui_for_pamoja_school && exp?.custom_fields?.block 
      // if(currentUser.role == "superadmin"){
      //   current_user_can_edit = true
      // }
      if(exp.experience_memberships_editors && exp.experience_memberships_editors.find(em => em.memberable_id === currentUser.id)) {
        if (experiencePermission.grade) {
          current_user_can_grade = true
        }
        if (experiencePermission.update) {
          current_user_can_edit = true
        } else {
          current_user_can_view = true
        }
        if(experiencePermission.deliver) {
          current_user_can_deliver = true
        }
        if(experiencePermission.monitor) {
          current_user_can_monitor = true
        }
        current_user_can_view_in_list = true
      }
      if(exp.experience_membership_invigilator_ids && exp.experience_membership_invigilator_ids.indexOf(currentUser.id) > -1){
        if (experiencePermission.monitor) {
          if(checkIfStartTime(exp)) {
            current_user_can_view = true
          }
          current_user_can_monitor = true
          current_user_can_only_monitor = true
          if(checkIfStartTime(exp) && experiencePermission.deliver) {
            current_user_can_deliver = true
          }
        }
        current_user_can_view_in_list = true
      }
  
      if (currentUser.role === "admin" && exp.org_id === currentUser.org_id) {
        if (experiencePermission.grade) {
          current_user_can_grade = true
        }
        if(experiencePermission.monitor) {
          current_user_can_monitor = true
        }
        if(experiencePermission.deliver) {
          current_user_can_deliver = true
        }
        current_user_can_edit = true
      }

      if(currentUser.role === "support") {
        current_user_can_view = true
      }

      if(currentUser.role === "qb_author"){
        let allowedQbIds = currentUser.custom_fields["allowed_questionbank_ids"]
        
        if(allowedQbIds && exp.questionbank && allowedQbIds.indexOf(exp.questionbank.id) !== -1){
          const originalUserRole = getFromLS("originalUserRole")
          if (originalUserRole === "superadmin") {
            current_user_can_deliver = true
          }
          current_user_can_edit = true
        }
      }

      // IMPORTANT: Make sure Pamoja school overrides are at the end of code block
      if (specialUIForPamojaSchool && (currentUser.role === "admin" || currentUser.role === "teacher") && exp.org_id === currentUser.org_id ) {
        current_user_can_grade = true
        current_user_can_deliver = false
        current_user_can_monitor = false
        current_user_can_edit = false
        current_user_can_view = true
      }

      exp.current_user_can_edit = current_user_can_edit
      exp.current_user_can_monitor = current_user_can_monitor
      exp.current_user_can_grade = current_user_can_grade
      exp.current_user_can_deliver = current_user_can_deliver
      exp.current_user_can_only_monitor = current_user_can_only_monitor
      exp.current_user_can_view = current_user_can_view
      
      console.log( "finalExperiences current_user_can_view_in_list ", current_user_can_view_in_list );
      if(current_user_can_view_in_list){
        console.log( "finalExperiences push ", exp );
        finalExperiences.push(exp)
      }
      // finalExperiences.push(exp)
      // return exp;
    })
    
  }
  return finalExperiences;
  // return experiences;
}

export const experiencesSelector = () =>
  createSelector([experiencesState, currentUserState, appTypeState, enabledFeaturesState], addCanCurrentUserEditForExperiences);

// export const experiencesSelector = () =>
//   createSelector([experiencesState, currentUserState], addCanCurrentUserEdit);

const libraryexperiencesState = (state, props) =>
  state.libraryexperiences.get("experiences");


export const libraryexperiencesSelector = () =>
  createSelector([libraryexperiencesState, currentUserState],addCanCurrentUserEdit);

const libraryExperienceFetchLoadingState = (state, props) =>
  state.libraryexperiences.get("fetchloading");

export const libraryExperienceFetchLoadingSelector = () =>
  createSelector([libraryExperienceFetchLoadingState],(fetchloading) => fetchloading);
  
const todayexperiencesState = (state, props) =>
  state.todayexperiences.get("experiences");

export const todayexperiencesSelector = () =>
  createSelector([todayexperiencesState, currentUserState],addCanCurrentUserEdit);

export const activeAdjustedExperienceIdState = (state, props) =>
  state.experiences.get("activeAdjustedExperienceId", null)

export const activeAdjustedExperienceIdSelector = () =>
  createSelector([activeAdjustedExperienceIdState], (activeAdjustedExperienceId) => activeAdjustedExperienceId);

/*
We are doing this for experience groups cases where we have nested experiences,
the nested experiences were not being fetched and preview was not showing up.
Added below code for the same.
*/

const findExperienceById = (experiences, id) => {
  for (let experience of experiences) {
    if (experience.id === id) {
      return experience;
    }
    const nestedExperiences = experience.nested_children || []
    if (nestedExperiences && nestedExperiences.length > 0) {
      const nestedExperienceFound = findExperienceById(nestedExperiences, id);
      if (nestedExperienceFound) {
        return nestedExperienceFound;
      }
    }
  }
  return null;
};

export const activeAdjustedExperienceState = (state, isLibrary) => {
  const activeAdjustedExperienceId = activeAdjustedExperienceIdState(state);
  if (!activeAdjustedExperienceId) {
    return {};
  }

  let experiences = isLibrary
    ? state.libraryexperiences.get("experiences")
    : state.experiences.get("experiences");
  return findExperienceById(experiences, activeAdjustedExperienceId) || {};
};

export const activeAdjustedExperienceSelector = (isLibrary) =>
  createSelector([(state) => activeAdjustedExperienceState(state, isLibrary)], (activeAdjustedExperience) => activeAdjustedExperience);

export const activeExperienceSelector = (state) => {
  const activeExperienceId = activeExperienceIdSelector(state) 

  if (!activeExperienceId) {
    return {}
  }
  return state.experiences.get("experiences", []).find((item) => item.id == activeExperienceId) || {}; 
}

export const activeExperienceGradingDisabledState = (state, props) => {
  // setting disabled true when mb_linked and rubric_type = myp_achievement_level. We want grading on MB side in this case.
  let gradingDisabled = false
  // // const activeExperience = activeAdjustedExperienceSelector()  // not working when activeAdjustedExperienceSelector is with createSelector
  // const activeExperience = activeAdjustedExperienceState(state) 
  // console.log("activeExperienceGradingDisabledState", activeExperience)
  // if(activeExperience && activeExperience.is_mb_linked && activeExperience.rubric && activeExperience.rubric.type_c === "myp_achievement_level"){
  //   gradingDisabled = true
  // }
  return gradingDisabled
}

const printLoadingState = (state, props) => {
  return state.experiences.get("printloading");
};
export const printLoadingSelector = () =>
  createSelector([printLoadingState], (loading) => loading);

export const printExperienceState = (state, props) => 
  state.experiences.get("printExperience", {})

export const printExperienceSelector = () =>
  createSelector([printExperienceState], (printExperience) => printExperience)

export const activeExperienceGradingDisabledSelector = () =>
  createSelector([activeExperienceGradingDisabledState], (activeExperienceGradingDisabled) => activeExperienceGradingDisabled);

export const internetStatusState = (state) =>
  state.experiences.get("internetStatus", 'online');

export const getInternetStatusSelector = () =>
  createSelector([internetStatusState], (internetStatus) => internetStatus);  

export const showExperiencePreviewState = (state) =>
  state.experiences.get("showExperiencePreview", defaultFalseValue);

export const showExperiencePreviewSelector = () =>
  createSelector([showExperiencePreviewState], (showExperiencePreview) => showExperiencePreview);  

export const offlineDBStatusState = (state) =>
  state.experiences.get("offlineDBStatus", 'open');

export const getOfflineDBStatusSelector = () =>
  createSelector([offlineDBStatusState], (offlineDBStatus) => offlineDBStatus); 

export const serverTimeOffsetState = (state) =>
  state.experiences.get("serverTimeOffset", false);

export const getServerTimeOffsetSelector = () =>
  createSelector([serverTimeOffsetState], (serverTimeOffset) => serverTimeOffset); 

export const experienceViewModeState = (state) =>
  state.experiences.get("experienceViewMode", '');

export const experienceViewModeSelector = () =>
  createSelector([experienceViewModeState], (experienceViewMode) => experienceViewMode)

export const exportLoadingState = (state) =>
  state.experiences.get("exportloading", false);

export const exportLoadingSelector = () =>
  createSelector([exportLoadingState], (exportloading) => exportloading);

export const printModeState = (state) =>
  state.experiences.get("printMode", false);

export const printModeSelector = () =>
  createSelector([printModeState], (printMode) => printMode);

export const printOptionsState = (state) =>
  state.experiences.get("printOptions", empty);

export const printOptionsSelector = () =>
  createSelector([printOptionsState], (printOptions) => printOptions);

export const switchFromStartToResumeViewState = (state) =>
  state.experiences.get("switchFromStartToResumeView", true);

export const switchFromStartToResumeViewSelector = () =>
  createSelector([switchFromStartToResumeViewState], (switchFromStartToResumeView) => switchFromStartToResumeView);

export const experienceConfigState = (state) => {
  try {
    return state.experiences.get("experienceConfig", {}).toJS()  // NOTE: Confirm with Abhimanyu why we need toJS for some cases
  } catch (error) {
    return state.experiences.get("experienceConfig", {});
  }
}

export const experienceConfigSelector = () =>
  createSelector([experienceConfigState], (experienceConfig) => experienceConfig);

// export const experienceTypeState = (state) =>
//   state.experiences.get("experienceConfig", {}).experienceType;

export const experienceTypeSelector = () =>
  createSelector([experienceConfigState], (experienceConfig) => experienceConfig.experienceType);

// export const experienceConfigTakeExperienceState = (state) =>
//   state.experiences.get("experienceConfig", {}).takeExperience || {};

export const experienceConfigTakeExperienceSelector = () =>
  createSelector([experienceConfigState], (experienceConfig) => experienceConfig.takeExperience);

// export const experienceMaxEngagementPointsState = (state, props) => {
//   const activeExperience = activeAdjustedExperienceState(state) 
//   const experienceConfigTake = experienceConfigTakeExperienceState(state) 
//   console.log("experienceMaxEngagementPointsState", activeExperience, experienceConfigTake)

//   if (!activeExperience) {
//     return 0
//   }
//   const engagementConfig = experienceConfigTake.engagement

//   console.log("experienceMaxEngagementPointsSelector", engagementConfig, activeExperience);

//   let segmentTypesCount = activeExperience.custom_fields.segment_types_count
//   let maxEngagementPoints = 0

//   if(segmentTypesCount){
//     if(engagementConfig.video.enabled){
//       maxEngagementPoints += segmentTypesCount.video*engagementConfig.video.points
//     }
//     if(engagementConfig.question.enabled){
//       maxEngagementPoints += segmentTypesCount.question*engagementConfig.question.points
//     }
//     if(engagementConfig.game.enabled){
//       maxEngagementPoints += segmentTypesCount.game*engagementConfig.game.points
//     }
//   }

//   return maxEngagementPoints;
// }

export const experienceMaxEngagementPointsSelector = () =>
  createSelector([activeAdjustedExperienceState, experienceConfigState], (activeExperience, experienceConfig) =>  { 
    let experienceConfigTake = experienceConfig.takeExperience

    if (!activeExperience) {
      return 0
    }
    const engagementConfig = experienceConfigTake.engagement
  
    console.log("experienceMaxEngagementPointsSelector", engagementConfig, activeExperience);
  
    let segmentTypesCount = activeExperience.custom_fields && activeExperience.custom_fields.segment_types_count
    let maxEngagementPoints = 0
  
    if(segmentTypesCount){
      if(engagementConfig.video.enabled){
        maxEngagementPoints += segmentTypesCount.video * engagementConfig.video.points
      }
      if(engagementConfig.question.enabled){
        maxEngagementPoints += segmentTypesCount.question * engagementConfig.question.points
      }
      if(engagementConfig.game.enabled){
        maxEngagementPoints += segmentTypesCount.game * engagementConfig.game.points
      }
    }

    return maxEngagementPoints
  });

const experienceAnalyticsSummaryState = (state, props) =>
  state.experiences.get("experienceAnalyticsSummary", { max: {}, average: {}, min: {}, median: {} });

export const experienceAnalyticsSummarySelector = () =>
  createSelector([experienceAnalyticsSummaryState], (experienceAnalyticsSummary) => experienceAnalyticsSummary);

const experienceAnalyticsQuestionsState = (state, props) =>
  state.experiences.get("experienceAnalyticsQuestions", {});

export const experienceAnalyticsQuestionsSelector = () =>
  createSelector([experienceAnalyticsQuestionsState], (experienceAnalyticsQuestions) => experienceAnalyticsQuestions);

const experienceAnalyticsTagsState = (state, props) =>
  state.experiences.get("experienceAnalyticsTags", {});

export const experienceAnalyticsTagsSelector = () =>
  createSelector([experienceAnalyticsTagsState], (experienceAnalyticsTags) => experienceAnalyticsTags);

const gradebookAnalyticsState = (state, props) =>
  state.experiences.get("gradebookAnalytics", {});

export const gradebookAnalyticsSelector = () =>
  createSelector([gradebookAnalyticsState], (gradebookAnalytics) => gradebookAnalytics);

export const monitorConfigState = (state) =>
  state.experiences.get("monitorConfig", {});

export const monitorConfigSelector = () =>
  createSelector([monitorConfigState], (monitorConfig) => monitorConfig);

export const calcTypeState = (state) =>
  state.experiences.get("calcType", "desmos_scientific_calc");

export const calcTypeSelector = () =>
  createSelector([calcTypeState], (calcType) => calcType);

export const skipFocusLostModalState = (state) =>
  state.experiences.get("skipFocusLostModal", defaultFalseValue);

export const skipFocusLostModalSelector = () =>
  createSelector([skipFocusLostModalState], (skipFocusLostModal) => skipFocusLostModal);

export const enableTextHighlighterState = (state) =>
  state.experiences.get("highlightTextEnabled", defaultFalseValue);

export const highlightTextEnabledSelector = () =>
  createSelector([enableTextHighlighterState], (highlightTextEnabled) => highlightTextEnabled);

export const startTestBtnLoadingState = (state) =>
  state.experiences.get("startTestBtnLoading", defaultFalseValue);

export const startTestBtnLoadingSelector = () =>
  createSelector([startTestBtnLoadingState], (startTestBtnLoading) => startTestBtnLoading);

export const endSessionBtnLoadingState = (state) =>
  state.experiences.get("endSessionBtnLoading", defaultFalseValue);

export const endSessionBtnLoadingSelector = () =>
  createSelector([endSessionBtnLoadingState], (endSessionBtnLoading) => endSessionBtnLoading);

export const joinTestBtnLoadingState = (state) =>
  state.experiences.get("joinTestBtnLoading", defaultFalseValue);

export const joinTestBtnLoadingSelector = () =>
  createSelector([joinTestBtnLoadingState], (joinTestBtnLoading) => joinTestBtnLoading);


export const experienceSettingsState = (state) => {
  const activeExperience = activeAdjustedExperienceState(state)
  return activeExperience.settings || {}
}

export const submissionsAIGradingStatusState = (state) => {
  const activeExperience = activeAdjustedExperienceState(state);
  return activeExperience?.custom_fields?.submissions_ai_grading_status || {};
}

export const submissionsAIGradingStatusSelector = () =>
  createSelector([submissionsAIGradingStatusState], (submissionsAIGradingStatus) => submissionsAIGradingStatus);

export const aiAssistantSettingsState = (state) => {
  // Prio 1: User settings
  const currentUser = currentUserState(state)
  const currentUserAIAssistantSettings = currentUser.custom_fields?.ai_assistant_settings
  if (currentUserAIAssistantSettings){
    return currentUserAIAssistantSettings
  }

  // Prio 2: Old LS settings
  // remove below after June 1 - for backward compatibility
  const gradingSettingsFromLS = getFromLS("aiGradingSettings");
  if (gradingSettingsFromLS){
    const parsedGradingSettingsFromLS = JSON.parse(gradingSettingsFromLS)
    return {
      ...defaultAIAssistantSettingsState,
      grading: {
        feedback_language: parsedGradingSettingsFromLS.feedbackLanguage,
        strictness: parsedGradingSettingsFromLS.strictness,
        feedback_length: parsedGradingSettingsFromLS.feedbackLength,
        feedback_tone: parsedGradingSettingsFromLS.feedbackTone,
        additional_instructions: parsedGradingSettingsFromLS.additionalInstructions
      }
    }
  }
  // remove above after June 1

  // Prio 3: Default settings with auto-detection for output language
  const activeExperience = activeAdjustedExperienceState(state)
  
  const subjectLabel = activeExperience?.custom_fields?.subject_item_label || '';
  const isAvailableAILanguageOption = availableLanguageOptionsForAI?.includes(subjectLabel);

  const updateLanguage = (currentLanguage) => 
    isAvailableAILanguageOption ? subjectLabel : currentLanguage;

  return {
    ...defaultAIAssistantSettingsState,
    authoring: {
      ...defaultAIAssistantSettingsState.authoring,
      output_language: updateLanguage(defaultAIAssistantSettingsState?.authoring?.output_language)
    },
    grading: {
      ...defaultAIAssistantSettingsState.grading,
      feedback_language: updateLanguage(defaultAIAssistantSettingsState?.grading?.feedback_language)
    }
  };
}

export const aiAssistantSettingsSelector = () =>
  createSelector([ aiAssistantSettingsState ], aiAssistantSettings => aiAssistantSettings);