import React, { useState, useEffect } from "react";
import { PrinterOutlined, DownOutlined, CheckCircleTwoTone, ExportOutlined, SortAscendingOutlined, MinusCircleTwoTone, CalculatorOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Menu, Modal, Select, Space, Tooltip } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  checkMob,
  displayPoints,
  dropDownTrigger,
  removeHTMLTagsFromText,
} from "/src/lib/utils/helperMethods";
import { exportLoadingSelector } from "/src/views/Experiences/selector";
import { useDispatch, useSelector } from "react-redux";
import MBIcon from "/src/assets/images/MB-icon-new.png";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { formatJsonApiData } from "mr_react_framework";
import { experienceUsersCountSelector, isGradingWithAIInProgressSelector, publishResultsExperienceUsersLoadingSelector } from "../selector";
import "./ExperienceUsersActions.scss"
import { capitalize } from "lodash";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import CreateNewSubmissionModal from "./CreateNewSubmissionModal";
import AchievementLevelCalculator from "./AchievementLevelCalculator";
import ConvertToAchievementLevel from "./ConvertToAchievementLevel";
import ConvertToAchievementLevelLegacy from "./ConvertToAchievementLevelLegacy";
import BulkUploadAnswerSheets from "./BulkUploadAnswerSheets";

const ExperienceUsersActions = (props) => {
  const { experience, gradingConfig, exportModalConfig = {}, setExportModalConfig, setSortType, by_group_id } = props

  const experienceSettings = experience.settings || {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publishAll, setPublishAll] = useState(true);
  const [selectedGroupIds, setSelectedGroupIds] = useState([])
  const [publishUnmapped, setPublishUnmapped] = useState(true);
  const [groupOptions, setGroupOptions] = useState([]);
  const [publishStatus, setPublishStatus] = useState("");
  // const [publishLoading, setPublishLoading] = useState(false);
  const publishResultsLoading = useSelector(publishResultsExperienceUsersLoadingSelector())
  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector())


  const isMultipleGroupsExists = experience.groups && experience.groups.length > 0;

  let modalTitle = "";
  if (publishStatus === "publish") {
    modalTitle = mrIntl(
      "ExperienceUsersActions.select_group_publish_modal_title"
    );
  } else {
    modalTitle = mrIntl(
      "ExperienceUsersActions.select_group_unpublish_modal_title"
    );
  }
  let modalOkText = "";
  if (publishStatus === "publish") {
    modalOkText = mrIntl(
      "ExperienceUsersActions.select_group_modal_ok_button_publish"
    );
  } else {
    modalOkText = mrIntl(
      "ExperienceUsersActions.select_group_modal_ok_button_unpublish"
    );
  }

  useEffect(() => {
    if (selectedGroupIds.length === groupOptions.length && publishUnmapped) {
      setPublishAll(true)
    } else {
      setPublishAll(false)
    }
  }, [selectedGroupIds, groupOptions, publishUnmapped])
  

  useEffect(() => {
    if (isMultipleGroupsExists) {
      const options = [];
      const selectedIds = [];

      experience.groups.forEach((element) => {
        const option = {
          label: element.name,
          value: element.id,
        };
        options.push(option);
        selectedIds.push(option.value);
      });

      setGroupOptions(options);
      setSelectedGroupIds(selectedIds);
    }
  }, [isModalOpen]);

  const showSelectGroupsModal = (status) => {
    setIsModalOpen(true);
    setPublishStatus(status);
  };

  const handleOk = () => {
    handlePublishResults(publishStatus);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value) => {
    setSelectedGroupIds(value)
    // setPublishAll(value.length === groupOptions.length);
  }; 

  const handlePublishResults = (status) => {
    // setPublishLoading(true)
    if (selectedGroupIds.length === 0 && !publishAll && !publishUnmapped) {
      // message.error(`Please select atleast one class before ${publishStatus}ing`);
      if (publishStatus === "publish") {
        message.error(mrIntl("ExperienceUsersActions.please_select_atleast_one_class_publish"));
      } else {
        message.error(mrIntl("ExperienceUsersActions.please_select_atleast_one_class_unpublish"));
      }
      
      // setPublishLoading(false)
      return;
    }
    let publishParams = {
        experience: experience,
        publishStatus: status,
    }
    if (!publishAll) {
      if (selectedGroupIds.length) {
        publishParams = {
          ...publishParams,
          groupIds: selectedGroupIds,
        };
      }
      if (publishUnmapped) {
        publishParams = {
          ...publishParams,
          publishUnmapped: true,
        };
      }
    }
    dispatch(
      experienceUserActions.publishResults(publishParams, {
        successCallback: () => {
          setIsModalOpen(false);
          // setPublishLoading(false)
        },
        errorCallback: () => {
          setIsModalOpen(false);
          // setPublishLoading(false)
        },
      })
    );
  }

  const delayTime =
    experience.submissions_count < 10
      ? 30
      : experience.submissions_count < 30
      ? 120
      : 300;

  const dispatch = useDispatch()
  const exportLoading = useSelector(exportLoadingSelector())
  const experienceUsersCount = useSelector(experienceUsersCountSelector())
  const isGradeAllWithAIInProgress = useSelector(isGradingWithAIInProgressSelector());
  const isGradingWithAIInProgressForEU = useSelector(
    isGradingWithAIInProgressSelector("experience_users")
  );
  
  let isActionsButtonDisabled = experience.status === "draft";
  let actionsButtonTooltip = ""; 

  if (isGradeAllWithAIInProgress || isGradingWithAIInProgressForEU) {
    actionsButtonTooltip = mrIntl("CommonText.grading_is_in_progress");
    isActionsButtonDisabled = true;
  }
  
  const isExperienceUsersExist = experienceUsersCount === 0 ? false : true

  const gradeListActionsMenu = (
    <Menu className="grade-list-actions-menu">
      <Menu.Item
        key="menu-item-publish-all"
        // title={
        //   experience.all_marked
        //     ? "Unpublish all results"
        //     : `Publish all results ${experience.is_mb_linked ? "(can take upto 20 mins to send all submissions to MB)" : ""}`
        // }
        // title={
        //   experience.all_marked
        //     ? "Unpublish all results" : experience.is_mb_linked ? "Publish all results can take upto 20 mins to send all submissions to MB" : "Publish all results"
        // }
        title={
          experience.is_mb_linked
            ? mrIntl(
                "ExperienceUsersActions.publish_all_results_can_take_upto_20_mins"
              )
            : mrIntl("ExperienceUsersActions.publish_all_results")
        }
        disabled={!isExperienceUsersExist}
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() => {
          isMultipleGroupsExists
            ? showSelectGroupsModal("publish")
            : handlePublishResults("publish");
        }}
      >
        {mrIntl("ExperienceUsersActions.publish_all_results")}
      </Menu.Item>
      <Menu.Item
        key="menu-item-unpublish-all"
        title={mrIntl("ExperienceUsersActions.unpublish_all_results")}
        disabled={!isExperienceUsersExist}
        icon={<MinusCircleTwoTone twoToneColor="#eb2f96" />}
        onClick={() => {
          isMultipleGroupsExists
            ? showSelectGroupsModal("unpublish")
            : handlePublishResults("unpublish");
        }}
      >
        {mrIntl("ExperienceUsersActions.unpublish_all_results")}
      </Menu.Item>
      {/* <Menu.Item key="menu-item-" title="Quick summary grid" icon={<CheckCircleTwoTone twoToneColor="#52c41a"/>}>
        Quick summary grid
      </Menu.Item> */}
      {/* <Menu.Item key="menu-item-print-all" title="Print all responses (coming soon)" disabled icon={<PrinterOutlined />}>
        Print all responses
      </Menu.Item> */}
      <Menu.Item
        key="menu-item-print-all"
        disabled={!isExperienceUsersExist ||
          ((exportModalConfig.exportType == "all_student_submissions" &&
            exportLoading) ||
            gradingConfig.anonymousGradingEnabled)
          ? true
          : false
        }
        title={
          gradingConfig.anonymousGradingEnabled
            ? mrIntl("ExperienceUserActions.export_disabled_with_anonymous")
            : exportModalConfig.exportType == "student_submission" &&
              exportLoading
            ? mrIntl("ExperienceUsersActions.export_for_a_particular_student")
            : mrIntl("ExperienceUsersActions.export_all_submissions_to_pdf")
        }
        icon={<PrinterOutlined />}
        onClick={() => {
          setExportModalConfig({
            ...exportModalConfig,
            showModal: true,
            modalTitle: mrIntl("ExperienceUsersList.export_all_submissions"),
            exportButtonText: mrIntl("ExperienceUsersActions.export_to_zip"),
            noSubmissionsMessage:
              experience.submissions_count === 0 && "No submission found",
            timeMessage:
              experience.submissions_count < 10
                ? mrIntl("ExperienceUsersActions.this_can_take_minutes")
                : experience.submissions_count < 30
                ? mrIntl("ExperienceUsersActions.this_can_take_15_minutes")
                : mrIntl("ExperienceUsersActions.this_can_take_45_minutes"),
            delay: delayTime,
            exportType: "all_student_submissions",
            autoInitiateExportDisabled: true,
            // downloadMessage: `Download exported ZIP file (${experience.submissions_count} PDFs)`,
            downloadMessage: mrIntl("ExperienceUsersActions.download_exported_zip",{ experienceSubmissionCount: experience.submissions_count }
            ),
            exportURLType: "zip",
            experience: experience,
          });
        }}
      >
        <MrTranslate id={"ExperienceUsersActions.export_all_to_pdf"} />
      </Menu.Item>
      {experience.is_mb_linked && 
        <Menu.Item
          key="menu-item-send-all-to-managebac"
          disabled={!isExperienceUsersExist ||
            (exportModalConfig.exportType == "student_submission" &&
              exportLoading) ||
            gradingConfig.anonymousGradingEnabled
              ? true
              : false
          }
          title={
            gradingConfig.anonymousGradingEnabled
              ? mrIntl("ExperienceUserActions.export_disabled_with_anonymous")
              : exportModalConfig.exportType == "student_submission" &&
                exportLoading
              ? mrIntl("ExperienceUsersActions.export_for_a_particular_student")
              : mrIntl("ExperienceUsersActions.send_all_submissions_to_managebac")
          }
          onClick={() => {
            setExportModalConfig({
              ...exportModalConfig,
              showModal: true,
              modalTitle: mrIntl("ExperienceUsersActions.send_all_to_managebac"),
              exportButtonText: mrIntl("ExperienceUsersActions.send_submissions"),
              noSubmissionsMessage:
                experience.submissions_count === 0 && "No submission found",
              timeMessage:
                experience.submissions_count < 10
                  ? mrIntl("ExperienceUsersActions.this_can_take_minutes")
                  : experience.submissions_count < 30
                  ? mrIntl("ExperienceUsersActions.this_can_take_15_minutes")
                  : mrIntl("ExperienceUsersActions.this_can_take_45_minutes"),
              delay: delayTime,
              exportType: "all_student_submissions",
              postToMb: true,
              autoInitiateExportDisabled: true,
              // downloadMessage: `Download exported ZIP file (${experience.submissions_count} PDFs)`,
              downloadMessage: mrIntl(
                "ExperienceUsersActions.download_exported_zip",
                { experienceSubmissionCount: experience.submissions_count }
              ),
              exportURLType: "zip",
              experience: experience,
            });
          }}
        >
          <img
            alt={mrIntl("ExperienceUsersActions.managebac_logo")}
            style={{
              width: "16px",
              marginLeft: "-2px",
              marginRight: "2px",
              verticalAlign: "middle"
            }}
            src={MBIcon}
          ></img>{" "}
          {mrIntl("ExperienceUsersActions.send_all_to_managebac")}
        </Menu.Item>
      }
      <Menu.Item
        key="menu-item-export-excel"
        disabled={(!isExperienceUsersExist || gradingConfig.anonymousGradingEnabled) ? true : false}
        title={
          gradingConfig.anonymousGradingEnabled
            ? mrIntl("ExperienceUsersActions.export_disabled_while_anonymous")
            : mrIntl("ExperienceUsersActions.export_all_to_excel")
        }
        icon={<ExportOutlined />}
        onClick={() => {
          setExportModalConfig({
            ...exportModalConfig,
            showModal: true,
            modalTitle: mrIntl(
              "ExperienceUsersActions.exporting_all_results_to_excel"
            ),
            exportButtonText: mrIntl("ExperienceUsersActions.export_to_excel"),
            exportType: "all_student_grades",
            downloadMessage: mrIntl("ExperienceUsersActions.download_excel"),
            exportURLType: "excel",
            timeMessage:
              experience.submissions_count === 0
                ? mrIntl("ExperienceUsersActions.no_submission_found")
                : experience.submissions_count < 30
                ? mrIntl("ExperienceUserActions.this_will_take_about_30s")
                : "",
            delay: 10,
            experience: experience,
          });
        }}
      >
        <MrTranslate id={"ExperienceUsersActions.export_all_to_excel"} />
      </Menu.Item>
      <Menu.Item
        key={"menu-item-create-submissions"}
        className="create-submissions-menu-item"
      >
        <CreateNewSubmissionModal
          experience={experience}
        />
        </Menu.Item>
      {/* <Menu.Item
        icon={<SortAscendingOutlined />}
        disabled={experience.submissions_count === 0}
        onClick={() => {
          setSortType("first_name");
        }}
      >
        Sort by first name
      </Menu.Item>
      <Menu.Item
        icon={<SortAscendingOutlined />}
        disabled={experience.submissions_count === 0}
        onClick={() => {
          setSortType("last_name");
        }}
      >
        Sort by last name
      </Menu.Item> */}
      {experience.rubric.type_c === "criteria_with_points" && 
        <Menu.Item
        className="convert-to-achievement-level-menu-item"
        key="menu-item-convert-to-achievement-level"
      >
        {enabledFeatures.legacy_conversion ? <ConvertToAchievementLevelLegacy /> : <ConvertToAchievementLevel />}
      </Menu.Item>
      }
      {!enabledFeatures.disable_myp_al_per_question_grading && experience.rubric.type_c === "myp_achievement_level" && (
        <Menu.Item
          className="achievement-level-calculator-menu-item"
          key="menu-item-achievement-calculator"
          disabled={!isExperienceUsersExist}
          // title= "Achievement Level Calculator"
          icon={<CalculatorOutlined />}
          // onClick={() =>
          //   setShowAchievementLevelCalculator(!showAchievementLevelCalculator)
          // }
        >
          <AchievementLevelCalculator isExperienceUsersExist = {isExperienceUsersExist}  />
        </Menu.Item>
      )}
      {((enabledFeatures.bulk_upload_answer_sheets &&
        experienceSettings.mode === "paper") ||
        import.meta.env.VITE_MODE === "development") && (
        <Menu.Item
          key="menu-item-bulk-upload-answer-sheets"
          className="bulk-upload-answer-sheets-menu-item"
        >
          <BulkUploadAnswerSheets />
        </Menu.Item>
      )}
    </Menu>
  );
  
  return (
    <>
      <Dropdown
        overlay={gradeListActionsMenu}
        placement="bottomRight"
        trigger={[dropDownTrigger]}
        disabled={isActionsButtonDisabled}  
      >
        <Tooltip title={actionsButtonTooltip}>
          <Button type="text" ghost={false} disabled={isActionsButtonDisabled}>
            <MrTranslate id={"ExperienceUsersActions.actions"} /> <DownOutlined />
          </Button>
        </Tooltip>
      </Dropdown>
      {isMultipleGroupsExists && (
        <Modal
          // title={`${capitalize(publishStatus)} marks`}
          title={capitalize(modalTitle)}
          open={isModalOpen}
          onOk={handleOk}
          // okText={capitalize(publishStatus)}
          okText={capitalize(modalOkText)}
          onCancel={handleCancel}
          confirmLoading={publishResultsLoading}
        >
          <Checkbox
            checked={publishAll}
            onChange={(e) => {
              const selectAllIds = groupOptions.map((option) => option.value);
              setSelectedGroupIds(e.target.checked ? selectAllIds : []);
              setPublishUnmapped(e.target.checked);
              setPublishAll(e.target.checked);
            }}
          >
            {/* All submissions */}
            {mrIntl("ExperienceUsersActions.all_submissions")}
          </Checkbox>
          <br />
          <Checkbox.Group
            options={groupOptions}
            value={selectedGroupIds}
            onChange={handleChange}
            className="select-class-checkbox"
          >
            {(options) =>
              options.map((option) => (
                <Space
                  direction="vertical"
                  key={option.value}
                  style={{ marginRight: 8 }}
                >
                  {option}
                  <br />
                </Space>
              ))
            }
          </Checkbox.Group>
          <br />
          <Checkbox
            className="select-class-checkbox"
            checked={publishUnmapped}
            onChange={(e) => {
              // if (e.target.checked) {
              //   if (groupOptions.length === selectedGroupIds.length) {
              //     setPublishResults(true);
              //   }
              // }
              setPublishUnmapped(e.target.checked);
            }}
          >
            {/* Unmapped Submissions/Guest Users */}
            {mrIntl("ExperienceUsersActions.unmapped_submissions")}
          </Checkbox>
        </Modal>
      )}
     
      {/* <AchievementLevelCalculator showAchievementLevelCalculator={showAchievementLevelCalculator} setShowAchievementLevelCalculator={setShowAchievementLevelCalculator} /> */}
    </>
  );
};

export default ExperienceUsersActions;
